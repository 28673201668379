import React, { useState, useEffect } from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import { CModal, CTextField, CInputDate, CInputMask,} from "./../../../components/index";
import dayjs from "dayjs";
import InputMask from "react-input-mask";
import { CancelOutlined, CheckCircleOutline} from "@material-ui/icons";
import { cadastrar_clientes, listar_clientes } from "./../../../store/actions/clientes";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { listar_tipos_planos } from "../../../store/actions/consultas";

function CadastroCliente({ open, close, classes }) {
  const { planos } = useSelector((state) => state.consultas );  
  const { clientes, page, per_page, total,  filtro } = useSelector((state) => state.clientes );  
  const hoje = new Date();

  const documento = {
    nome: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    cidade: "",
    uf: "",
    data_fundacao: hoje,
    cnpj: "",
    telefone: "",
    celular: "",
    email: "",
    valor_consulta: []
  };

  
  const [dados, setDados] = useState({ ...documento});
  const dispatch = useDispatch()
  

 
  useEffect(() => {
    dispatch(listar_tipos_planos(data => { 
      const planos = data.map((item) => ({ tipo_consulta_id: item.id, valor_consulta: 0}))  
      setDados({...dados, valor_consulta: [...planos] })
    }));
  }, []);

  function closeModal() {
    const plan = planos.map((item) => ({ tipo_consulta_id: item.id, valor_consulta: 0}))  
    setDados({...dados, valor_consulta: [...plan] })
    close();
  }

  const handleChange = (event) => {
    setDados(event.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const removedots = (value) =>
    value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");

  async function sendCadastro(event) {
    event.preventDefault();
    try {
      await cadastrar_clientes({
        ...dados,
        data_fundacao: dayjs(dados.data_fundacao).format("YYYY-MM-DD"),
        cnpj: removedots(dados.cnpj),
      });
      Toast.fire({
        icon: "success",
        title: "Cadastro efetuado com sucesso!",
      });
      dispatch(listar_clientes({ page: 1,  per_page: 10}));
      closeModal();
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos!",
      });
    }
  }

  return (
    <CModal modalWidth="64%" modalHeight="auto" open={open}>
      <div className={classes.modalCadastro}>
        Formulario de Cadastro
        <br />
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="Nome"
              name="nome"
              variant="outlined"
              value={dados.nome}
              onChange={(e) => setDados({ ...dados, nome: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              mask="99.999.999/9999-99"
              value={dados.cnpj}
              onChange={(e) => setDados({ ...dados, cnpj: e.target.value })}
            >
              {() => <CTextField label="CNPJ" name="cnpj" variant="outlined" />}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={3}>
            <CInputDate
              label="Data de Fundação"
              value={dados.data_fundacao}
              onChange={(date) => setDados({ ...dados, data_fundacao: date })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="Endereço"
              name="endereco"
              variant="outlined"
              value={dados.endereco}
              onChange={(e) => setDados({ ...dados, endereco: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Numero"
              name="numero"
              variant="outlined"
              value={dados.numero}
              onChange={(e) => setDados({ ...dados, numero: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Complemento"
              name="complemento"
              variant="outlined"
              value={dados.complemento}
              onChange={(e) =>
                setDados({ ...dados, complemento: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CTextField
              label="Bairro"
              name="bairro"
              variant="outlined"
              value={dados.bairro}
              onChange={(e) => setDados({ ...dados, bairro: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CTextField
              label="Cidade"
              name="cidade"
              variant="outlined"
              value={dados.cidade}
              onChange={(e) => setDados({ ...dados, cidade: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputMask
              mask="99999-999"
              value={dados.cep}
              onChange={(e) => setDados({ ...dados, cep: e.target.value })}
            >
              {() => <CTextField label="CEP" name="cep" variant="outlined" />}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={2}>
            <CTextField
              label="UF"
              name="uf"
              variant="outlined"
              value={dados.uf}
              onChange={(e) => setDados({ ...dados, uf: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="E-mail"
              name="email"
              variant="outlined"
              value={dados.email}
              onChange={(e) => setDados({ ...dados, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Telefone"
              name="telefone"
              variant="outlined"
              value={dados.telefone}
              onChange={(e) => setDados({ ...dados, telefone: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Celular"
              name="celular"
              variant="outlined"
              value={dados.celular}
              onChange={(e) => setDados({ ...dados, celular: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
            Valores de consulta
          </Grid>

            {dados.valor_consulta.length > 0 && planos.map((item, index) => {
              return(
              <Grid item xs={12} sm={3}>
                <div key={index.id}>
                  <CInputMask
                    type="cash"
                    label={item.nome}
                    variant="outlined"
                    margin="dense"
                    value={dados.valor_consulta[index].valor_consulta}
                    onValueChange={(v) => {
                      const valor = dados.valor_consulta  
                      valor[index].valor_consulta = v.floatValue || 0
                      setDados({ ...dados, valor_consulta: [...valor] })
                    }
                    }
                  />
                </div>
          </Grid>
              )
            })}
        </Grid>
        <br />
        <Divider />
        <div style={{ float: "right", marginRight: "2em" }}>
          <Button onClick={sendCadastro} style={{ color: "#056d29" }}>
            <CheckCircleOutline style={{ width: "16px" }} />
            &nbsp; Cadastrar
          </Button>
          <Button onClick={closeModal} style={{ color: "#890f0f" }}>
            <CancelOutlined style={{ width: "16px" }} />
            &nbsp; Fechar
          </Button>
        </div>
      </div>
    </CModal>
  );
}

export default CadastroCliente;
