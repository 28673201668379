import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  home: {
    fontFamily: "Open Sans",
    widht: '100%',
    "& h1": {
      fontSize: "16px",
      marginTop: "2em",
    },
    "& h2": {
      textAlign: "left",
      fontSize: "17px",
      width: "24%",
      marginLeft: '2em',
      color: '#1d4f91 ',
      borderBottom: "1px solid #1d4f91 ",
    },
    
  },
  homeContainer:{
    width: '95%',
    margin: 'auto'
  },
  modalInformations:{
    padding: '2em',
    '& h3':{
      fontSize: '16px',
    }
  },
  buttonModal:{
    width: '99%',
    margin: "auto",
    textAlign: 'center',
    '& button':{
      backgroundColor: '#4f4f4f',
      textAlign: 'right',
      color: '#fff',
      '&:hover':{
        backgroundColor: '#293b54',
      }
    }
  }
}));

export default useStyles;
