import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function SerasaQueries({ consultaJson }) {
  return (
    <div id="consultas-serasa">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Consultas à Serasa realizadas no mesmo documento
          </Th>
        </tr>
      </Table>
      {/* {{!-- {{consultaJson?.report?.serasaQueries.internal}} --}} */}
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th>Cheques Emitidos</Th>
        </tr>
      </Table>
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th>Data emissão do primeiro cheque a vista: </Th>
          <Th>Data emissão do último cheque a vista:</Th>
          <Th>Quantidade de cheques à prazo emitidos:</Th>
        </tr>
        <tr>
          <Td>
            {consultaJson?.report?.serasaQueries.internal?.firstCashCheckDate
              ? consultaJson?.report?.serasaQueries.internal?.firstCashCheckDate
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries.internal?.lastCashCheckDate
              ? consultaJson?.report?.serasaQueries.internal?.lastCashCheckDate
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries.internal?.totalDeferredChecks
              ? consultaJson?.report?.serasaQueries.internal
                  ?.totalDeferredChecks
              : "Nada consta"}
          </Td>
        </tr>
      </Table>
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th>últimos 15 dias: </Th>
          <Th>últimos 30 dias:</Th>
          <Th>31 a 60 dias:</Th>
          <Th>61 a 90 dias:</Th>
        </tr>
        <tr>
          <Td>
            {consultaJson?.report?.serasaQueries.internal
              ?.totalCashChecksLast15Days
              ? consultaJson?.report?.serasaQueries.internal
                  ?.totalCashChecksLast15Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries.internal
              ?.totalDeferredChecksLast30Days
              ? consultaJson?.report?.serasaQueries.internal
                  ?.totalDeferredChecksLast30Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries.internal
              ?.totalDeferredChecks31_60Days
              ? consultaJson?.report?.serasaQueries.internal
                  ?.totalDeferredChecks31_60Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries.internal
              ?.totalDeferredChecks61_90Days
              ? consultaJson?.report?.serasaQueries.internal
                  ?.totalDeferredChecks61_90Days
              : "Nada consta"}
          </Td>
        </tr>
      </Table>

      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th>Consultas Sem Cheques</Th>
        </tr>
      </Table>
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th>últimos 15 dias: </Th>
          <Th>últimos 16 a 30 dias:</Th>
          <Th>31 a 60 dias:</Th>
          <Th>61 a 90 dias:</Th>
        </tr>
        <tr>
          <Td>
            {consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueriesLast15Days
              ? consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueriesLast15Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries16_30Days
              ? consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries16_30Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries31_60Days
              ? consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries31_60Days
              : "Nada consta"}
          </Td>
          <Td>
            {consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries61_90Days
              ? consultaJson?.report?.serasaQueries?.withoutChecks?.totalQueries61_90Days
              : "Nada consta"}
          </Td>
        </tr>
      </Table>
      <br />
    </div>
  );
}

export default SerasaQueries;
