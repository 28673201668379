import toCurrency from "../../../../helpers/toCurrency";
import { Box, Container, Table, Td, Th, Tr } from "./styles";

function Protests({ data }) {
  return (
    <>
      {data?.protests && (
        <Container>
          <Box>
          <h4>Protestos - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>Valor</Th>
              <Th>Cartorio</Th>
              <Th>Cidade/UF</Th>
            </Tr>
            {data.protests.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>
                  <Td>{toCurrency(item.value)}</Td>
                  <Td>{item.civilCourt}</Td>
                  <Td>
                    {item.city}/{item.state}
                  </Td>
                </tr>
              );
            })}
          </Table>
      </Box>
    </Container>
      )}
      </>
  );
}

export default Protests;
