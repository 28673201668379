import styled from "@emotion/styled";

export const Home = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: "#fcf7f7";
  background-size: cover;
`;

export const Box = styled.div`
  width: 30%;
  height: 40%;
  padding: 15px;
  margin-top: -10em;
  background: #fff;
  border-radius: 6px;
`;


export const Formulario = styled.form`
  width: 80%;
  margin: auto;
  text-align: center;
`;