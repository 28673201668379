import React from "react";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Conta, Financeiro, Saldo, Home } from "./components/index";
import logo from './../../assets/images/consultas_logo150x150.png'
import ValidaDocumento from "./components/validaDocumento";
import { useSelector } from "react-redux";

function Navbar() {
  const classes = useStyles();
  const { permissao } = useSelector((state) => state.usuario);


  return (
    <>
      <div className={classes.Nav}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={permissao.valida_documento ? 2 : 3}>
            <Link to="/app/home">
              <div className={classes.logo}>
                <img src={logo} /> 
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={permissao.valida_documento ? 10 : 9}>
            <div className={classes.Grid}>
              <Grid container spacing={2}>
               
                <Grid item xs={12} sm={3}>
                  {permissao.tipo !== "ADMINISTRADOR_FINANCEIRO" && <Saldo />}
                </Grid>
                <Grid item xs={12} sm={permissao.valida_documento ? 2 : 3}>
                {permissao.tipo !== "ADMINISTRADOR_FINANCEIRO" && <Home /> }
                </Grid>
                {permissao.valida_documento ?
                <Grid item xs={12} sm={2}>
                  <ValidaDocumento />
                </Grid>
                : ''
                }
               <Grid item xs={12} sm={permissao.valida_documento ? 2 : 3}>
                  <Financeiro />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Conta />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Navbar;
