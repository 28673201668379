import React, { useState, useEffect } from "react";
import { CDataTable, CPaginate ,CDialog} from "../../../components/index";
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, RadioGroup, Radio, FormControlLabel, Grid, Button, Tooltip} from "@material-ui/core";
import PerfectScrollBar from "react-perfect-scrollbar";
import { listar_clientes } from '../../../store/actions/clientes'
import * as types from '../../../store/types/clientes'
import { Edit as EditIcon, Group as GroupIcon, MonetizationOnOutlined, MoneyOffOutlined, SettingsOutlined  } from "@material-ui/icons";
import EditarCliente from "./editar";
import useStyles from './../styles';
import { Link} from 'react-router-dom'
import { listar_cliente } from './../../../store/actions/clientes';
import { listar_usuario } from "../../../store/actions/usuario";

function ListadeClientes() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { clientes, page, per_page, total,  filtro, cliente } = useSelector((state) => state.clientes );  
  const [modal_editar, setModalEditar] = useState(false)


  useEffect(() => {
    dispatch(listar_clientes({ page: 1,  per_page: 10}));
  }, []);


  const handleChangePage = (event, newPage) => {
    dispatch([
      { type: types.CLIENTES_LOADING, payload: true },
      { type: types.CLIENTES_PAGE, payload: newPage + 1 },
      listar_clientes({...filtro, page: newPage + 1, per_page, paginate: true }),
    ]);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch([
      { type: types.CLIENTES_LOADING, payload: true },
      { type: types.CLIENTES_PERPAGE, payload: true },
      listar_clientes({ ...filtro, page: 1, per_page , paginate: true}),
    ]);
  };

  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  function GerenciarAcesso(id){
  }

  function EditarCadastro(id){
    dispatch(listar_cliente(id));
    setModalEditar(true)
  }

   function listarUsuario(id) {  
       dispatch(listar_usuario({page: 1, per_page: 10}, id));    
     
  }
 
  const [datab, setDatab] = useState({})
  
  return (
    <>
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <PerfectScrollBar>
          <CDataTable
            columns={[
              { title: "Nome", field: "nome", cellStyle:{ width: '30%', paddingLeft: '0.5em'} },
              { title: "CNPJ",        cellStyle:{ width: '10%', textAlign: 'center'}, field: "cnpj" },         
              // { title: "PF Básica",   cellStyle:{ width: '10%', textAlign: 'center'}, render: (row) => toCurrency(row.valor_pf_basica) },          
              // { title: "PF Avançada", cellStyle:{ width: '10%', textAlign: 'center'}, render: (row) => toCurrency(row.valor_pf_avancada) },          
              // { title: "PJ Básica",   cellStyle:{ width: '10%', textAlign: 'center'}, render: (row) => toCurrency(row.valor_pj_basica) },           
              // { title: "PJ Avançada", cellStyle:{ width: '10%', textAlign: 'center'}, render: (row) => toCurrency(row.valor_pj_avancada) },          
              { title: "Situação",    cellStyle:{ width: '10%', textAlign: 'center'}, render: (row) => row.situacao == 'A' ? 'Ativo' : row.situacao == 'I' ? 'Inativo' : 'Cancelado' },          
              { title: "Ações", cellStyle:{ width: '5%', paddingLeft: '0.5em'},  render: (row) => 
              <div style={{display:"flex"}} className={classes.actions}>
                <Tooltip title="Editar">
                  <Button onClick={() => EditarCadastro(row.id)}>
                    <EditIcon style={{color: '#1d4f91', fontSize: '18px'}}/>
                  </Button>
                </Tooltip>
                             
                <Tooltip title="Definir Planos">
                <Link to={`/app/clientes/planos/${row.id}`} >
                  <Button >
                    <MonetizationOnOutlined style={{color: '#1d4f91',  fontSize: '22px'}}/>
                  </Button>
                  </Link>
                </Tooltip>                
                <Tooltip title="Usuarios Vinculados">
                <Link to={`/app/clientes/usuarios/${row.id}`} >
                  <Button >
                    <GroupIcon style={{color: '#1d4f91',  fontSize: '22px'}}/>
                  </Button>
                  </Link>
                </Tooltip> 
              </div>}     
            ]}
        
            data={clientes}
            options={{
              headerStyle: {
                backgroundColor: "#F2F2F3",
                fontSize: 12,
                textAlign: "center",
                borderLeft: "solid 0.5px white",
              },
            }}
          />
        </PerfectScrollBar>
        <CPaginate
          rowsPerPageOptions={[10, 25, 50]}
          rowsPerPage={parseInt(per_page)}
          count={parseInt(total)}
          page={parseInt(page) - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      </Grid>
      <EditarCliente
        open={modal_editar}
        close={() => setModalEditar(false)}
  
      />
    </>
  );
}


export default ListadeClientes