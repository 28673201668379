import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CDialog, CInputSelect } from "../../../components";
import CTextField from "../../../components/CTextField";
import { formatCNPJ, formatCPF } from "../../../helpers";
import {
  efetuar_consulta,
  listarPrecosClientesActions,
  listar_consultas,
} from "../../../store/actions/consultas";
import { resumo_extrato_usuario } from "../../../store/actions/extrato";
import { CheckboxComponent } from "./featuresSelecionadas";
import AlertComponent from "../../../components/AlertComponent";


function CatgoriaDeConsulta({ handleChangeStatus, status, toCurrency }) {
  const { permissao } = useSelector((state) => state.usuario);
  const dispatch = useDispatch();
  const { listar_precos_cliente, consultaEfetuadaLoading } = useSelector(
    (state) => state.consultas
  );
  const [precos, setPrecos] = useState(0);
  const [features, setFeatures] = useState([]);
  const [featureOptions, setFeaturesOptions] = useState([]);
  const [dialogConsulta, setDialogConsulta] = useState(false);
  const [documento, setDocumento] = useState(0);
  const [valorVenda, setValorVenda] = useState(0);
  const [listagemDasFeatures, setListagemDasFeatures] = useState([]);
  const [idItensSelecionados, setIdItensSelecionados] = useState([]);
  const [somaItensSelecionados, setSomaItensSelecionados] = useState(0.0);

  function handleCheckboxChange(e) {
    const { checked, value } = e.target;
    const id = parseInt(value);
    const item = listagemDasFeatures.find((item) => item.id === id);

    if (checked) {
      setIdItensSelecionados([...idItensSelecionados, id]);
    } else {
      setIdItensSelecionados(idItensSelecionados.filter((item) => item !== id));
    }
  }

  useEffect(() => {
    const valoresSelecionados = idItensSelecionados.reduce((acc, id) => {
      const item = listagemDasFeatures.find((item) => item.id === id);
      return acc + parseFloat(item.valor);
    }, 0);

    setSomaItensSelecionados(valoresSelecionados);
  }, [idItensSelecionados]);

  let valorConsultaSoma = listar_precos_cliente.find(
    (item) => item.id == precos
  );

  const clienteId = JSON.parse(localStorage.getItem("permissao"));

  useEffect(() => {
    dispatch(listarPrecosClientesActions(clienteId?.cliente_id));
  }, []);

  function filtrarPorId(id) {
    const resultado = listar_precos_cliente.find((item) => item.id === id);
    return resultado ? resultado.features : [];
  }

  const initialValue = 0;
  const feat = valorConsultaSoma?.features
    .filter((item) => features.includes(item.id))
    .map((item) => Number(item.valor))
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );

  const somaValores =
    Number(valorConsultaSoma && valorConsultaSoma.valor_consulta) +
    somaItensSelecionados;

  const planosCliente = listar_precos_cliente.map((item) => ({
    value: item.id,
    label: item.nome,
  }));

  const removedots = (value) =>
    value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const date = new Date();
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1;

  async function realizarConsulta() {
    try {
      await efetuar_consulta({
        documento: removedots(documento),
        consulta_id: precos,
        feature: idItensSelecionados,
        valor_venda: valorVenda,
      });
      Toast.fire({
        icon: "success",
        title: "Consulta efetuado com sucesso!",
      });
      dispatch(listar_consultas({ page: 1, per_page: 10 }));
      dispatch(resumo_extrato_usuario({ mes: mes, ano: ano }));
      setDialogConsulta(false);
      setIdItensSelecionados([]);
      setListagemDasFeatures([]);
      setSomaItensSelecionados(0.0);
    } catch (error) {
      setDialogConsulta(false);
      Toast.fire({
        icon: "error",
        title: "Documento inválido!",
      });
    }
  }

  useEffect(() => {
    setDocumento();
  }, [precos]);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  async function OpenDialogConsulta(id) {
    try {
      if (documento.length > 0) {
        setListagemDasFeatures(filtrarPorId(id));
        setDialogConsulta(true);
      } 
    } catch (e) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  function Fakeconsulta() {}

  return (
    <div style={{ width: "99%", margin: "auto", padding: "3em" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <CInputSelect
            name="tipo_consulta"
            values={[{ value: 0, label: "Selecione " }].concat(planosCliente)}
            value={precos}
            label="Selecione o tipo"
            onChange={(e) => setPrecos(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {precos == "1" || precos == "2" ? (
            <CTextField
              name="documento"
              required="true"
              label="Digite o Documento para consulta"
              variant="outlined"
              type="text"
              value={formatCPF(documento)}
              onChange={(e) => setDocumento(e.target.value)}
            />
          ) : (
            <CTextField
              name="documento"
              required="true"
              label="Digite o Documento para consulta"
              variant="outlined"
              type="text"
              value={formatCNPJ(documento)}
              onChange={(e) => setDocumento(e.target.value)}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <CTextField
            name="valor_venda"
            required="true"
            label="Digite o valor da venda"
            variant="outlined"
            type="text"
            value={valorVenda}
            onChange={(e) => setValorVenda(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          {permissao?.tipo == "ADMINISTRADOR" ? (
            <>""</>
          ) : (
            <div style={{ textAlign: "center" }}>
              Valor do Relatório <br />
              {toCurrency(somaValores ? somaValores : "")}
            </div>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            onClick={() => OpenDialogConsulta(Number(precos))}
            style={{
              backgroundColor: "#1D4F91",
              color: "#fff",
              zIndex: 0,
            }}
          >
            Efetuar consulta
          </Button>
        </Grid>
        <CDialog
          open={dialogConsulta}
          title="Abaixo informações relacionadas a consulta"
          contenttext={
            <>
              {listagemDasFeatures.length > 0 && (
                <>
                  Gostaria de incluir alguma solicitação adicional? <br />
                  <CheckboxComponent
                    dados={listagemDasFeatures}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </>
              )}
              <div
                style={{
                  borderTop:
                    listagemDasFeatures.length > 0 ? "1px solid gray" : "none",
                  marginTop: listagemDasFeatures.length > 0 ? 15 : -15,
                }}
              >
                <br />
                Documento consultado: {documento} <br />
                Valor {toCurrency(somaValores)}
                <br />
                {idItensSelecionados.length > 0 && (
                  <b style={{ color: "#9e1a1a", fontSize: 14, marginTop: 15 }}>
                    Atenção, relatório com informações adicionais!
                  </b>
                )}
              </div>
            </>
          }
          buttons={[
            {
              title: "Cancelar",
              style: {
                color: "gray",
                padding: "0.3em 0.9em",
                border: "1px solid #9a9a9a",
                borerRadius: "4px",
              },
              onClick: () => {
                setDialogConsulta(false);
                setIdItensSelecionados([]);
                setSomaItensSelecionados(0.0);
              },
            },
            {
              title: !consultaEfetuadaLoading ? (
                "Sim, Consultar"
              ) : (
                <>
                  Aguarde &nbsp;{" "}
                  <CircularProgress
                    style={{ width: "14px", height: "14px", color: "#fff" }}
                  />
                </>
              ),
              style: {
                color: "#fff",
                backgroundColor: !consultaEfetuadaLoading ? "#1d4f91" : "gray",
                padding: "0.3em 0.9em",
                border: "1px solid #9a9a9a",
                borerRadius: "4px",
              },
              onClick: !consultaEfetuadaLoading
                ? realizarConsulta
                : Fakeconsulta,
            },
          ]}
        />
      </Grid>
   

      {showErrorAlert && (
        <AlertComponent
          type="alert"
          message="Digite um documento para consultar"
          timeout={3000}
        />
      )}
    </div>
  );
}

export default CatgoriaDeConsulta;
