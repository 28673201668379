
import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function InternalPendencies({consultaJson}){
    return(
        <div id="internalPendencies">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Pendências Internas
            </Th>
          </tr>
        </Table>

        {consultaJson?.report?.internalPendencies.occurrences.length >
        0 ? (
          <>
            <Table>
              <tr
                style={{ backgroundColor: "#e6e6e6", color: "#666263" }}
              >
                <Th>Data </Th>
                <Th>Modalidade </Th>
                <Th>Aval. </Th>
                <Th>Valor </Th>
                <Th>Contrato </Th>
                <Th>Origem </Th>
                <Th>Praça </Th>
              </tr>
              {consultaJson?.report?.internalPendencies.occurrences.map(
                (item) => {
                  return (
                    <tr>
                      <Td> {item?.date} </Td>
                      <Td> {item?.modality} </Td>
                      <Td> {item?.isGuarantor ? "SIM" : "NÃO"} </Td>
                      <Td> {item?.currencyType} </Td>
                      <Td> {item?.value && toCurrency(item?.value)} </Td>
                      <Td> {item?.contract} </Td>
                      <Td> {item?.origin} </Td>
                    </tr>
                  );
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th style={{ color: "#255391", background: "#dee5eb" }}>
                  Total de ocorrências &nbsp;
                  {
                    consultaJson?.report?.internalPendencies
                      .totalOccurrences
                  }
                  . Periodo &nbsp;
                  {
                    consultaJson?.report?.internalPendencies
                      .oldestOccurrenceDate
                  }{" "}
                  &nbsp;a &nbsp;
                  {
                    consultaJson?.report?.internalPendencies
                      .newestOccurrenceDate
                  }
                  valor total das ocorrencias
                  {consultaJson?.report?.internalPendencies.pefin
                    .totalValue &&
                    toCurrency(
                      consultaJson?.report?.internalPendencies.pefin
                        .totalValue
                    )}
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>{consultaJson?.report?.internalPendencies.message}</Th>
            </tr>
          </Table>
        )}
        {/* Validação se não tiver ocorrencias  */}
      <br />
      </div>
    )
}

export default InternalPendencies