import MuiTab from './MuiTab';
import MuiFilledInput from './MuiFilledInput';
import MuiInputBase from './MuiInputBase';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiButton from './MuiButton';

export default {
  MuiTab,
  MuiFilledInput,
  MuiInputBase,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiButton
};