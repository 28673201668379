

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function UnsuccessfulCompanyParticipations({consultaJson}){
    return(
        <div id="ParticipaçãoemInsucessoEmpresarial">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Participação em Falências
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.occurrences.length >
        0 ? (
          <>
            <Table>
              <tr
                style={{
                  backgroundColor: "#e6e6e6",
                  color: "#666263",
                }}
              >
                <Th>Data </Th>
                <Th>Cód. Natureza</Th>
                <Th>Cód. Qualificação </Th>
                <Th>Nº Vara Civil </Th>
                <Th>CNPJ </Th>
                <Th>Razão Social </Th>
                <Th>Data da inclusão </Th>
              </tr>
              {consultaJson?.report?.completeAnnotations.unsuccessfulCompanyParticipations.occurrences.map(
                (item) => {
                  return(
                  <tr>
                    <Td> {item?.occurrenceDate} </Td>
                    <Td>
                      {" "}
                      {item?.natureCode} | {item?.description}
                    </Td>
                    <Td> {item?.qualificationCode} </Td>
                    <Td> {item?.civilConstituency} </Td>
                    <Td>
                      {" "}
                      {item?.document}/{item?.documentBranch}-
                      {item?.documentDigit}
                    </Td>
                    <Td> {item?.companyName} </Td>
                    <Td> {item?.inclusionDateTime} </Td>
                  </tr>
                  )
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th
                  style={{ color: "#255391", background: "#dee5eb" }}
                >
                  Total de ocorrências &nbsp;
                  {
                    consultaJson?.report?.completeAnnotations
                      .unsuccessfulCompanyParticipations.total
                  }
                  . Periodo
                  {
                    consultaJson?.report?.completeAnnotations
                      .unsuccessfulCompanyParticipations.initialDate
                  }
                  a
                  {
                    consultaJson?.report?.completeAnnotations
                      .unsuccessfulCompanyParticipations.finalDate
                  }
                  valor total das ocorrencias R$
                  {
                    consultaJson?.report?.completeAnnotations
                      .unsuccessfulCompanyParticipations.totalValue
                  }
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default UnsuccessfulCompanyParticipations