import { Grid } from "@material-ui/core";
import { toCurrency } from "../../../../helpers";
import { Box, BoxConsultas, Container } from "./styles";

function MaisAnotacoes({ data, pefin, refin }) {
  const consultasInfoData = [
    
    {
      nome: "Protesto",
      natureCode: "03",
    },
    {
      nome: "Ação Judicial",
      natureCode: "04",
    },
    {
      nome: "Participação em Falencia",
      natureCode: "05",
    },
    {
      nome: "Falência/Concordata",
      natureCode: "06",
    },
    {
      nome: "Dívida Vencida",
      natureCode: "07",
    },
    {
      nome: "Cheque sem fundo achei",
      natureCode: "09",
    }
  ];

  function compararArrays(primeiroArray, segundoArray) {
    const resultado = [];

    primeiroArray.forEach((parametro) => {
      const objetoEncontrado =
        segundoArray &&
        segundoArray.find(
          (objeto) => objeto.natureCode === parametro.natureCode
        );

      if (objetoEncontrado) {
        resultado.push(objetoEncontrado);
      } else {
        resultado.push({
          natureCode: parametro.natureCode,
          quantity: "Nada Consta",
          totalValue: 0,
        });
      }
    });

    return resultado;
  }


 const acaoJudicial = data?.summary?.find((item) => item.natureCode === '04')
 const protesto = data?.summary?.find((item) => item.natureCode === '03')
 const dividasVencidas = data?.summary?.find((item) => item.natureCode === '07')
 const falenciaRecJudicial = data?.summary?.find((item) => item.natureCode === '06')
 const cheques = data?.summary?.find((item) => item.natureCode === '08')
 const recheque = data?.summary?.find((item) => item.natureCode === '09')




  const resultadoFinal = compararArrays(consultasInfoData, data?.summary);

  return (
    <>
    {data && 
      <Container>
        <Box>
          <h4>Mais anotações</h4>
          <Grid container spacing={1} style={{ textAlign: "center" }}>
          <Grid item xs={3} sm={3}>
              REFIN
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {refin?.quantity ? refin?.quantity : "Nada Consta"}
                </b>
                <br /> {refin?.totalValue ? toCurrency(refin?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
            <Grid item xs={3} sm={3}>
              PEFIN
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {pefin?.quantity ? pefin?.quantity : "Nada Consta"}
                </b>
                <br /> {pefin?.totalValue ? toCurrency(pefin?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Dívidas Vencidas
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {dividasVencidas?.quantity ? dividasVencidas?.quantity : "Nada Consta"}
                </b>
                <br /> {dividasVencidas?.totalValue ? toCurrency(dividasVencidas?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Falência/Rec.Judicial
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {falenciaRecJudicial?.quantity ? falenciaRecJudicial?.quantity : "Nada Consta"}
                </b>
                <br /> {falenciaRecJudicial?.totalValue ? toCurrency(falenciaRecJudicial?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Ações Judiciais
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {acaoJudicial?.quantity ? acaoJudicial?.quantity : "Nada Consta"}
                </b>
                <br /> {acaoJudicial?.totalValue ? toCurrency(acaoJudicial?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Protestos
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {protesto?.quantity ? protesto?.quantity : "Nada Consta"}
                </b>
                <br /> {protesto?.totalValue ? toCurrency(protesto?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Cheques
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {cheques?.quantity ? cheques?.quantity : "Nada Consta"}
                </b>
                <br /> {cheques?.totalValue ? toCurrency(cheques?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
          <Grid item xs={3} sm={3}>
              Recheques
              <BoxConsultas>
                <b style={{ color: "#245782" }}>
                  {recheque?.quantity ? recheque?.quantity : "Nada Consta"}
                </b>
                <br /> {recheque?.totalValue ? toCurrency(recheque?.totalValue) : "-"}
              </BoxConsultas>
            </Grid>
            {/* {data && (
              <>
                {resultadoFinal.map((item, index) => {
                  return (
                    <Grid item xs={3} sm={3} key={index.natureCode}>
                      {item.natureCode == "03"
                        ? "Protesto"
                        : item.natureCode == "04"
                        ? "Ação Judicial"
                        : item.natureCode == "05"
                        ? "Participação em falência"
                        : item.natureCode == "06"
                        ? "Falência/concordata"
                        : item.natureCode == "07"
                        ? "Dívida vencida"
                        : "Cheque sem fundo achei"}
                      <BoxConsultas>
                        <b style={{ color: "#245782" }}>{item.quantity}</b>
                        <br />{" "}
                        {item.totalValue == 0
                          ? "-"
                          : toCurrency(item.totalValue)}
                      </BoxConsultas>
                    </Grid>
                  );
                })}
              </>
            )} */}
           
           
          </Grid>
        </Box>
      </Container>
      }
    </>
  );
}

export default MaisAnotacoes;
