import http from "./../../services/api";
import queryString from "query-string";
import store from "../index";
import * as types from "./../types/usuario";
import * as clientes from './../types/clientes'

export const autenticarUsuario = async (email, password) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });

    http.post(`/api/v1/usuarios/autenticar`, { email, password }).then( response => {
      const { usuario, permissao, token, cliente} = response.data 
      
      store.dispatch([
        { type: types.USUARIO_SET, payload: usuario },
        { type: clientes.CLIENTE_SET, payload: cliente },
        { type: types.USUARIO_PERMISSAO, payload: permissao },
        { type: types.USUARIO_LOADING, payload: false },
      ]);    
        localStorage.setItem("token", token);
        localStorage.setItem("permissao", JSON.stringify(permissao)); 
        localStorage.setItem("usuario", JSON.stringify(usuario)); 
        resolve(response.data );     
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject();
      }
    );
  });
};



export const listar_usuario = (filtro, id) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.USUARIO_LOADING, payload: true });

    http.get(`/api/v1/usuarios/administrativo/users/${id}/?${qry}`).then(
      (response) => {
        const  data  = response.data;  
        dispatch([
          { type: types.LISTAR_USUARIO, payload: data.data },
        ]);
      },
      (error) => {
        dispatch({ type: types.USUARIO_LOADING, payload: false });
      }
    );
  };
};


export const cadastrar_usuario = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.post(`/api/v1/usuarios/administrativo`, dados).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject();
      }
    );
  });
};


export const excluir_usuario = async (id) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.delete(`/api/v1/usuarios/administrativo/${id}`,).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject();
      }
    );
  });
};