export default {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px',
      fontFamily: 'Open Sans'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px',
      fontFamily: 'Open Sans'

    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px',
      fontFamily: 'Open Sans'

    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px',
      fontFamily: 'Open Sans'

    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px',
      fontFamily: 'Open Sans'

    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px',
      fontFamily: 'Open Sans'

    },
    overline: {
      fontWeight: 500,
      fontFamily: 'Open Sans'

    },
    p: {
      fontFamily: 'Open Sans'

    }
  };