import { Box, Container, Table, Td, Th, Tr } from "./styles";

function MainSuppliers({ data }) {
  return (
    <>
      {data?.mainSuppliers && (
        <Container>
          <Box>
            <h4>Principais fornecedores</h4>
            <h5>Visão Mercado</h5>
            <Table>
              <Tr>
                <Th>CNPJ do fornecedor</Th>
                <Th>Razão Social do fornecedor</Th>
              </Tr>
              {data?.mainSuppliers?.map((item) => {
              return (
                <tr>
                  <Td>{item.document}{item.branch}{item.documentDigit}</Td>
                  <Td>{item.name}</Td>
                </tr>
              );
            })}
            </Table>
          </Box>
        </Container>
      )}
    </>
  );
}

export default MainSuppliers;
