
import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function BadChecks({consultaJson}){
    return(
        <div id="badChecks">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Cheque sem Fundos BACEN
            </Th>
          </tr>
        </Table>

        {consultaJson?.report?.badChecks.occurrences.length > 0 ? (
          <>
            <Table>
              <tr
                style={{ backgroundColor: "#e6e6e6", color: "#666263" }}
              >
                <Th>Data </Th>
                <Th>Número do Cheque </Th>
                <Th>Cód. Razão </Th>
                <Th>Qtd. CCF </Th>
                <Th>Valor </Th>
                <Th>Número Banco </Th>
                <Th>Banco </Th>
                <Th>Agencia </Th>
                <Th>Cidade </Th>
                <Th>UF </Th>
              </tr>
              {consultaJson?.report?.badChecks.occurrences.map((item) => {
                return (
                  <tr>
                    <Td> {item?.date} </Td>
                    <Td> {item?.checkNumber} </Td>
                    <Td> {item?.reasonCode} </Td>
                    <Td> {item?.ccfQuantity} </Td>
                    <Td>
                      {" "}
                      {item?.totalValue &&
                        toCurrency(item?.totalValue)}{" "}
                    </Td>
                    <Td> {item?.bankNumber} </Td>
                    <Td> {item?.bankName} </Td>
                    <Td> {item?.bankAgency} </Td>
                    <Td> {item?.city} </Td>
                    <Td> {item?.state} </Td>
                  </tr>
                );
              })}
            </Table>
            <Table>
              <tr>
                <Th style={{ color: "#255391", background: "#dee5eb" }}>
                  Total de ocorrências
                  {consultaJson?.report?.badChecks.total}. Periodo{" "}
                  {consultaJson?.report?.badChecks.oldestOccurrenceDate} a{" "}
                  {consultaJson?.report?.badChecks.newestOccurrenceDate}{" "}
                  valor total das ocorrencias{" "}
                  {consultaJson?.report?.badChecks.totalValue &&
                    toCurrency(
                      consultaJson?.report?.badChecks.totalValue
                    )}
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default BadChecks
