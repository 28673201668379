import styled from "@emotion/styled";

export const Th = styled.th`
  border: 1px solid #afacac;
  text-align: left;
  padding: 8px;
  font-weight: bold;
  font-size: 12px;
`;
export const Td = styled.td`
  border: 1px solid #afacac;
  text-align: left;
  padding: 8px;
  font-size: 12px;
`;
export const Table = styled.table`
font-family: 'Roboto', sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin: auto;
  color: #1c1c1c;
`;

export const B = styled.b`
  font-weight: bold;
`;

export const Container = styled.div`
  color: #000;
  font-family: Arial;
  font-size: 16px;
  width: 90%;
  margin: auto;
`;



export const ButtonSave = styled.button`
  padding: 15px;
  background: transparent;
  border: 1px solid #1d4f91;
  border-radius: 8px;
  margin-right: 25px;
  margin-top: 25px;
  cursor: pointer;
  &:hover {
    background: #1d4f91;
    color: #ffffff;
    transition: 0.3s ease-in-out;
  }
`;
