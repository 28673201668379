import { Grid } from "@material-ui/core";
import React from "react";
import { BsGraphUp, BsSpeedometer2 } from "react-icons/bs";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { CardContainer, H3 } from "../style";
import { toCurrency } from "../../../helpers";
import dayjs from "dayjs";
import { useState } from "react";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{ padding: 10, background: "#ffffff" }}>
        <p className="label">{`Data: ${new Date(
          data.created_at
        ).toLocaleDateString("pt-BR")}`}</p>
        <p className="value">{`Valor :  ${toCurrency(data.valor_venda)}`}</p>
      </div>
    );
  }

  return null;
};

function BarChartComponent({ data }) {
  const [eventPress, setEventPress] = useState(false);

  window.addEventListener("beforeprint", function () {
    setEventPress(true);
    console.log("Impressão acionada");
  });

  window.addEventListener("afterprint", function () {
    setEventPress(false);
    console.log("Impressão acionada");
  });

  return (
    <BarChart
      width={eventPress ? 600 : 900}
      height={200}
      data={data}
      margin={{
        top: 5,
        bottom: 5,
      }}
      barSize={30}
    >
      <XAxis
        dataKey="created_at"
        tickFormatter={(name) => new Date(name).toLocaleDateString("pt-BR")}
        scale="point"
        padding={{ left: 20, right: 20 }}
        fontSize={12}
      />
      <YAxis fontSize={14} />
      <Tooltip content={<CustomTooltip />} />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="valor_venda" fill="#30876b" />
    </BarChart>
  );
}

function HistoricoVendas({ data }) {
  return (
    <>
      {data?.historico_vendas && (
        <div style={{ marginTop: 15 }}>
          <div
            style={{
              background: "#F6F7FA",
              borderRadius: 7,
              padding: 15,
            }}
          >
            <h4>
              Histórico de Vendas <BsSpeedometer2 color="#30876b" />
            </h4>
            <h4>
              Evolução nos últimos 12 Meses <BsGraphUp />
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <CardContainer>
                  Média Vendas <h5 style={{ margin: 0 }}>Ult. 12 meses</h5>
                  <H3>
                    {" "}
                    {toCurrency(data?.historico_vendas?.media_geral_vendas)}
                  </H3>
                </CardContainer>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CardContainer>
                  Maior Venda
                  <h5 style={{ margin: 0 }}>Ult. 12 meses</h5>
                  <H3>
                    {toCurrency(
                      data?.historico_vendas?.maior_venda?.valor_venda
                    )}{" "}
                  </H3>
                  <h4 style={{ margin: 0 }}>
                    Data:{" "}
                    {dayjs(
                      data?.historico_vendas?.maior_venda?.created_at
                    ).format("DD/MM/YYYY")}
                  </h4>
                </CardContainer>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CardContainer>
                  Menor Venda <h5 style={{ margin: 0 }}>Ult. 12 meses </h5>
                  <H3>
                    {toCurrency(
                      data?.historico_vendas?.menor_venda?.valor_venda
                    )}{" "}
                  </H3>
                  <h4 style={{ margin: 0 }}>
                    Data:{" "}
                    {dayjs(
                      data?.historico_vendas?.menor_venda?.created_at
                    ).format("DD/MM/YYYY")}
                  </h4>
                </CardContainer>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CardContainer>
                  Vendas Efetuadas <h5 style={{ margin: 0 }}>Ult. 12 meses </h5>
                  <H3>
                    {data?.historico_vendas?.quantidade_vendas_efetuadas}{" "}
                  </H3>
                </CardContainer>
              </Grid>
            </Grid>
            <div style={{ marginTop: 15 }}>
              <BarChartComponent data={data?.historico_vendas?.registros} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HistoricoVendas;
