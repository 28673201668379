

import { Box, Container, Table, Td, Th, Tr } from "./styles";

function BankruptcyParticipations({ data }) {
  return (
    <>
      {data?.bankruptcyParticipations && (
        <Container>
          <Box>
          <h4>Participações em falências - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>CNPJ</Th>
              <Th>Razão Social</Th>
              <Th>Participação</Th>
              <Th>Quantidade</Th>
            </Tr>
            {data.bankruptcyParticipations.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>
                  <Td>{item.document} </Td>
                  <Td>{item.name} </Td>
                  <Td>{item.participation}</Td>
                  <Td>{item.quantity}</Td>
                </tr>
              );
            })}
          </Table>
      </Box>
    </Container>
      )}
      </>
  );
}

export default BankruptcyParticipations;
