import styled from "@emotion/styled";
import dayjs from "dayjs";
import BudgetPieChart from "../scoreComponents/budgetPieChart";

function SerasaCompaniesPositiveScore({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 50%;
    margin-left: 15px;
    border-radius: 7px;
    @media print {
    width: 100%;
    font-size: 10px;
  }
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

  return (
    <>
      {data.report?.serasaCompaniesPositiveScore && (
        <div
          style={{  padding: 15, borderRadius: 8 }}
        >
          <div
            style={{
              width: '80%',
              margin: "auto",
              padding: 12,
              borderRadius: 8,
              border: '1px solid #AFACAC'
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 250, padding: 15, borderRadius: 15 }}>
                Serasa Score 2.0
                <div style={{ marginTop: 35 }}>
                  <BudgetPieChart
                    value={
                      data.report.serasaCompaniesPositiveScore.score
                        ? data.report.serasaCompaniesPositiveScore.score
                        : 0
                    }
                  />
                  <div style={{ textAlign: "center", fontSize: 18 }}>
                    <b style={{ fontSize: 14 }}>Score</b> <br />{" "}
                    {data.report.serasaCompaniesPositiveScore.score
                      ? data.report.serasaCompaniesPositiveScore.score
                      : "0"}
                    <h6 style={{ margin: 0, fontWeight: "normal" }}>
                      {" "}
                      de 1000
                    </h6>
                  </div>
                </div>
              </div>
              <div style={{ width: "70%" }}>
                <h4 style={{ textAlign: "right", fontSize: 13 }}>
                  Informação Atualizada em{" "}
                  {data.report.serasaCompaniesPositiveScore.dateTime ? dayjs(data.report.serasaCompaniesPositiveScore.dateTime).format('DD/MM/YYYY HH:mm') : ''}
                </h4>

                <div style={{ width: "100%" }}>
                  <h4
                    style={{
                      margin: 0,
                      textAlign: "right",
                      fontSize: 13,
                      padding: 15,
                      color: "#333333",
                    }}
                  >
                    ______ Práticas de mercado ______
                  </h4>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        <b style={{ color: "#426fb3" }}>
                          Probabilidade de <br />
                          Inadimplência
                        </b>
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        {data.report.serasaCompaniesPositiveScore.prinad ? (
                          <>
                            {data.report.serasaCompaniesPositiveScore.prinad} %
                          </>
                        ) : (
                          "Não Cal"
                        )}
                      </div>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <b style={{ color: "#426fb3" }}>
                          Risco de
                          <br /> Crédito
                        </b>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        {data.report.serasaCompaniesPositiveScore
                          .score && (
                          <>
                            {data.report.serasaCompaniesPositiveScore
                              .score < 250
                              ? "Alto"
                              : data.report.serasaCompaniesPositiveScore
                                  .score > 251 &&
                                data.report.serasaCompaniesPositiveScore
                                  .score < 500
                              ? "Médio"
                              : data.report.serasaCompaniesPositiveScore
                                  .score > 501 &&
                                data.report.serasaCompaniesPositiveScore
                                  .score < 750
                              ? "Baixo"
                              : "Mínimo"}
                          </>
                        )}
                      </div>
                    </Box>
                  </div>
                  <div
                    style={{
                      border: "1px solid #afacac",
                      borderRadius: 7,
                      margin: "auto",
                      marginTop: 15,
                      width: "90%",
                      padding: 15,
                    }}
                  >
                    A pontuação enquadra-se na faixa de
                    {data.report.serasaCompaniesPositiveScore.score < 50
                      ? "de 01 a 50"
                      : data.report.serasaCompaniesPositiveScore.score >
                          51 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          100
                      ? "de 51 a 100"
                      : data.report.serasaCompaniesPositiveScore.score >
                          101 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          150
                      ? "de 101 a 150"
                      : data.report.serasaCompaniesPositiveScore.score >
                          151 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          200
                      ? "de 150 a 201"
                      : data.report.serasaCompaniesPositiveScore.score >
                          201 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          250
                      ? "de 201 a 250"
                      : data.report.serasaCompaniesPositiveScore.score >
                          250 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          301
                      ? "de 250 a 301"
                      : data.report.serasaCompaniesPositiveScore.score >
                          301 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          350
                      ? "de 301 a 350"
                      : data.report.serasaCompaniesPositiveScore.score >
                          351 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          400
                      ? "de 351 a 400"
                      : data.report.serasaCompaniesPositiveScore.score >
                          401 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          450
                      ? "de 401 a 450"
                      : data.report.serasaCompaniesPositiveScore.score >
                          451 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          500
                      ? "de 451 a 500"
                      : data.report.serasaCompaniesPositiveScore.score >
                          501 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          550
                      ? "de 501 a 550"
                      : data.report.serasaCompaniesPositiveScore.score >
                          551 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          600
                      ? "de 551 a 600"
                      : data.report.serasaCompaniesPositiveScore.score >
                          601 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          650
                      ? "de 601 a 650"
                      : data.report.serasaCompaniesPositiveScore.score >
                          651 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          700
                      ? "de 651 a 700"
                      : data.report.serasaCompaniesPositiveScore.score >
                          701 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          750
                      ? "de 701 a 750"
                      : data.report.serasaCompaniesPositiveScore.score >
                          751 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          800
                      ? "de 751 a 800"
                      : data.report.serasaCompaniesPositiveScore.score >
                          801 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          850
                      ? "de 801 a 850"
                      : data.report.serasaCompaniesPositiveScore.score >
                          851 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          900
                      ? "de 851 a 900"
                      : data.report.serasaCompaniesPositiveScore.score >
                          901 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          950
                      ? "de 901 a 950"
                      : data.report.serasaCompaniesPositiveScore.score >
                          951 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          1000
                      ? "de 951 a 1000"
                      : "Não calculado"}{" "}
                    e representa risco{" "}
                    {data.report.serasaCompaniesPositiveScore.score < 250
                      ? "Alto"
                      : data.report.serasaCompaniesPositiveScore.score >
                          251 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          500
                      ? "médio"
                      : data.report.serasaCompaniesPositiveScore.score >
                          501 &&
                        data.report.serasaCompaniesPositiveScore.score <
                          750
                      ? "baixo"
                      : "mínimo"}{" "}
                    de crédito. Para empresas com este perfil de risco, é
                    prática de mercado conceder crédito para compras de
                    mercadorias ou serviços sem necessidades de garantias
                    adicionais. Em média, empresas com esta categoria de risco
                    costumam honrar os compromissos assumidos em {data.report.serasaCompaniesPositiveScore.prinad ? 100 - data.report.serasaCompaniesPositiveScore.prinad : 0} %
                  </div>
                </div>
              </div>
            </div>
            <div>
              <b style={{ color: "gray", fontSize: 12, marginLeft: 20 }}>
                Legenda
              </b>
              <div id="legend" style={{ marginTop: 15, marginLeft: 20 }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    fontSize: 14,
                    color: "gray",
                    marginTop: 10,
                  }}
                >
                  <Barra style={{ background: "#0d6eb7" }} /> Risco mínimo a
                  baixo de crédito
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    fontSize: 14,
                    color: "gray",
                    marginTop: 10,
                  }}
                >
                  <Barra style={{ background: "#00a045" }} /> Risco baixo a
                  médio de crédito
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    fontSize: 14,
                    color: "gray",
                    marginTop: 10,
                  }}
                >
                  <Barra style={{ background: "#ffda00" }} /> Risco médio a
                  relevane de crédito
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    fontSize: 14,
                    color: "gray",
                    marginTop: 10,
                  }}
                >
                  <Barra style={{ background: "#ff8e21" }} /> Iminente risco de
                  crédito - Default
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


export default SerasaCompaniesPositiveScore