import * as types from '../types/validadoc';

const INITIAL_STATE = {
    loading: false,
    token: '',
    validaDocRetorno: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.VALIDA_DOC_LOAD:
            return { ...state, loading: action.payload};

        case types.VALIDA_DOC_TOKEN:
            return { ...state, token: action.payload};   
                   
        case types.VALIDA_DOC_RETORNO:
            return { ...state, validaDocRetorno: action.payload};   
                   

      default:
        return state;
    }
};