

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function TotalOcorrencias({consultaJson}){
return(
     <div id="totalOcorrencias">
     <Table>
       <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
         <Th>Ocorrencias </Th>
         <Th>Quantidade </Th>
         <Th>Valor </Th>
         <Th>Ultimo Registro </Th>
       </tr>
       <tr>
         <Th>Pendências Internas </Th>
         <Td> {consultaJson?.report?.internalPendencies.totalOccurrences ? consultaJson?.report?.internalPendencies.totalOccurrences :  "NAO CONSTAM OCORRENCIAS" }  </Td>
         <Td>
           {" "}
           {consultaJson?.report?.internalPendencies.totalValue &&
             toCurrency(consultaJson?.report?.internalPendencies.totalValue)}{" "}
         </Td>
         <Td> {consultaJson?.report?.internalPendencies.newestOccurrenceDate} </Td>
       </tr>
       <tr>
         <Th>Pendências Pefin </Th>
         <Td> {consultaJson?.report?.financialPendencies.pefin.total ? consultaJson?.report?.financialPendencies.pefin.total  : "NAO CONSTAM OCORRENCIAS"}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.financialPendencies.pefin.totalValue &&
             toCurrency(
               consultaJson?.report?.financialPendencies.pefin.totalValue
             )}
         </Td>
         <Td>
           {" "}
           {
             consultaJson?.report?.financialPendencies.pefin.newestOccurrenceDate
           }{" "}
         </Td>
       </tr>
       <tr>
         <Th>Pendências Dívida Vencida </Th>
         <Td> {consultaJson?.report?.financialPendencies?.overdueDebt.total ? consultaJson?.report?.financialPendencies?.overdueDebt.total :  "NAO CONSTAM OCORRENCIAS"}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.financialPendencies?.overdueDebt.totalValue &&
             toCurrency(
               consultaJson?.report?.financialPendencies?.overdueDebt.totalValue
             )}
         </Td>
         <Td>
           {consultaJson?.report?.financialPendencies?.overdueDebt.newestOccurrenceDate}
         </Td>
       </tr>
       <tr>
         <Th>Protesto Nacional</Th>
         <Td> {consultaJson?.report?.stateProtests?.total ? consultaJson?.report?.stateProtests?.total :  "NAO CONSTAM OCORRENCIAS"}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.stateProtests?.totalValue &&
             toCurrency(
               consultaJson?.report?.stateProtests?.totalValue
             )}
         </Td>
         <Td>
           {
             consultaJson?.report?.stateProtests
               ?.newestOccurrenceDate
           }
         </Td>
       </tr>


       <tr>
         <Th>Cheque sem fundo BACEN</Th>
         <Td> {consultaJson?.report?.badChecks.total  ? consultaJson?.report?.badChecks.total : "NAO CONSTAM OCORRENCIAS"}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.badChecks.totalValue &&
             toCurrency(consultaJson?.report?.badChecks.totalValue)}
         </Td>
         <Td>{consultaJson?.report?.badChecks.newestOccurrenceDate}</Td>
       </tr>


       {consultaJson?.report?.completeAnnotations && (
              <>        
               <tr>
             <Th>Ações judiciais</Th>
              <Td> {consultaJson?.report?.completeAnnotations?.judicialActions.total  ? consultaJson?.report?.completeAnnotations?.judicialActions.total : "NAO CONSTAM OCORRENCIAS"}</Td>
              <Td>
                {" "}
                {consultaJson?.report?.completeAnnotations?.judicialActions.totalValue &&
                  toCurrency(consultaJson?.report?.completeAnnotations?.judicialActions.totalValue)}
              </Td>
              <Td>{consultaJson?.report?.completeAnnotations?.judicialActions.finalDate}</Td>
       </tr>

       <tr>
             <Th>Participações em Falências</Th>
              <Td> {consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.total  ? consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.total : "NAO CONSTAM OCORRENCIAS"}</Td>
              <Td>
                {" "}
                {consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.totalValue &&
                  toCurrency(consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.totalValue)}
              </Td>
              <Td>{consultaJson?.report?.completeAnnotations
          .unsuccessfulCompanyParticipations.finalDate}</Td>
       </tr>


       <tr>
             <Th>Falência/Concordata/Recuperação Judicial</Th>
              <Td> {consultaJson?.report?.completeAnnotations.bankruptcies.total  ? consultaJson?.report?.completeAnnotations.bankruptcies.total : "NAO CONSTAM OCORRENCIAS"}</Td>
              <Td>
                {" "}
                {consultaJson?.report?.completeAnnotations.bankruptcies.totalValue &&
                  toCurrency(consultaJson?.report?.completeAnnotations.bankruptcies.totalValue)}
              </Td>
              <Td>{consultaJson?.report?.completeAnnotations.bankruptcies.finalDate}</Td>
       </tr>
              </>
            )}





     </Table>








     <Table>
       <tr>
         <Th style={{ color: "#255391", background: "#dee5eb" }}>
           Detalhes do Documento
         </Th>
         <Th style={{ color: "#255391", background: "#dee5eb" }}>
           Situação do CPF/CNPJ em{" "}
           {consultaJson?.report?.confirmei.documentStatusDate} :
           {consultaJson?.report?.confirmei.documentStatus}
         </Th>
       </tr>
     </Table>
     <br />
   </div>
   
)}

export default TotalOcorrencias