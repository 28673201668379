import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  forget: {},
  container:{    
    marginTop: "5vh",
    padding: '120px 80px',
    borderRadius: '5px',
    width: '20%',
    margin: 'auto',
    boxShadow: '0px 0px 13px 2px #d3d3d3'
  },
  forgetContainer: {
    margin: 'auto',
    borderRadius: '7px',
    alignItems: "center",
    margin: "auto 0",
    textAlign: "center",
    justifyContent: "center",
    "& h2": {
      fontSize: "16px",
    },
    "& img": {
      width: "200px",
      marginBottom: "3em",
    },
    "& button": {
      padding: "15px 100px",
      borderRadius: "5px",
      border: "1px solid #9f9f9f",
      cursor: "pointer",
      marginTop: "2em",
      backgroundColor: "#1d4f91",
      color: "#fff",
    },
  },
}));

export default useStyles;
