

import toCurrency from "../../../../helpers/toCurrency";
import { Box, Container, Table, Td, Th, Tr } from "./styles";

function OverdueDebts({ data }) {
  return (
    <>
      {data?.overdueDebts && (
        <Container>
          <Box>
          <h4>Divídas vencidas - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>Modalidade</Th>
              <Th>Valor</Th>
              <Th>Titulo</Th>
              <Th>Instituição Cobradora</Th>
              <Th>Local</Th>
            </Tr>
            {data.overdueDebts.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>
                  <Td>{item.modality}</Td>
                  <Td>{toCurrency(item.value)}</Td>
                  <Td>{item.title}</Td>
                  <Td>{item.financialInstitution}</Td>
                  <Td>{item.local}</Td>                
                </tr>
              );
            })}
          </Table>
          </Box>
        </Container>
        )}
        </>

  );
}

export default OverdueDebts;
