
import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function FinancialPendenciesRefin({consultaJson}){
    return(
        <div id="financialPendenciesRefin">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Pendências Financeiras Refin
            </Th>
          </tr>
        </Table>

        {consultaJson?.report?.financialPendencies?.refin?.occurrences?.length > 0 ? (
          <>
            <Table>
              <tr
                style={{ backgroundColor: "#e6e6e6", color: "#666263" }}
              >
                <Th>Data </Th>
                <Th>Modalidade </Th>
                <Th>Aval. </Th>
                <Th>Valor </Th>
                <Th>Contrato </Th>
                <Th>Origem </Th>
                <Th>Praça </Th>
              </tr>
              {consultaJson?.report?.financialPendencies?.refin?.occurrences.map(
                (item) => {
                  return (
                    <tr>
                      <Td> {item?.date} </Td>
                      <Td> {item?.modality} </Td>
                      <Td> {item?.isGuarantor ? "SIM" : "NÃO"} </Td>
                      <Td> {item?.value && toCurrency(item?.value)} </Td>
                      <Td> {item?.contract} </Td>
                      <Td> {item?.origin} </Td>
                      <Td> {item?.embratelInitials} </Td>
                    </tr>
                  );
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th style={{ color: "#255391", background: "#dee5eb" }}>
                  Total de ocorrências &nbsp;
                  {consultaJson?.report?.financialPendencies?.refin?.total}.
                  Periodo{" "}
                  {
                    consultaJson?.report?.financialPendencies?.refin?.oldestOccurrenceDate
                  }{" "}
                  a{" "}
                  {
                    consultaJson?.report?.financialPendencies?.refin?.newestOccurrenceDate
                  }{" "}
                  valor total das ocorrencias{" "}
                  {consultaJson?.report?.financialPendencies?.refin?.totalValue &&
                    toCurrency(
                      consultaJson?.report?.financialPendencies?.refin?.totalValue
                    )}
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default FinancialPendenciesRefin