import { Tooltip } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

const BarChartComponent = ({ data }) => {
  const [eventPress, setEventPress] = useState(false);

  window.addEventListener("beforeprint", function () {
    setEventPress(true);
    console.log("Impressão acionada");
  });

  window.addEventListener("afterprint", function () {
    setEventPress(false);
    console.log("Impressão acionada");
  });

  return (
    <div style={{ flexDirection: "row" }}>
      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <BarChart
          width={eventPress ? 550 : 1000}
          height={100}
          data={data}
          barSize={eventPress ? 20 : 40}
        >
          <Bar dataKey="uv" fill="#244a93" />
          <XAxis dataKey="name" fontSize={eventPress ? 10 : 14}/>
          <Tooltip />
        </BarChart>
      </div>
      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
          width: eventPress ? 550 : 1000,
          margin: "auto",
        }}
      >
        {data.map((item) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  fontSize: 13,
                  padding: 3,
                  margin: 5,
                  borderRadius: 14,
                }}
              >
                {item.undueRangeCode}
              </div>
              <div
                style={{
                  fontSize: 13,
                  padding: 3,
                  background: "#d6d6d6",
                  margin: 5,
                  borderRadius: 14,
                }}
              >
                <b>{item.undueRangeDescription}</b>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

BarChartComponent.demoUrl = "https://codesandbox.io/s/tiny-bar-chart-35meb";

export default BarChartComponent;
