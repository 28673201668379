
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';


export default function BudgetPieChart({value}){



  const RADIAN = Math.PI / 180;
  const data = [
    { name: 'A', value: 250, color: '#ff8e21' },
    { name: 'B', value: 250, color: '#ffda00' },
    { name: 'C', value: 250, color: '#00a045' },
    { name: 'C', value: 250, color: '#0d6eb7' },
  ];
  
  const cx = 120;
  const cy = 170;
  const iR = 60;
  const oR = 70;
  // const value = 700;
  
  
  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;
  
    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />
    ];
  };



    return (
      <div style={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: 'center', marginTop: -90}}>
      <PieChart width={250} height={190}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color}  />
          ))}
        </Pie>
        {value}
        {needle(value, data, cx, cy, iR, oR, '#525659')}
      </PieChart>
      </div>
    );
  }
