import { Box, Container } from "./styles";

function Spelling({ data }) {
  return (
    <>
      {data?.spelling && (
        <Container style={{ marginTop: 15 }}>
          <Box>
            <h4>Grafias semelhantes ao CNPJ</h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid gray",
                  padding: "25px 50px",
                  borderRadius: 10,
                  textAlign: "center",
                  margin: "20px 80px",
                }}
              >
                {data.spelling.length > 4 ? (
                  <>
                    Mais de <br />
                    <b style={{ color: "blue", fontSize: 18 }}>
                      {data.spelling.length - 1}{" "}
                    </b>
                    <br />
                    variações encontradas
                  </>
                ) : (
                  <>
                    <b style={{ color: "blue", fontSize: 18 }}>
                      {data.spelling.length}{" "}
                    </b>
                    <br />
                    variações encontrada(s)
                  </>
                )}
              </div>
              <div>
                {data.spelling.map((item) => {
                  return (
                    <>
                      {item.spellings}
                      <br />
                    </>
                  );
                })}
              </div>
            </div>
          </Box>
        </Container>
      )}
    </>
  );
}

export default Spelling;
