import BadChecks from "./badChecks";
import Bankruptcies from "./bankruptcies";
import BankruptcyParticipations from "./bankruptcyParticipations";
import Lawsuits from "./lawsuits";
import MaisAnotacoes from "./maisAnotacoes";
import OverdueDebts from "./overdueDebts";
import Pefin from "./pefin";
import Protests from "./protests";
import Refin from "./refin";
import Resumo from "./resumo";
import Spelling from "./spelling";

function ConcentreInfo({ data }) {

  return (
    <div>
      <Spelling data={data?.concentreInfo} />
      <MaisAnotacoes data={data?.concentreInfo} pefin={data?.financialPendencies?.pefin} refin={data?.financialPendencies?.refin} />
      <Resumo data={data?.concentreInfo} />
      <Pefin data={data?.financialPendencies} />
      <Refin data={data?.financialPendencies} />
      <Protests data={data?.concentreInfo} />
      <Lawsuits data={data?.concentreInfo} />
      <BankruptcyParticipations data={data?.concentreInfo} />
      <Bankruptcies data={data?.concentreInfo} />
      <OverdueDebts data={data?.concentreInfo} />
      <BadChecks data={data?.concentreInfo} />
    </div>
  );
}

export default ConcentreInfo;
