import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    minHeight: 35,
    lineHeight: 1,
    textAlign: 'center',
    position: 'static',
    padding: 0,
  },
  head: {
    fontSize: 12,
    color: palette.text.primary
  },
  sizeSmall: {
    padding: 0
  }
};