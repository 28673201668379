import React from "react";
import { Navigate } from "react-router-dom";
import BasicoPj from "../templates/basicoPj/basicoPj";

import AvancadoPj from "../templates/avancadoPj";
import {
  AvancadoPessoaFisica,
  BasicoPf,
  ChangePassword,
  Clientes,
  ClientesVinculados,
  ConsumoFinanceiro,
  DashboardLayout,
  ForgetPassword,
  Home,
  Login,
  PlanosClientes
} from "./index";
import ValidaDocPage from "../pages/validaDoc";
import ConsumoFaturaAdministrador from "../pages/consumo_financeiro/fatura_detalhada_administrativo";

const routes = [
  {
    // Autenticação
    path: "/",
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", element: <Login /> },
      { path: "/esqueci-senha", element: <ForgetPassword /> },
      { path: "/password/reset/:id/:key", element: <ChangePassword /> },
    ],
  },

  {
    // Rotas
    path: "/app",
    element: <DashboardLayout />,
    children: [
      { path: "/app/home", element: <Home /> },
      { path: "*", element: <Navigate to="/app/home" /> },
      { path: "/app/consumo", element: <ConsumoFinanceiro /> },
      { path: "/app/consumo/:mes_filtro/:ano_filtro/:id", element: <ConsumoFaturaAdministrador /> },
      { path: "/app/clientes", element: <Clientes /> },
      { path: "/app/valida-doc", element: <ValidaDocPage /> },
      { path: "/app/clientes/usuarios/:id", element: <ClientesVinculados /> },
      { path: "/app/clientes/planos/:id", element: <PlanosClientes /> },
      { path: "/app/consulta/basicopf/:cnpj/:id", element: <BasicoPf /> },
      { path: "/app/consulta/avancadopf/:cnpj/:id", element: <AvancadoPessoaFisica /> },
      { path: "/app/consulta/basicopj/:cnpj/:id", element: <BasicoPj /> },
      { path: "/app/consulta/avancadopj/:cnpj/:id", element: <AvancadoPj /> },
 
    ],
  },
];

export default routes;
