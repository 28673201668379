import html2pdf from "html2pdf.js";
import React, { useEffect, useRef } from "react";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionConsultaJson } from "../../store/actions/consultas";
import ConcentreInfo from "./components/concentreInfo";
import { CreditLimit } from "./components/creditLimit";
import HistoricoScoreConexo from "./components/historicoScoreConexo";
import HistoricoVendasConexo from "./components/historicoVendasConexo";
import { InformacoesSocietarias } from "./components/informacoesSocietarias";
import { Participants } from "./components/participants";
import PositiveCommercialHistory from "./components/positiveCommercialHistory";
import PresumedBilling from "./components/presumedBilling";
import { RegisterInformation } from "./components/registerInformation";
import { SerasaQueries } from "./components/serasaQueries";
import { SerasaScoreWithPositive } from "./components/serasaScoreWithPositive";
import { SyntheticRegistration } from "./components/syntheticRegistration";
import { ButtonSave, Container } from "./style";

function AvançadoPj() {
  const { id, cnpj } = useParams();
  const dispatch = useDispatch();
  const { consultaJson, consultaLoading } = useSelector(
    (state) => state.consultas
  );

  useEffect(() => {
    dispatch(ActionConsultaJson(id, cnpj));
  }, []);

  const printableRef = useRef(null);

  const imprimirPDF = () => {
    const printableComponent = printableRef.current;

    if (printableComponent) {
      const element = printableComponent.cloneNode(true);
      const options = {
        filename: `${consultaJson?.meta?.document}.pdf`,
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a2", orientation: "portrait" },
        autotable: { startY: false },
      };

      html2pdf().from(element).set(options).save();
    }
  };

  return (
    <>
      {!consultaLoading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "90%",
            }}
          >
            <ButtonSave onClick={imprimirPDF}>
              <BsFileEarmarkPdf />
              Salvar em PDF
            </ButtonSave>
          </div>
          <div ref={printableRef}>
            <Container>
              <h3 style={{ textAlign: "center", fontFamily: "Roboto" }}>
                Relatório Avançado | Pessoa Jurídica
              </h3>

              <RegisterInformation data={consultaJson} />
              <SerasaScoreWithPositive data={consultaJson} />
              <PresumedBilling data={consultaJson.report} />
              <CreditLimit data={consultaJson} />
              <SyntheticRegistration data={consultaJson} />
              <InformacoesSocietarias data={consultaJson} />

              {/* Features */}
              <Participants data={consultaJson} />

              {/* ------------------- HISTÓRICO DE VENDAS CONEXO */}
              <HistoricoScoreConexo data={consultaJson} />
              <HistoricoVendasConexo data={consultaJson} />

              <SerasaQueries data={consultaJson} />

              <ConcentreInfo data={consultaJson.report} />

              {/* Features */}
              <PositiveCommercialHistory data={consultaJson.report} />
              {/* <ParticipantsWithAnnotations data={consultaJson.report} /> */}
            </Container>
          </div>
        </>
      )}
    </>
  );
}

export default AvançadoPj;
