import { Td, Table, Th } from "../style";
import { FiAlertTriangle } from 'react-icons/fi' 


function SociosEAcionistas({ consultaJson }) {

  return (
    <div id="partnerParticipations">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Sócios e Administradores
          </Th>
        </tr>
      </Table>
      {consultaJson?.report?.partnersShareholders?.occurrences?.length > 0 ? (
        <>
        <br/>
          <Table>
            <tr>
              <Th style={{ color: "#666263", background: "#faf6d3" }}>
                <b style={{marginLeft: 20}}>Legenda</b>:  <FiAlertTriangle color="#ce5712" size={16}/>  Sócios, Administradores e PArticipantes que possuem anotações negativas.
              </Th>
            </tr>
          </Table>

          <br/>
          <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Sócios e Acionistas
          </Th>
        </tr>
      </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th> CPF/CNPJ </Th>
              <Th> Sócio/Acionista</Th>
              <Th> % Capital Total </Th>
            </tr>
            {consultaJson?.report?.partnersShareholders?.occurrences.map(
              (item) => {
                return (
                  <tr>
                    <Td>{item?.restrictions &&  <FiAlertTriangle color="#ce5712" size={16}/>  }  {item?.document}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.totalCapitalPercentage}</Td>
                  </tr>
                );
              }
            )}
          </Table>
        </>
      ) : (
        <Table>
          <tr>
            <Th>NÃO CONTAM OCORRENCIAS</Th>
          </tr>
        </Table>
      )}
    </div>
  );
}

export default SociosEAcionistas;
