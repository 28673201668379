import React from "react";
import useStyles from "./styles";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

export default function Financeiro() {
  const classes = useStyles();

  return (
    <div className={classes.Btn}>
      <Link to="consumo" style={{ textDecoration: "none" }}>
        <Button>
          <EqualizerIcon /><h1>Financeiro</h1>
        </Button>
      </Link>
    </div>
  );
}
