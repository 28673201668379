import { Grid } from "@material-ui/core";
import { Clear as ClearIcon, Send as SendIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CDialog from "../../components/CDialog";
import { efetuar_consulta, listarPrecosClientesActions } from "../../store/actions/consultas";
import { listar_consultas } from "./../../store/actions/consultas";
import CategoriaDeConsulta from "./components/categorias_de_consulta";
import ModalTipo from "./components/modal_tipo";
import TabelaConsultas from "./components/tabela_consultas";
import useStyles from "./styles";


function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogConsulta, setDialogConsulta] = useState(false);
  const [tipo_pessoa, setTipoDocumento] = React.useState("FISICA");
  const [tipo_consulta, setTipoConsulta] = React.useState("basica");
  const [documento, setDocumento] = useState({ cpf: "", cnpj: "" });
  const { cliente } = useSelector((state) => state.clientes);
  const {  permissao } = useSelector((state) => state.usuario);
  const [open_tipo, setOpentipo] = useState(false);
  const [status_filtro, setStatusFiltro] = useState();  

  const clienteId = JSON.parse(localStorage.getItem("permissao"))

  useEffect(() => {


      dispatch( listarPrecosClientesActions(clienteId?.cliente_id ))
    
  }, []);


  const toUpper = (str) =>
    str != undefined && str != null ? str.toString().toUpperCase() : "";
  const removedots = (value) =>
    value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");
  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  const handleChange = (event) => {
    setTipoDocumento(event.target.value);
  };

  const status = status_filtro == "P" ? undefined : "P";

  const handleChangeStatus = (event) => {
    setStatusFiltro(event.target.value);
    dispatch(listar_consultas({ page: 1, per_page: 10, status }));
  };

  const handleChangeTipo = (event) => {
    setTipoConsulta(event.target.value);
  };

  let cnpj = tipo_pessoa == "FISICA" ? documento.cpf : documento.cnpj;

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  async function realizarConsulta() {
    try {
      await efetuar_consulta({
        documento: removedots(cnpj),
        tipo_pessoa: toUpper(tipo_pessoa),
        tipo_consulta: toUpper(tipo_consulta),
        anotacoes_completas: false,
        anotacoes_consultas_spc: false
      });
      Toast.fire({
        icon: "success",
        title: "Consulta efetuada com sucesso!",
      });
      dispatch(listar_consultas({ page: 1, per_page: 10 }));
      setDialogConsulta(false);
      setDocumento({ cpf: "", cnpj: "" });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos!",
      });
    }
  }

  return (
    <>
      <div className={classes.home}>
        <div className={classes.homeContainer}>
          <h1>Selecione a modalidade de consulta :</h1>
          <Grid container spacing={2}>
            <CategoriaDeConsulta
              tipo_pessoa={tipo_pessoa}
              handleChange={handleChange}
              documento={documento}
              setDocumento={setDocumento}
              tipo_consulta={tipo_consulta}
              handleChangeTipo={handleChangeTipo}
              setOpentipo={setOpentipo}
              setDialogConsulta={setDialogConsulta}
              cliente={cliente}
              toCurrency={toCurrency}
              status={status_filtro}
              handleChangeStatus={handleChangeStatus}
            />
            <TabelaConsultas status_filtro={status_filtro} />
          </Grid>
          <CDialog
            open={dialogConsulta}
            title="Os dados enviados estão corretos?"
            contenttext={
              <>
                <b>Tipo da consulta</b>: {toUpper(tipo_pessoa)}{" "}
                {toUpper(tipo_consulta)}
                <br />
                <b>Documento</b>:
                {tipo_pessoa == "FISICA" ? documento.cpf : documento.cnpj}
                <br />
                <b>Valor:</b>:
                {tipo_consulta == "basica" && tipo_pessoa == "FISICA"
                  ? toCurrency(cliente?.valor_pf_basica)
                  : tipo_consulta == "completa" && tipo_pessoa == "FISICA"
                  ? toCurrency(cliente?.valor_pf_avancada)
                  : tipo_consulta == "basica" && tipo_pessoa == "JURIDICA"
                  ? toCurrency(cliente?.valor_pj_basica)
                  : tipo_consulta == "completa" && tipo_pessoa == "JURIDICA"
                  ? toCurrency(cliente?.valor_pj_avancada)
                  : ""}
              </>
            }
            buttons={[
              {
                title: "Cancelar",
                icon: <ClearIcon />,
                style: { color: "gray" },
                onClick: () => {
                  setDialogConsulta(false);
                },
              },
              {
                title: "Confirmar",
                icon: <SendIcon />,
                style: { color: "green" },
                onClick: () => realizarConsulta(),
              },
            ]}
          />
        </div>
      </div>
      <ModalTipo
        open={open_tipo}
        tipo={tipo_pessoa}
        close={() => setOpentipo(false)}
        classes={classes}
      />
    </>
  );
}

export default Home;
