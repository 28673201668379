import React, { useState , useEffect} from "react";
import CadastroCliente from "./components/cadastro";
import useStyles from "./styles";
import { Button, Divider } from "@material-ui/core";
import ListadeClientes from "./components/lista_clientes";
import { PersonAddOutlined } from "@material-ui/icons";
import { listar_cliente } from './../../store/actions/clientes';

function Clientes() {

  const classes = useStyles();
  const [open_modal, setOpenModal] = useState(false);


  return (
    <div className={classes.clientes}>
      <div className={classes.clientesContainer}>
        <h4>Clientes</h4>

        <Divider />
        <div style={{ float: "right" }} className={classes.cadastro}>
          <Button onClick={() => setOpenModal(true)}>        
            <PersonAddOutlined style={{ fontSize: "16px" }} /> &nbsp; Cadastrar
            Cliente
          </Button>
        </div>
        <CadastroCliente
          open={open_modal}
          close={() => setOpenModal(false)}
          classes={classes}
        />

        <ListadeClientes />
      </div>
    </div>
  );
}

export default Clientes;
