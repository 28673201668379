

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function TotalOcorrencias({consultaJson}){
return(
     <div id="totalOcorrencias">
     <Table>
       <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
         <Th>Ocorrencias </Th>
         <Th>Quantidade </Th>
         <Th>Valor </Th>
         <Th>Ultimo Registro </Th>
       </tr>
       <tr>
         <Th>Pendências Internas </Th>
         <Td> {consultaJson?.report?.internalPendencies.totalOccurrences} </Td>
         <Td>
           {" "}
           {consultaJson?.report?.internalPendencies.totalValue &&
             toCurrency(consultaJson?.report?.internalPendencies.totalValue)}{" "}
         </Td>
         <Td> {consultaJson?.report?.internalPendencies.newestOccurrenceDate} </Td>
       </tr>
       <tr>
         <Th>Pendências Pefin </Th>
         <Td> {consultaJson?.report?.financialPendencies.pefin.total}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.financialPendencies.pefin.totalValue &&
             toCurrency(
               consultaJson?.report?.financialPendencies.pefin.totalValue
             )}
         </Td>
         <Td>
           {" "}
           {
             consultaJson?.report?.financialPendencies.pefin.newestOccurrenceDate
           }{" "}
         </Td>
       </tr>
       <tr>
         <Th>Pendências Refin </Th>
         <Td> {consultaJson?.report?.financialPendencies.refin.total}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.financialPendencies.refin.totalValue &&
             toCurrency(
               consultaJson?.report?.financialPendencies.refin.totalValue
             )}
         </Td>
         <Td>
           {consultaJson?.report?.financialPendencies.refin.newestOccurrenceDate}
         </Td>
       </tr>
       <tr>
         <Th>Protesto Nacional</Th>
         <Td> {consultaJson?.report?.stateProtests?.total}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.stateProtests?.totalValue &&
             toCurrency(
               consultaJson?.report?.stateProtests?.totalValue
             )}
         </Td>
         <Td>
           {
             consultaJson?.report?.stateProtests
               ?.newestOccurrenceDate
           }
         </Td>
       </tr>
       <tr>
         <Th>Cheque sem fundo BACEN</Th>
         <Td> {consultaJson?.report?.badChecks.total}</Td>
         <Td>
           {" "}
           {consultaJson?.report?.badChecks.totalValue &&
             toCurrency(consultaJson?.report?.badChecks.totalValue)}
         </Td>
         <Td>{consultaJson?.report?.badChecks.newestOccurrenceDate}</Td>
       </tr>
     </Table>
     <Table>
       <tr>
         <Th style={{ color: "#255391", background: "#dee5eb" }}>
           Detalhes do Documento
         </Th>
         <Th style={{ color: "#255391", background: "#dee5eb" }}>
           Situação do CPF/CNPJ em{" "}
           {consultaJson?.report?.confirmei.documentStatusDate} :
           {consultaJson?.report?.confirmei.documentStatus}
         </Th>
       </tr>
     </Table>
     <br />
   </div>
   
)}

export default TotalOcorrencias