import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CInputMask, CTextField } from "../../../../components";
import {
  cadastrar_features,
  listar_features,
} from "../../../../store/actions/clientes";

function SelectFeatures({ planoId }) {
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.clientes);

  useEffect(() => {
    dispatch(listar_features(planoId.tipo_consulta_id, planoId.id));
  }, []);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const initialStep = () => {
    setStep(step - 3);
  };

  const [db, setDb] = useState({
    feature_id: "",
    valor: "",
    consulta_id: planoId.id,
  });
  const [dadosFeatures, setDadosFeatures] = useState();



  function SelectedFeat(data) {
    setDb({ ...db, feature_id: data.id });
    setDadosFeatures(data);
    nextStep();
  }

  async function AddFeature() {
    await cadastrar_features({ valor_feature: [db] });
    try {
      setStep(1);
      setDb({ ...db, feature_id: "", valor: "", consulta_id: planoId.id });
      dispatch(listar_features(planoId.tipo_consulta_id, planoId.id));
    } catch (error) {
      return console.log(error);
    }
  }

  switch (step) {
    case 1:
      return (
        <>
          <div>
            Selecione as features que deseja adicionar ao cliente
            <Divider style={{ margin: "1em 0 1em 0" }} />
            {features.length > 0 ? (
              <>
                {features.map((item, idx) => {
                  return (
                    <div key={item.id}>
                      <button
                        onClick={() => SelectedFeat(item)}
                        style={{
                          width: "100%",
                          padding: "0.2em",
                          marginBottom: "0.2em",
                          cursor: "pointer",
                        }}
                      >
                        {item.descricao}
                      </button>
                    </div>
                  );
                })}
              </>
            ) : (
              <h5 style={{ textAlign: "center" }}>
                <br />
                Todas as features dessa categoria já foram cadastradas
              </h5>
            )}
          </div>
        </>
      );
    case 2:
      return (
        <div>
          Defina o valor da feature: <b>{dadosFeatures.descricao}</b>
          <Divider style={{ margin: "1em 0 1em 0" }} />
          <CInputMask
            type="cash"
            label={dadosFeatures.descricao}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="dense"
            value={db.valor}
            onValueChange={(v) => setDb({ ...db, valor: v.floatValue || 0 })}
          />
          <button
            onClick={() => AddFeature()}
            style={{
              width: "100%",
              marginTop: "1em",
              padding: "1em",
              border: "1px solid #173f74",
              color: "#173f74",
              cursor: "pointer",
              backgroundColor: "transparent",
              borderRadius: "4px",
            }}
          >
            {" "}
            Adicionar
          </button>
        </div>
      );
    default:
      return "";
  }
}

export default SelectFeatures;
