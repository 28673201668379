import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';


function PieChartComponent({value}) {
  const onPieEnter = () => {
  };


  return (
    <PieChart width={200} height={200} onMouseEnter={onPieEnter}>
      <Pie
        data={value}
        cx={90}
        cy={100}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={value.length === 1 ? 0 : 2}
        dataKey="value"
      >
        {value.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie> 
    </PieChart>
  );
}

export default PieChartComponent;