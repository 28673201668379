import React from "react";
import NumberFormat from "react-number-format";

function NumberMaskedInput({ onChange, value, customInput, ...props }) {
  return (
    <NumberFormat
      customInput={customInput}
      allowEmptyFormatting={true}
      allowLeadingZeros={true}
      fixedDecimalScale={true}
      displayType={"input"}
      decimalScale={props.decimalScale}
      decimalSeparator={props.separator || "."}
      onChange={onChange}
      value={value}
      margin="dense"
      label={props.label}
      required={props.required}
      disabled={props.disabled}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}

export default NumberMaskedInput;