import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

  Btn:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  
    zIndex: '999',

    '& Button':{
      color: '#fff',
      right: '2em',
     
      '& h1':{
        fontSize: '14px',
        
        marginTop: '1em'
      },
    },
  },
  grow:{
    width: '250px',
    height: '100%',
    zIndex: '',
    '& h1':{
      color: "#1D4F91",
      fontSize: '15px',
      padding: '5px 15px',
      marginTop: '-0.1em',
    },
    '& h2':{
      color: "#585859",
      fontSize: '12px',
      padding: '5px 15px',
      marginTop: '-1.5em',
      borderBottom: '1px solid #f1f1f1'
    },
    '& a':{
      textDecoration: 'none',
      color: '#010100',
      '&:hover':{
        color: "#1d4f91",
        textDecoration: 'none',
      }
    }
  },
  saldo:{
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '1em',
    '& h1':{
      fontSize: '14px',
    },
  }
}));

export default useStyles;