import { CircularProgress, TextField, Tooltip } from "@material-ui/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BsGenderAmbiguous, BsPersonCircle } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import { ImWoman } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { getTokenValida, retornoConsultaEfetuada } from "../../store/actions/validaDoc";
import { Box, Home } from "./styles";

function ValidaDocPage() {
  const [searchDoc, setSearchDoc] = useState("");
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.validaDoc)
  const [tokenConnect, setTokenConnect] = useState();
  const [resultaConsulta, setResultadoConsulta] = useState([]);
  const [idadePerson, setIdadePerson] = useState("");
  const [loadingConsulta, setLoadingConsulta] = useState(false);


  useEffect(() => {
    { !token && dispatch(getTokenValida()) }
  }, [])




  async function EfetuarConsulta() {
    setLoadingConsulta(true)
    await retornoConsultaEfetuada(token, searchDoc).then((resp) => {
      setResultadoConsulta(resp);
      setLoadingConsulta(false)
    }).catch((err) => {
      setLoadingConsulta(false)
    })



  }


  function IdadePessoa(item) {
    const hoje = dayjs();
    const idade = hoje.diff(item, "year");
    return idade;
  }

  return (
    <>
      {token ? (
        <Home>
          <Box>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                label="Digite o documento"
                value={searchDoc}
                variant="outlined"
                onChange={(e) => setSearchDoc(e.target.value)}
              />
              {loadingConsulta ? (
                <div style={{ flexDirection: "row" }}>
                  Aguarde estamos buscando{" "}
                  <CircularProgress style={{ width: 15, height: 15 }} />
                </div>
              ) : (
                <button
                  onClick={() => EfetuarConsulta()}
                  style={{
                    margin: 15,
                    border: "none",
                    padding: "15px 45px",
                    borderRadius: 15,
                    background: "#1D4F91",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Efetuar consulta
                </button>
              )}
              {resultaConsulta?.map((item) => {
                return (
                  <div style={{ color: "#3a3a3a" }}>
                    <Tooltip title="Nome" style={{ cursor: 'pointer' }}>
                      <h4>
                        <BsPersonCircle /> {item.name}
                      </h4>
                    </Tooltip>
                    <Tooltip title="Sexo" style={{ cursor: 'pointer' }}>
                      <h4>
                        <BsGenderAmbiguous />{" "}
                        {item.sex === "M"
                          ? "Masculino"
                          : item.sex == "F"
                            ? "Feminino"
                            : "Não informado"}
                      </h4>
                    </Tooltip>
                    <Tooltip title="Nascimento" style={{ cursor: 'pointer' }}>                      
                      <h4>
                        <FaBirthdayCake />{" "}
                        {dayjs(item.birth).format("DD/MM/YYYY")} (
                        {IdadePessoa(item.birth)} anos)
                      </h4>
                    </Tooltip>
                
                    <Tooltip title="Nome da mãe" style={{ cursor: 'pointer' }}>
                      <h4>
                        <ImWoman /> {item.mothersName}
                      </h4>
                    </Tooltip>
                    <h5>As informações apresentadas nesta consulta são estritamente confidenciais. É proibida a divulgação dos dados, bem como a captura de tela ou fotografia das mesmas. O não cumprimento dessas restrições pode resultar em ações legais, incluindo processo e quebra de contrato. É crucial manter a confidencialidade das informações para preservar a privacidade das partes envolvidas e garantir a segurança dos dados sensíveis compartilhados. Em caso de dúvidas, é recomendado entrar em contato com o suporte</h5>
                  </div>
                );
              })}
            </div>
          </Box>
        </Home>
      ) : (
        <Home style={{ backgroundColor: '#cecece', opacity: 0.9 }}>
          <CircularProgress style={{ width: 40, height: 40 }} />
        </Home>
      )}
    </>
  );
}

export default ValidaDocPage;
