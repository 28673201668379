import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function SerasaQueries({ consultaJson }) {
  return (
    <div id="consultas-serasa">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Consultas à Serasa realizadas no mesmo documento
          </Th>
        </tr>
      </Table>
      {/* {{!-- {{consultaJson?.report?.serasaQueries.internal}} --}} */}
      {consultaJson?.report?.serasaQueries?.commercialReferences?.length > 0 ? (
        <Table>
          <tr>
            <Td>Consultas Varejo:</Td>
            <Td>
              {" "}
              <b>até 15 dias:</b>{" "}
              {
                consultaJson?.report?.serasaQueries.commercialReferences
                  ?.totalCashChecksLast15Days
              }{" "}
            </Td>
            <Td>
              <b> 16-30 dias:</b>{" "}
              {
                consultaJson?.report?.serasaQueries.commercialReferences
                  ?.totalDeferredChecksLast30Days
              }
            </Td>
            <Td>
              <b> 31-60 dias:</b>{" "}
              {
                consultaJson?.report?.serasaQueries.commercialReferences
                  ?.totalDeferredChecks31_60Days
              }
            </Td>
            <Td>
              <b>61-90 dias:</b>{" "}
              {
                consultaJson?.report?.serasaQueries.commercialReferences
                  ?.totalDeferredChecks61_90Days
              }
            </Td>
          </tr>
        </Table>
      ) : (
        <Table>
          <tr>
            <Th>{consultaJson?.report?.serasaQueries?.message}</Th>
          </tr>
        </Table>
      )}
      <br />
      <Table>
        <tr>
          <Th style={{ background: "#faf6d3" }}>
            Simples consulta ao CNPJ ({consultaJson?.meta?.document}) no
            cadastro da Serasa. Essa informação de consulta não significa
            negócio realizado, nem se confunde com anotação negativa no cadastro
            de inadimplentes.
          </Th>
        </tr>
      </Table> 
      <br />
    </div>
  );
}

export default SerasaQueries;
