import React, { useState } from "react";
import useStyles from "./styles";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Button, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  AssignmentInd,
  AssignmentIndOutlined,
  Home as HomeIcon,
} from "@material-ui/icons";

export default function ValidaDocumento() {
  const classes = useStyles();


  return (
    <div className={classes.Btn}>
      <Link to="/app/valida-doc" style={{ textDecoration: "none" }}>
        <Button>
          <AssignmentInd /> <h1>Valida Doc</h1>         
        </Button>
      </Link>
    </div>
  );
}
