import BudgetPieChart from "../scoreComponents/budgetPieChart";
import styled from "@emotion/styled";

export function SerasaScoreAvancadoPf({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
    margin-left: 15px;
    border-radius: 7px;
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

const dataValue = [
  { value: 100, label: '0', color: '#d4e5fc' },
  { value: 100, label: '100', color: '#d4e5fc' },
  { value: 100, label: '200', color: '#bcd9ff' },
  { value: 100, label: '300', color: '#a4c9f9' },
  { value: 100, label: '400', color: '#86b8f9' },
  { value: 100, label: '500', color: '#70acf9' },
  { value: 100, label: '600', color: '#559efc' },
  { value: 100, label: '700', color: '#378cfc' },
  { value: 100, label: '800', color: '#207ffc' },
  { value: 100, label: '900', color: '#0771fc' },
  { value: 100, label: '1000',  color: '#006eff' },
];

const calcPorcentage = data?.report?.scoring?.nonPaymentProbability?.toString().replace(",", ".")

console.log(100 - calcPorcentage)


  return (
    <>
      {data.report?.scoring && (
        <div
          style={{
            width: "80%",
            margin: "auto",
            border: "1px solid #afacac",
          }}
        >
          <div style={{ borderBottom: "1px solid #AFACAC" }}>
            <b style={{ padding: 15 }}>Serasa Score com Positivo</b>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: 250, padding: 15, borderRadius: 15 }}>
              <div>
                <BudgetPieChart
                  value={
                    data.report.scoring.score ? data.report.scoring.score : 0
                  }
                  data={dataValue}
                />
                <div style={{ textAlign: "center", fontSize: 18 }}>
                  <b style={{ fontSize: 14 }}>Score</b> <br />{" "}
                  {data.report.scoring.score}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  border: "1px solid gray",
                  width: 200,
                  height: 100,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 7,
                }}
              >
                  {data.report.scoring.nonPaymentProbability ? (
                  <> {100 - calcPorcentage}%</>
                ) : (
                  "Não calculado"
                )}
                <br />
                Chance de pagamento
              </div>
              <div
                style={{
                  border: "1px solid gray",
                  marginLeft: 15,
                  padding: "0px 10px",
                  height: 100,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 7,
                }}
              >
                A chance de um consumidor, com o score entre{" "}
                {data.report.scoring.score && (
                  <>
                    {data.report.scoring.score > 0 &&
                    data.report.scoring.score < 100
                      ? "1 e 100"
                      : data.report.scoring.score > 101 &&
                        data.report.scoring.score < 200
                      ? "101 e 200"
                      : data.report.scoring.score > 201 &&
                        data.report.scoring.score < 300
                      ? "201 e 300"
                      : data.report.scoring.score > 301 &&
                        data.report.scoring.score < 400
                      ? "301 e 400"
                      : data.report.scoring.score > 401 &&
                        data.report.scoring.score < 500
                      ? "401 e 500"
                      : data.report.scoring.score > 501 &&
                        data.report.scoring.score < 600
                      ? "501 e 600"
                      : data.report.scoring.score > 601 &&
                        data.report.scoring.score < 700
                      ? "601 e 700"
                      : data.report.scoring.score > 701 &&
                        data.report.scoring.score < 800
                      ? "701 e 800"
                      : data.report.scoring.score > 801 &&
                        data.report.scoring.score < 900
                      ? "801 e 900"
                      : "901 e 1000"}
                  </>
                )}
                , pagar seus compromissos financeiros nos próximos é de{" "}
                {data.report.scoring.nonPaymentProbability ? (
                  <>{100 - calcPorcentage}%</>
                ) : (
                  "Não calculado"
                )}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #AFACAC", fontSize: 13 }}>
            A decisão da aprovação ou não do crédito é de exclusiva
            responsabilidade do concedente. As informações prestadas pela Serasa
            Experian têm como objetivo subsidiar essas decisões e, em hipótese
            alguma, devem ser utilizadas como justificativa, pelo concedente do
            crédito, para a tomada da referida decisão
          </div>
        </div>
      )}
      <br />
    </>
  );
}
