
import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";


function ResumoPendenciasFinanceiras({consultaJson}){
  return(
    <div id="totalOcorrencias">
            <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> <h3> Anotações Negativas</h3></Th>
        </tr>
      </Table>
            <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> Resumo</Th>
        </tr>
      </Table>
    <Table>
      <tr>
        <Th>Ocorrencias </Th>
        <Th>Quantidade </Th>
        <Th>Período </Th>
        <Th>Valor </Th>
        <Th>Mais Recente </Th>
      </tr>
 
      <tr>
        <Th>Pendências Comerciais (PEFIN) </Th>
        <Td> {consultaJson?.report?.paymentPendencies?.market?.totalOccurrences ? consultaJson?.report.paymentPendencies?.market?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.market?.oldestOccurrenceDate ? <>{consultaJson?.report.paymentPendencies?.market?.oldestOccurrenceDate} a {consultaJson?.report.paymentPendencies?.market?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.market?.totalValue ? toCurrency(consultaJson?.report.paymentPendencies?.market?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.market?.newestOccurrenceCompany ? consultaJson?.report.paymentPendencies?.market?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Pendências Bancarias (REFIN) </Th>
        <Td> {consultaJson?.report?.paymentPendencies?.refin?.totalOccurrences ? consultaJson?.report.paymentPendencies?.refin?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.refin?.oldestOccurrenceDate ? <>{consultaJson?.report.paymentPendencies?.refin?.oldestOccurrenceDate} a {consultaJson?.report.paymentPendencies?.refin?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.refin?.totalValue ? toCurrency(consultaJson?.report.paymentPendencies?.refin?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.paymentPendencies?.refin?.newestOccurrenceCompany ? consultaJson?.report.paymentPendencies?.refin?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Cheques sem fundos</Th>
        <Td> {consultaJson?.report?.badChecks?.totalOccurrences ? consultaJson?.report.badChecks?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.badChecks?.oldestOccurrenceDate ? <>{consultaJson?.report.badChecks?.oldestOccurrenceDate} a {consultaJson?.report.badChecks?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.badChecks?.totalValue ? toCurrency(consultaJson?.report.badChecks?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.badChecks?.newestOccurrenceCompany ? consultaJson?.report.badChecks?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Protestos </Th>
        <Td> {consultaJson?.report?.protests?.totalOccurrences ? consultaJson?.report.protests?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.protests?.oldestOccurrenceDate ? <>{consultaJson?.report.protests?.oldestOccurrenceDate} a {consultaJson?.report.protests?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.protests?.totalValue ? toCurrency(consultaJson?.report.protests?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.protests?.newestOccurrenceCity ? consultaJson?.report.protests?.newestOccurrenceCity : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Ações Judiciais </Th>
        <Td> {consultaJson?.report?.judicialActions?.totalOccurrences ? consultaJson?.report.judicialActions?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.judicialActions?.oldestOccurrenceDate ? <>{consultaJson?.report.judicialActions?.oldestOccurrenceDate} a {consultaJson?.report.judicialActions?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.judicialActions?.totalValue ? toCurrency(consultaJson?.report.judicialActions?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.judicialActions?.newestOccurrenceCompany ? consultaJson?.report.judicialActions?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Participação em Falências </Th>
        <Td> {consultaJson?.report?.bankruptyParticipations?.totalOccurrences ? consultaJson?.report.bankruptyParticipations?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.bankruptyParticipations?.oldestOccurrenceDate ? <>{consultaJson?.report.bankruptyParticipations?.oldestOccurrenceDate} a {consultaJson?.report.bankruptyParticipations?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.bankruptyParticipations?.totalValue ? toCurrency(consultaJson?.report.bankruptyParticipations?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.bankruptyParticipations?.newestOccurrenceCompany ? consultaJson?.report.bankruptyParticipations?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
 
      <tr>
        <Th>Dívidas Vencidas </Th>
        <Td> {consultaJson?.report?.overdueDebt?.totalOccurrences ? consultaJson?.report.overdueDebt?.totalOccurrences : 'nada consta'}</Td>
        <Td> {consultaJson?.report?.overdueDebt?.oldestOccurrenceDate ? <>{consultaJson?.report.overdueDebt?.oldestOccurrenceDate} a {consultaJson?.report.overdueDebt?.newestOccurrenceDate} </> : '-'}</Td>
        <Td> {consultaJson?.report?.overdueDebt?.totalValue ? toCurrency(consultaJson?.report.overdueDebt?.totalValue) : '-'}</Td>
        <Td> {consultaJson?.report?.overdueDebt?.newestOccurrenceCompany ? consultaJson?.report.overdueDebt?.newestOccurrenceCompany : '-'}</Td>             
      </tr>
    </Table>



    <br />
  </div>
  )
}

export default ResumoPendenciasFinanceiras