import { Box, Container, Table, Th, Tr } from "./styles";

function BusinessReferences({ data }) {
  return (
    <>
      {data?.businessReferences && (
        <Container style={{ marginTop: 15 }}>
          <Box>
            <h4>Referencias de Negócios (Valores em reais)</h4>
            <Table>
              <Tr>
                <Th>Mercado</Th>
              </Tr>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {data?.businessReferences.map((item) => {
                  return (
                    <div style={{margin: 15, lineHeight: 2}}>
                      <div>{item.businessDescription}</div>
                      <div>{item.potentialDate}</div>
                      <div>
                        <b
                          style={{
                            background: "gray",
                            borderRadius: 7,
                            padding: 5,
                            color: "white",
                          }}
                        >
                          {" "}
                          {item.potentialRangeCode}
                        </b>{" "}
                        {item.potentialRangeDescription}
                      </div>
                      <div>
                        <b
                          style={{
                            background: "gray",
                            borderRadius: 7,
                            padding: 5,
                            color: "white",
                          }}
                        >
                          {item.avgPotentialRangeCode}
                        </b>{" "}
                        {item.avgPotentialRangeDescription}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Table>
          </Box>
        </Container>
      )}
    </>
  );
}

export default BusinessReferences;
