import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import useStyles from "./styles";
import { Input, InputAdornment, FormControl } from "@material-ui/core";
import logoConsultas from "./../../../../assets/images/logo_colorida.png";
import Swal from "sweetalert2";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import api from "./../../../../services/api";

function ForgetPassword() {
  const [dados, setDados] = useState("");
  const navigate = useNavigate();
  function handleChange(event) {
    setDados(event.target.value);
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let email = dados;
    try {
      let res = await api
        .post(`/api/v1/users/reset-password/${email}`)
        .then(function (result) {
          if (true) {
            Toast.fire({
              icon: "success",
              title: "Link enviado com sucesso para o email cadastrado!",
            });
            navigate("/", { replace: true });
          }
        });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Email não cadastrado!",
      });
      navigate("/", { replace: true });
    }
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.forget}>
        <div className={classes.container}>
          <div className={classes.forgetContainer}>
            <form onSubmit={handleSubmit}>
              <img src={logoConsultas} />
              <h2>Digite seu email para envio do link</h2>
              <br />
              <FormControl>
                <Input
                  type="email"
                  value={dados}
                  onChange={handleChange}
                  style={{ width: "32ch" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <button type="submit">enviar</button>
            </form>
            <br />
            <br />
            <Link
              to="/login"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
                color: "#5e5353",
              }}
            >
              <KeyboardBackspaceIcon />
              login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
