import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

import { FiAlertTriangle } from 'react-icons/fi'

function ParticipacaoEmpresas({ consultaJson }) {



  return (
    <>
      <>
        <Table>
          <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
            <Th>
              <h3> Participação Societária</h3>
            </Th>
          </tr>
        </Table>
        <Table>
          <tr style={{ backgroundColor: "#faf6d4", color: "#666263" }}>
            <Th>
              <h3> Legenda: <FiAlertTriangle color="#ce5712" size={16}/> Participantes que possuem anotações negativas. </h3>
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.partnerParticipations?.map((item, index) => {
          return (
            <>
              <Table id={index.document}>
                <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
                  <Th>Empresa</Th>
                  <Th>CNPJ</Th>
                  <Th>Participação (%)</Th>
                  <Th>UF</Th>
                </tr>
                <tr>
                  <Td>{item?.withRestriction && <FiAlertTriangle color="#ce5712" size={16}/>}  {item?.companyName}</Td>
                  <Td>{item?.document}</Td>
                  <Td>{item?.percentage}</Td>
                  <Td>{item?.state}</Td>
                </tr>
              </Table>
              <Table>
                <tr>
                  <Td style={{width: "30%"}}>{item?.companyStatus} </Td>
                  <Td style={{width: "30%"}}>Desde: {item?.dateInitiatedParticipation}</Td>
                  <Td>Última Atualização: {item?.lastUpdate}</Td>
                </tr>
              </Table>
              <br/>
            </>
          );
        })}

        <br />
      </>
    </>
  );
}

export default ParticipacaoEmpresas;
