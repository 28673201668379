import * as types from "../types/extrato";
import queryString from "query-string";
import http from "./../../services/api";


export const resumo_extrato_usuario = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.EXTRATO_LOADING, payload: true });

    http.get(`/api/v1/extrato/resumo?${qry}`).then(
      (response) => {
        const  data  = response.data;  
        dispatch([
          { type: types.EXTRATO_RESUMO_USUARIO, payload: data },
        ]);
      },
      (error) => {
        dispatch({ type: types.EXTRATO_LOADING, payload: false });
      }
    );
  };
};

export const resumo_extrato_usuario_mes_anterior = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.EXTRATO_LOADING, payload: true });

    http.get(`/api/v1/extrato/resumo?${qry}`).then(
      (response) => {
        const  data  = response.data;  
        dispatch([
          { type: types.EXTRATO_RESUMO_USUARIO_MES_ANTERIOR, payload: data },
        ]);
      },
      (error) => {
        dispatch({ type: types.EXTRATO_LOADING, payload: false });
      }
    );
  };
};


export const resumo_administrador = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.EXTRATO_LOADING, payload: true });

    http.get(`/api/v1/extrato/listar?${qry}`).then(
      (response) => {
        const  data  = response.data;  
        dispatch([
          { type: types.EXTRATO_RESUMO_ADMINISTRADOR, payload: data },
        ]);
      },
      (error) => {
        dispatch({ type: types.EXTRATO_LOADING, payload: false });
      }
    );
  };
};


export const relatorioFatura = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.EXTRATO_LOADING, payload: true });

    http.get(`/api/v1/financeiro?${qry}`).then(
      (response) => {
        const  data  = response.data;  

        console.log(data)
        dispatch([
          { type: types.EXTRATO_FATURA_DETALHADA, payload: data.data },
          { type: types.EXTRATO_FATURA_ASSINATURA, payload: +data.assinatura },
        ]);
      },
      (error) => {
        dispatch({ type: types.EXTRATO_LOADING, payload: false });
      }
    );
  };
};