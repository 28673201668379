import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function ResumoConsulta({ consultaJson }) {
  return (
    <div id="resumoConsulta">
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> Identificação</Th>
        </tr>
      </Table>
      <Table>
        <tr>
          <Th>NOME</Th>
          <Th>CPF</Th>
          <Th>DATA NASCIMENTO</Th>
          <Th>NOME DA MAE</Th>
        </tr>
        <tr>
          <Td> {consultaJson?.report?.identification?.name} </Td>
          <Td> {consultaJson?.report?.identification?.document} </Td>
          <Td> {consultaJson?.report?.identification?.birthDate !== "Invalid date" ? consultaJson?.report?.identification?.birthDate : '-'} </Td>
          <Td>
            {" "}
            {
              consultaJson?.report?.syntheticRegistration?.document?.mothersName
            }{" "}
          </Td>
        </tr>
      </Table>
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> Status do Documento</Th>
        </tr>
      </Table>
      <Table>
        <tr>
          <Td>
            {" "}
            Situação do CPF em {consultaJson?.report?.identification.lastUpdate !== "Invalid date" ? consultaJson?.report?.identification.lastUpdate :  '-'}{" "}
            : {consultaJson?.report?.identification.statusDescription}{" "}
          </Td>
        </tr>
      </Table>

      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> Endereço e Telefone</Th>
        </tr>
      </Table>
      <Table>
        <tr>
          <Th>Endereço</Th>
          <Th>Bairro</Th>
          <Th>Cidade</Th>
          <Th>UF</Th>
          <Th>CEP</Th>
        </tr>
        <tr>
          <Td>
            {consultaJson?.report?.syntheticRegistration?.address?.street},
            {consultaJson?.report?.syntheticRegistration?.address?.number}
          </Td>
          <Td>
            {consultaJson?.report?.syntheticRegistration?.address?.district}
          </Td>
          <Td>{consultaJson?.report?.syntheticRegistration?.address?.city}</Td>
          <Td>{consultaJson?.report?.syntheticRegistration?.address?.state}</Td>
          <Td>{consultaJson?.report?.syntheticRegistration?.address?.cep}</Td>
        </tr>
      </Table>
      <Table>
        <tr>
          <Th>Telefone residencial</Th>
          <Th>Telefone comercial</Th>
          <Th>Ramal</Th>
          <Th>Celular</Th>
        </tr>
        <tr>
          <Td>
            {" "}
            ({" "}
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.residentialDDD
            }{" "}
            )
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.residentialTelephone
            }{" "}
          </Td>
          <Td>
            {" "}
            ({" "}
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.commercialDDD
            }{" "}
            )
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.commercialTelephone
            }{" "}
          </Td>
          <Td>
            {" "}
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.commericalBranch
            }{" "}
          </Td>
          <Td>
            {" "}
            ({" "}
            {
              consultaJson?.report?.syntheticRegistration?.personalInfo?.cellphoneDDD
            }{" "}
            )
            {consultaJson?.report?.syntheticRegistration?.personalInfo?.cellphone}{" "}
          </Td>
        </tr>
      </Table>

      <br />
    </div>
  );
}

export default ResumoConsulta;
