import React from "react";
import NumberFormat from "react-number-format";

function CashMaskedInput({ onChange, value, customInput, prefix='R$ ', ...props }) {
  return (
    <NumberFormat
      thousandSeparator={"."}
      isNumericString
      prefix={prefix}
      decimalScale={2}
      decimalSeparator={","}
      fixedDecimalScale={true}
      customInput={customInput}
      onValueChange={(values) => {
        onChange({
          target: {
            values,
          },
        });
      }}
      value={value}
      {...props}
    />
  );
}

export default CashMaskedInput;