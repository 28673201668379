import styled from "styled-components";
import BarChartComponent from "../scoreComponents/barChartComponent";

export function SerasaQueries({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
    margin-left: 15px;
    border-radius: 7px;
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

  const LineInfo = styled.div`
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    @media print {
      font-size: 8px;
    }
  `;


  const SizeLine = styled.div`
    width: 400px;
    font-size: 14px;
    @media print {
      font-size: 8px;
      width: 60mm;
    }
  `;

  const H4 = styled.h4`
    margin: 0;
    background-color: #e9ecf3;
    color: #5b6475;
    border-bottom: 2px solid #b6bcc1;
    font-size: 16px;
    @media print {
      font-size: 10px;
    }
  `;

  function getSortedMonths(array) {
    const currentMonth = new Date().getMonth() + 1;
    const filteredArray =
      array && array.filter((item) => parseInt(item.month) !== currentMonth);
    const mesesConsultados = filteredArray.map((item) => {
      return {
        name: `${item.monthDescription}/${item.year}`,
        Quantidade: item?.queriesCompanyQuantity,
      };
    });
    return mesesConsultados;
  }

  function getCurrentMonthYearData(array) {
    const now = new Date();
    const year = now.getFullYear().toString().substr(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    return (
      array &&
      array.filter((item) => item.year === year && item.month === month)
    );
  }

  const consultasMesAtual = getCurrentMonthYearData(
    data.report?.serasaQueries?.queries
  );

  

  return (
    <div>
      {data.report?.serasaQueries && (
        <div style={{ marginTop: 20 }}>
          <b>Informações sobre Consultas </b>
          <div
            style={{
              backgroundColor: "#e9ecf3",
              padding: 15,
              borderRadius: 8,
            }}
          >
            Consultas à Serasa
            <div
              style={{
                background: "#F6F7FA",
                borderRadius: 7,
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div>
                {data.report?.serasaQueries?.queries && 
                <BarChartComponent
                  data={
                    
                    getSortedMonths(
                      data.report?.serasaQueries?.queries
                    ).reverse()
                  }
                />
              }
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    border: "1px solid gray",
                    borderRadius: 7,
                    padding: "25px 5px",
                  }}
                >
                  Quantidade de consultas mês atual <br />
                  <b style={{ fontSize: 22, color: "#26478d" }}>
                    {consultasMesAtual &&
                      consultasMesAtual?.map(
                        (item) => item?.queriesCompanyQuantity
                      )}
                  </b>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "#F6F7FA",
                borderRadius: 7,
                marginTop: 25,
              }}
            >
              <b style={{ marginLeft: 15, color: "gray" }}>
                Últimas 5 Consultas Realizadas
              </b>
              <div style={{ paddingTop: 20, width: '95%', margin: 'auto' }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: 10,
                  }}
                >
                  <LineInfo>
                    <H4>Data da Consulta</H4>
                  </LineInfo>

                  <LineInfo>
                    <H4>CNPJ do Consultante</H4>
                  </LineInfo>
                  <LineInfo>
                    <SizeLine >
                      <H4>Razão Social do Consultante</H4>
                    </SizeLine>
                  </LineInfo>
                  <LineInfo>
                    <H4>Qtd</H4>
                  </LineInfo>
                </div>
                {data.report?.serasaQueries?.lastQueries?.map((item) => {
                  return (
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <LineInfo>{item.date}</LineInfo>
                      <LineInfo>{item.document}</LineInfo>
                      <LineInfo>
                        <SizeLine >{item.name}</SizeLine>
                      </LineInfo>
                      <LineInfo>{item.quantity}</LineInfo>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
