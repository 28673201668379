
import React from 'react';
import styled from '@emotion/styled';
import logo from '../../assets/images/logo_colorida.png'
import bg from '../../assets/images/bg.png'

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: url(${bg}) center center / cover no-repeat; /* Ajuste conforme necessário */
  color: #191919; /* Cor do texto, ajuste conforme necessário */
`;

const WhiteBackground = styled.div`
  background-color: #fff; /* Cor de fundo branca, ajuste conforme necessário */
  padding: 20px; /* Adicionando espaço ao redor do logotipo e texto */
  border-radius: 10px; /* Adicionando bordas arredondadas, ajuste conforme necessário */
`;


const CompanyLogo = styled.img`
  max-width: 150px; /* Ajuste conforme necessário */
  margin-bottom: 20px;
`;

const MaintenancePage = () => {
  return (
    <MaintenanceContainer>
         <WhiteBackground>
      <CompanyLogo src={logo} alt="Logo da Empresa" />
      <h1>Site em Manutenção</h1>
      <p>Estamos trabalhando para melhorar sua experiência. Por favor, volte mais tarde.</p>
      </WhiteBackground>
    </MaintenanceContainer>
  );
}

export default MaintenancePage;
