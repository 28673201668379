



import { Box, Container, Table, Td, Th, Tr } from "./styles";

function BadChecks({ data }) {
  return (
    <>
      {data?.badChecks && (
        <Container>
          <Box>
          <h4> Cheques sem fundo - Achei - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>Quantidade</Th>
              <Th>Valor</Th>
              <Th>Numero cheque</Th>
              <Th>Digito</Th>
              <Th>Banco</Th>
              <Th>Agencia</Th>
              <Th>Quantidade Banco</Th>
              <Th>Local</Th>
            </Tr>
            {data.badChecks.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>                 
                  <Td>{item.quantity} </Td>
                  <Td>{item.value} </Td>
                  <Td>{item.checkNumber} </Td>
                  <Td>{item.checkDigit} </Td>
                  <Td>{item.bank} </Td>
                  <Td>{item.bankAgency}</Td>
                  <Td>{item.bankQuantity}</Td>
                  <Td>{item.city}/{item.state}</Td>   
                </tr>
              );
            })}
          </Table>
          </Box>
        </Container>
        )}
        </>

  );
}

export default BadChecks;
