import React from "react";

import html2pdf from "html2pdf.js";
import { useEffect, useRef } from "react";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionConsultaJson } from "../../store/actions/consultas";
import {
  BadChecks,
  Bankruptcies,
  CommercialReferences,
  FinancialPendenciesPefin,
  FinancialPendenciesRefin,
  InternalPendencies,
  JudicialActions,
  PartnerParticipations,
  ResumoConsulta,
  SerasaPositiveScore,
  SerasaQueries,
  StateProtests,
  StolenDocumentsAlerts,
  TotalOcorrencias,
  UnsuccessfulCompanyParticipations,
} from "./components";
import { ButtonSave, Container } from "./style";

function BasicoPf() {
  const { id, cnpj } = useParams();
  const dispatch = useDispatch();
  const { consultaJson, consultaLoading } = useSelector(
    (state) => state.consultas
  );

  useEffect(() => {
    dispatch(ActionConsultaJson(id, cnpj));
  }, []);

  const printableRef = useRef(null);

  const imprimirPDF = () => {
    const printableComponent = printableRef.current;

    if (printableComponent) {
      const element = printableComponent.cloneNode(true);
      const options = {
        filename: `${consultaJson?.meta?.document}.pdf`,
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a3", orientation: "portrait" },
        autotable: { startY: false },
      };

      html2pdf().from(element).set(options).save();
    }
  };

  return (
    <>
      {!consultaLoading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "90%",
            }}
          >
            <ButtonSave onClick={imprimirPDF}>
              <BsFileEarmarkPdf />
              Salvar em PDF
            </ButtonSave>
          </div>

          <div ref={printableRef}>
            <Container>
              <h3 style={{ textAlign: "center", fontFamily: "Roboto" }}>
                Relatório Básico | Pessoa Física{" "}
              </h3>

              <div style={{ width: "100%" }}>
                {/*  Resumo da Consulta */}
                <ResumoConsulta consultaJson={consultaJson} />

                {/* Total das Ocorrencias */}
                <TotalOcorrencias consultaJson={consultaJson} />

                {/* Score Positivo  */}
                <SerasaPositiveScore consultaJson={consultaJson} />

                {/* Participação em Empresas */}
                <PartnerParticipations consultaJson={consultaJson} />

                {/* Documentos Roubados */}
                <StolenDocumentsAlerts consultaJson={consultaJson} />

                {/* Pendências Internas */}
                <InternalPendencies consultaJson={consultaJson} />

                {/* Pendências Pefin  */}
                <FinancialPendenciesPefin consultaJson={consultaJson} />

                {/* Pendências Refin */}
                <FinancialPendenciesRefin consultaJson={consultaJson} />

                {/* Pendências Protesto */}
                <StateProtests consultaJson={consultaJson} />

                {/*  Cheques sem Fundos BACEN */}
                <BadChecks consultaJson={consultaJson} />

                {/*  Consultas a Serasa realizadas ao mesmo documento  */}
                <SerasaQueries consultaJson={consultaJson} />

                {/*  Referencias Comerciais */}
                <CommercialReferences consultaJson={consultaJson} />

                {/*  Features adicionais withCompleteAnnotations  (ações judiciais, falências/concordatas, participação em insucesso empresarial) */}
                {consultaJson?.report?.completeAnnotations && (
                  <>
                    <h4 style={{ color: "#255391", textAlign: "center" }}>
                      Consulta adicional | Anotações mais Completas
                    </h4>
                    <br />

                    {/*  Features Ações Judiciais */}
                    <JudicialActions consultaJson={consultaJson} />

                    {/*  Features ParticipaçãoemInsucessoEmpresarial  */}
                    <UnsuccessfulCompanyParticipations
                      consultaJson={consultaJson}
                    />

                    {/*  Features Falencias Concordatas (bankruptcies)  */}
                    <Bankruptcies consultaJson={consultaJson} />
                  </>
                )}
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  );
}

export default BasicoPf;
