import { Td, Table, Th } from "../style";
import { FiAlertTriangle } from 'react-icons/fi' 


function AdministradoresDaEmpresa({ consultaJson }) {


  return (
    <div id="partnerParticipations">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Sócios e Administradores
          </Th>
        </tr>
      </Table>
      {consultaJson?.report?.administrationMembers?.occurrences?.length > 0 ? (
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th> CPF/CNPJ </Th>
              <Th> Administração</Th>
              <Th> Cargo </Th>
            </tr>
            {consultaJson?.report?.administrationMembers?.occurrences.map(
              (item) => {
                return (
                  <tr>
                    <Td>{item?.restrictions &&  <FiAlertTriangle color="#ce5712" size={16}/>  }  {item?.document}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.role}</Td>
                  </tr>
                );
              }
            )}
          </Table>
        </>
      ) : (
        <Table>
          <tr>
            <Th>NÃO CONTAM OCORRENCIAS</Th>
          </tr>
        </Table>
      )}
      <br />
    </div>
  );
}

export default AdministradoresDaEmpresa;
