import screen from "./assets/images/screen.png";
import styled from "@emotion/styled";

export const Home = styled.div`
  width: 100vw;
  height: 100vh;
  align-items: center;
  text-align: center;
  background: "#fcf7f7";
`;

function ScreenSize() {
  return (
    <Home>
      <div style={{width: "80%", margin: 'auto'}}>
        <h4 style={{marginTop: 150}}>Atenção!</h4>
        <h3>
          Resolução inferior ao permitido. <br />
          Favor acessar esse site por um computador <br />
        </h3>
        <img src={screen} style={{width: "100%"}}/>
        <br />
      </div>
    </Home>
  );
}

export default ScreenSize;
