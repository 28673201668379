export const CONSULTAS_LOADING = 'CONSULTAS_LOADING';

export const CONSULTAS_PERMISSAO = 'CONSULTAS_PERMISSAO';
export const CONSULTAS_LOAD = 'CONSULTAS_LOAD';
export const CONSULTA_LOAD = 'CONSULTA_LOAD';
export const CONSULTAS_PAGE = 'CONSULTAS_PAGE';
export const CONSULTAS_PERPAGE = 'CONSULTAS_PERPAGE';
export const CONSULTAS_TOTAL = 'CONSULTAS_TOTAL';
export const LOAD_CONSULTA_STATUS = 'LOAD_CONSULTA_STATUS';
export const CONSULTAS_PLANOS = 'CONSULTAS_PLANOS';
export const CONSULTAS_LISTAR_PRECOS_CLIENTE = 'CONSULTAS_LISTAR_PRECOS_CLIENTE';



export const CONSULTAS_JSON_LOADING = 'CONSULTAS_JSON_LOADING';
export const SET_CONSULTAS_JSON = 'SET_CONSULTAS_JSON';
export const CONSULTA_EFETUADA_LOADING = 'CONSULTA_EFETUADA_LOADING';



