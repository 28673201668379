  
import { applyMiddleware, combineReducers, createStore } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { clientes, consultas, extrato, usuario, validaDoc } from './reducers';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
    usuario, consultas, clientes, extrato, validaDoc
});


export default applyMiddleware(thunk, multi, promise)(createStore)(reducers, process.env.NODE_ENV === 'development' ? devTools : undefined);