import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Tooltip } from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { resumo_administrador as resumo } from "./../../store/actions/extrato";
import { CDataTable, CInputSelect, CTextField } from "./../../components/index";
import PerfectScrollBar from "react-perfect-scrollbar";
import toCurrency from "./../../helpers/toCurrency";
import InputMask from "react-input-mask";
import { TbReportAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router";

function RelatorioConsumoAdm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resumo_administrador } = useSelector((state) => state.extrato);
  const [pesquisa, setPesquisa] = useState({ mes_s: "", ano_s: "", nome: "" });
  const date = new Date();
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1;

  const ano_busca = pesquisa.ano_s == "" ? ano : pesquisa.ano_s;
  const mes_busca = pesquisa.mes_s == "" ? mes : pesquisa.mes_s;

  useEffect(() => {
    dispatch(resumo({ mes: mes, ano: ano }));
  }, []);

  const limparFiltros = () => {
    dispatch(resumo({ mes: mes, ano: ano }));
    setPesquisa({ mes_s: "", ano_s: "" });
  };

  const pesquisar = () => {
    dispatch(resumo({ mes: mes_busca, ano: ano_busca }));
  };

  function formatarCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    return (
      cnpj.substr(0, 2) +
      "." +
      cnpj.substr(2, 3) +
      "." +
      cnpj.substr(5, 3) +
      "/" +
      cnpj.substr(8, 4) +
      "-" +
      cnpj.substr(12)
    );
  }

  function somarValoresPagamento(array) {
    // Inicializa a variável de soma
    let soma = 0;

    // Itera sobre cada elemento do array
    array.forEach((item) => {
      // Converte o valor de "valor_pagamento" para número e adiciona à soma
      soma += parseFloat(item.valor_pagamento);
    });

    // Retorna o resultado da soma
    return soma;
  }

  function somarQuantidadeConsultas(array) {
    let soma = 0;

    array.forEach((item) => {
      soma += parseFloat(item.qtde_consultas);
    });

    return soma;
  }

  const totalPagamento = somarValoresPagamento(resumo_administrador);

  console.log(somarQuantidadeConsultas(resumo_administrador));

  return (
    <>
      <h4 style={{ textAlign: "left" }}>Relatório de Consultas:</h4>

      <Grid container spacing={1}>
        <Grid item sm={5}>
          <CTextField
            label="Nome ou CPF/CNPJ para pesquisar"
            variant="outlined"
            value={pesquisa.nome}
            onChange={(e) => setPesquisa({ ...pesquisa, nome: e.target.value })}
          />
        </Grid>

        <Grid item sm={1} className={classes.btnBusca}>
          <button
            onClick={pesquisar}
            style={{ border: "1px solid #29d7f2", color: "#2b9ec4" }}
          >
            Pesquisa
          </button>
        </Grid>

        <Grid item sm={3}>
          <Grid container spacing={1}>
            <Grid item sm={8}>
              <CInputSelect
                name="mes"
                label="Mês"
                values={[
                  { value: "", label: "Selecione" },
                  { value: 1, label: "Janeiro" },
                  { value: 2, label: "Fevereiro" },
                  { value: 3, label: "Março" },
                  { value: 4, label: "Abril" },
                  { value: 5, label: "Maio" },
                  { value: 6, label: "Junho" },
                  { value: 7, label: "Julho" },
                  { value: 8, label: "Agosto" },
                  { value: 9, label: "Setembro" },
                  { value: 10, label: "Outubro" },
                  { value: 11, label: "Novembro" },
                  { value: 12, label: "Dezembro" },
                ]}
                value={pesquisa.mes_s}
                onChange={(e) =>
                  setPesquisa({ ...pesquisa, mes_s: e.target.value })
                }
              />
            </Grid>
            <Grid item sm={4}>
              <InputMask
                mask="9999"
                value={pesquisa.ano_s || ano}
                required
                onChange={(e) =>
                  setPesquisa({ ...pesquisa, ano_s: e.target.value })
                }
              >
                {() => (
                  <CTextField
                    required
                    label="Ano"
                    name="ano"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={3} className={classes.btnBusca}>
          <Tooltip title="Filtro">
            <button
              onClick={pesquisar}
              style={{ border: "1px solid #1f9e43", color: "#1f9e43" }}
            >
              Busca Por Período
            </button>
          </Tooltip>
          <Tooltip title="Limpar Filtro">
            <button
              onClick={limparFiltros}
              style={{
                border: "1px solid #a50e0e",
                color: "#a50e0e",
                marginLeft: "0.5em",
              }}
            >
              X
            </button>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{marginTop: 20}}>
        <Grid item sm={6} style={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
          <div style={{border: "1px solid #bfbfbf", borderRadius: 14, width: "50%"}}>
          <h5>Quantidade de consultas efetuadas</h5>
          <h4>{somarQuantidadeConsultas(resumo_administrador)}</h4>

          </div>
        </Grid>
        <Grid item sm={6} style={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
          <div style={{border: "1px solid #bfbfbf", borderRadius: 14, width: "50%"}}>
          <h5>Total Consumo</h5>
          <h4>{toCurrency(somarValoresPagamento(resumo_administrador))}</h4>
          </div>
        </Grid>
      </Grid>

      <Grid item sm={12} style={{ marginTop: "4em",  marginBottom: 50 }}>
        <PerfectScrollBar>
          <CDataTable
            columns={[
              {
                title: "Nome",
                cellStyle: { fontSize: 14, width: "25%" },
                render: (row) => {
                  return (
                    <div
                      style={{
                        maxWidth: "25ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip title={row.nome}>
                        <span>{row.nome}</span>
                      </Tooltip>
                    </div>
                  );
                },
              },
              {
                title: "CPF/CNPJ",
                cellStyle: { fontSize: 14, width: "18%" },

                render: (row) => formatarCNPJ(row.cnpj),
              },
              {
                title: "Email",
                field: "email",
                cellStyle: { fontSize: 14, width: "25%" },
              },

              {
                title: "Vencimento",
                field: "vencimento",
                cellStyle: {
                  fontSize: 14,
                  textAlign: "left",
                },
              },
              {
                title: "Quantidade",
                field: "qtde_consultas",
                cellStyle: {
                  fontSize: 14,
                  textAlign: "left",
                },
              },
              {
                title: "Consultas",
                cellStyle: { fontSize: 14, width: "10%" },
                render: (row) => toCurrency(row.valor_total),
              },
              {
                title: "Assinatura",
                cellStyle: { fontSize: 14, width: "10%" },
                render: (row) => toCurrency(row.assinatura),
              },
              {
                title: "A Receber",
                cellStyle: { fontSize: 14, width: "15%" },
                render: (row) => toCurrency(row.valor_pagamento),
              },
              {
                title: "Relatório",
                cellStyle: {
                  fontSize: 14,
                },
                render: (row) => {
                  return (
                    <Tooltip title="Relatório Detalhado">
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() =>
                          navigate(
                            `/app/consumo/${mes_busca}/${ano_busca}/${row.id}`
                          )
                        }
                      >
                        <TbReportAnalytics size={20} color="#1899ad" />
                      </button>
                    </Tooltip>
                  );
                },
              },
            ]}
            data={resumo_administrador}
            options={{
              headerStyle: {
                backgroundColor: "#F2F2F3",
                fontSize: 12,
                textAlign: "left",
                borderLeft: "solid 0.5px white",
               
              },
            }}
          />
        </PerfectScrollBar>
      </Grid>
    </>
  );
}

export default RelatorioConsumoAdm;
