import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function ConsultasRealizadasCPFnoSerasa({ consultaJson }) {
  return (
    <>
      <>
        <Table>
          <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
            <Th>
              <h3> Consultas Realizadas para o CPF na Serasa Experian</h3>
            </Th>
          </tr>
        </Table>
        <Table>
          <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
            <Th>Data</Th>
            <Th>Segmento</Th>
          </tr>
          {consultaJson?.report?.serasaQueries?.lastQueries?.map((item) => {
            return (
              <tr>
                <Td>{item.date}</Td>
                <Td>{item.origin}</Td>
              </tr>
            );
          })}
        </Table>
      </>
      <Table>
        <tr>
          <Th>
            TOTAL DE CONSULTAS CRÉDITO ={" "}
            {consultaJson?.report?.serasaQueries?.credit?.total} (
            {consultaJson?.report?.serasaQueries?.credit?.monthlyQueries.map(
              (item) => {
                return (
                  <>
                    {item.date}={item.total} &nbsp;
                  </>
                );
              }
            )}
            ){" "}
          </Th>
        </tr>
        <tr>
          <Th>
            TOTAL DE CONSULTAS CHEQUE ={" "}
            {consultaJson?.report?.serasaQueries?.checks?.total} (
            {consultaJson?.report?.serasaQueries?.checks?.monthlyQueries.map(
              (item) => {
                return (
                  <>
                    {item.date}={item.total} &nbsp;
                  </>
                );
              }
            )}
            ){" "}
          </Th>
        </tr>
        <tr style={{background: "#faf6d4"}}>
          <Th>
            Simples consulta ao CPF {consultaJson?.meta?.document} no cadastro da Serasa. Essa
            consulta não significa negócio realizado, nem se confunde
            comanotação negativa no cadastro de inadimplentes.
          </Th>
        </tr>
      </Table>
      <br />
    </>
  );
}

export default ConsultasRealizadasCPFnoSerasa;
