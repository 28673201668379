import React, { useState } from "react";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { autenticarUsuario } from "../../store/actions/usuario";
import * as types from "../../store/types/usuario";
import { useDispatch, useSelector } from "react-redux";
import icone from "../../assets/images/logo_colorida.png";
import { Home, Box, Formulario } from "./styles";
import { useEffect } from "react";
import AlertComponent from "../../components/AlertComponent";

function Login() {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const navigate = useNavigate();

  const [data, setData] = useState({ email: "", password: "" });

  const [permissaoUser, setPermissaoUser] = useState("");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  async function sendLogin(event) {
    event.preventDefault();
    try {
      await autenticarUsuario(data.email, data.password).then((resp) => {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
        if (resp.permissao.tipo === "ADMINISTRADOR_FINANCEIRO") {
          navigate("/app/consumo", { replace: true });
        } else {
          navigate("/app/home", { replace: true });
        }
      });    
    } catch (error) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <Home>
      <Box>
        <div style={{ textAlign: "center", padding: "1em" }}>
          <img src={icone} style={{ width: "100px" }} />
        </div>
        <h4 style={{ textAlign: "center" }}>Área Parceiro</h4>

        <Formulario onSubmit={sendLogin}>
          <label>
            <div>
              <TextField
                type="email"
                value={data.email}
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
                onChange={(event) =>
                  setData({
                    ...data,
                    email: event.target.value,
                  })
                }
                id="email"
                label="E-mail"
              />
            </div>
            <div>
              <TextField
                type={showPass ? "text" : "password"}
                value={data.password}
                onChange={(event) =>
                  setData({
                    ...data,
                    password: event.target.value,
                  })
                }
                id="password"
                label="Senha"
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: "Pointer", opacity: "0.7" }}
                      onClick={ShowPassword}
                    >
                      <IconButton onClick={ShowPassword}>
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </label>
          <br />
          <div>
            <Button variant="outlined" fullWidth type="submit" color="primary">
              Entrar
            </Button>
          </div>
          <br />
        </Formulario>

        <br />
      </Box>

      {showSuccessAlert && (
        <AlertComponent type="success" message="Login efetuado com sucesso!" timeout={3000} />
      )}

      {showErrorAlert && (
        <AlertComponent type="error" message="Usuário e/ou senha inválidos" timeout={3000} />
      )}
    </Home>
  );
}

export default Login;
