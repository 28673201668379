// suppliersCommitmentEvolution

import React from "react";
import styled from "styled-components";
import BarChartComponent from "./components/BarChart";

export function SuppliersCommitmentEvolution({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
    margin-left: 15px;
    border-radius: 7px;
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

  const LineInfo = styled.div`
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
  `;

  const H4 = styled.h4`
    margin: 0;
    background-color: #e9ecf3;
    color: #5b6475;
    border-bottom: 2px solid #b6bcc1;
    font-size: 16px;
  `;

  function getSortedMonths(array) {
    const currentMonth = new Date().getMonth() + 1;
    const filteredArray =
      array && array.filter((item) => parseInt(item.month) !== currentMonth);
    const mesesConsultados = filteredArray.map((item) => {
      return {
        name: `${item.monthDescription}/${item.year}`,
        key: `${item.undueRangeDescription}`,
        Quantidade: item.undueInitialValue,
      };
    });
    return mesesConsultados;
  }

  function getCurrentMonthYearData(array) {
    const now = new Date();
    const year = now.getFullYear().toString().substr(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    return (
      array &&
      array.filter((item) => item.year === year && item.month === month)
    );
  }

  const consultasMesAtual = getCurrentMonthYearData(
    data.suppliersCommitmentEvolution
  );

  console.log(data?.suppliersCommitmentEvolution);

  const dadosFormatados = data?.suppliersCommitmentEvolution?.map((item) => {
    const uv = item.undueFinalValue;
    return {
      ...item,
      uv: item.undueFinalValue,
      name: `${item.month}/${item.year}`,
    };
  });


  return (
    <div>
      {data?.suppliersCommitmentEvolution && (
        <div style={{ marginTop: 20 }}>
          <b>Evolução de compromissos(Valores em Reais) </b>

          <div
            style={{
              backgroundColor: "#e9ecf3",
              padding: 15,
              borderRadius: 8,
            }}
          >
            <div
              style={{
                background: "#F6F7FA",
                borderRadius: 7,
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div>
                {dadosFormatados && (
                  <BarChartComponent data={dadosFormatados.reverse()} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
