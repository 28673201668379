import { toCurrency } from "../../../helpers";
import grafico from "./grafico.png";
import { Box } from "./styles";

function PresumedBilling({ data }) {
  return (
    <div>
      {data?.presumedBilling && (
        <Box>
          <h4> Faturamento Estimado com Positivo</h4>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                margin: 15,
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: 15,
                border: '1px solid gray', borderRadius: 14
              }}
            >
              <img src={grafico} style={{ width: 100 }} />
              <div style={{ textAlign: "center", padding: 20 }}>
                Acima de <br />
                <b>
                  {toCurrency(
                    data?.presumedBilling?.billing?.valueInThousands * 10000
                  )}
                </b>{" "}
                <br />
                ao ano
              </div>
            </div>
            <div >
              <b>Metodologia utilizada</b> <br />
              {data?.presumedBilling?.reason?.message}
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}

export default PresumedBilling;
