import React from "react";
import useStyles from "./styles";
import ConsumoFaturaUsuario from "./consumo_fatura";
import { useDispatch, useSelector } from "react-redux";
import RelatorioConsumoAdm from "./relatorio_consumo_adm";

export default function ConsumoFinanceiro() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { permissao } = useSelector((state) => state.usuario);

  return (
    <div className={classes.consumo}>
      {permissao.tipo == "ADMINISTRADOR" || permissao.tipo == "ADMINISTRADOR_FINANCEIRO"  ?  (
        <RelatorioConsumoAdm />
      ) : (
        <>
          Consumo Financeiro Total de consultas <br />
          <ConsumoFaturaUsuario />
        </>
      )}
      {/* Relatório Consumo (<b>12 meses</b>)
      <Grafico /> */}
    </div>
  );
}
