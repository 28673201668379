import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function AcoesJudiciais({ consultaJson }) {
  return (
    <>   
      {consultaJson?.report?.judicialActions?.occurrences?.length > 0 &&
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th> <h3> Ações Judiciais </h3></Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Cartório </Th>
              <Th>Cidade </Th>
              <Th>UF </Th>
              <Th>Data. </Th>
              <Th>Valor </Th>
            </tr>
            {consultaJson?.report?.judicialActions?.occurrences?.map(
              (item) => {
                return (
                  <tr>
                    <Td> {item?.notaryOffice} </Td>
                    <Td> {item?.city} </Td>
                    <Td> {item?.state} </Td>
                    <Td> {item?.date} </Td>
                    <Td> {item?.value && toCurrency(item?.value)} </Td>
                  </tr>
                );
              }
            )}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.judicialActions?.totalOccurrences}. Periodo{" "}
                {
                  consultaJson?.report?.judicialActions?.oldestOccurrenceDate
                }{" "}
                a{" "}
                {
                  consultaJson?.report?.judicialActions?.newestOccurrenceDate
                }{" "}
                valor total das ocorrencias{" "}
                {consultaJson?.report?.judicialActions?.totalValue &&
                  toCurrency(
                    consultaJson?.report?.judicialActions?.totalValue
                  )}{" "}
              </Th>
            </tr>
          </Table>
        </>
      }
    </>
  );
}

export default AcoesJudiciais;
