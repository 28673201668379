import * as types from '../types/consultas';

const hoje = new Date();

const INITIAL_STATE = {
    loading: false,
    consultas: [{}],
    consulta: [],
    status: '',
    page: 1,
    rows: 10,
    total: 0,
    planos: [],
    listar_precos_cliente: [],
    consultaJson: [],
    consultaLoading: false,
    consultaEfetuadaLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CONSULTAS_LOADING:
            return { ...state, loading: action.payload};
        
        case types.CONSULTA_EFETUADA_LOADING:
            return { ...state, consultaEfetuadaLoading: action.payload};
        
        case types.CONSULTAS_LOAD:
            return { ...state, consultas: action.payload};

        case types.CONSULTA_LOAD:
            return { ...state, consulta: action.payload};

        case types.CONSULTAS_PAGE:
            return { ...state, page: action.payload};

        case types.CONSULTAS_PERPAGE:
            return { ...state, rows: action.payload}
            ;
        case types.CONSULTAS_TOTAL:
            return { ...state, total: action.payload};  
            
            
        case types.CONSULTAS_PLANOS:
            return { ...state, planos: action.payload};    


        case types.CONSULTAS_LISTAR_PRECOS_CLIENTE:
            return { ...state, listar_precos_cliente: action.payload};    
  
          

        case types.LOAD_CONSULTA_STATUS:
            return { ...state, status: action.payload };


        case types.SET_CONSULTAS_JSON:
            return { ...state, consultaJson: action.payload };

        case types.CONSULTAS_JSON_LOADING:
            return { ...state, consultaLoading: action.payload };
      default:
        return state;
    }
};