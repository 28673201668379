

import { Box, Container, Table, Td, Th, Tr } from "./styles";

function Bankruptcies({ data }) {
  return (
    <>
      {data?.bankruptcies && (
        <Container>
          <Box>
          <h4>Falências e concordatas - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>Tipo</Th>
              <Th>Origem</Th>
              <Th>Vara</Th>
              <Th>Local</Th>
            </Tr>
            {data.bankruptcies.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>
                  <Td>{item.type} </Td>
                  <Td>{item.origin} </Td>
                  <Td>{item.civilCourt} </Td>
                  <Td>{item.participation}</Td>
                  <Td>{item.city}/{item.state}</Td>
                </tr>
              );
            })}
          </Table>
      </Box>
    </Container>
      )}
      </>
  );
}

export default Bankruptcies;
