export function RegisterInformation({ data }) {
  return (
    <div>
      <b>Visão Geral</b> <br />
      <br />
      CNPJ: {data.meta && data.meta.document}
      <br />
      {data.report?.registerInformation && (
        <>
          {data.report?.registerInformation?.identification?.companyName} <br />
          {data.report?.registerInformation?.accounting && (
            <>
              {" "}
              Data e Hora da Consulta{" "}
              {
                data.report?.registerInformation?.accounting?.emissionDateTime
              }{" "}
            </>
          )}
        </>
      )}
    </div>
  );
}
