import styled from "@emotion/styled";
import { formatCNPJ, formatCPF } from "../../../helpers";
import dayjs from "dayjs";
import { Tooltip } from "@material-ui/core";

import { FiAlertOctagon } from 'react-icons/fi'

export function Participants({ data }) {
  const Th = styled.th`
    text-align: left;
    padding: 8px;
    font-weight: bold;
    border-bottom: 1px solid #d1d1d1;
    font-size: 14px;
  `;
  const Td = styled.td`
    text-align: left;
    padding: 8px;
    font-size: 14px;
    border-bottom: 1px solid #d1d1d1;
  `;
  const Table = styled.table`
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #d1d1d1;

    border-collapse: collapse;
    width: 95%;
    margin: auto;
    color: #1c1c1c;
  `;
  const Tr = styled.tr`
    font-weight: bold;
    background: #e9ecf3;
    border-bottom: 2px solid #b6bcc1;
  `;

  return (
    <>
      {data?.report?.participations && (
        <div style={{ marginTop: 15 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <b>Participações:</b>
            </div>
            <div>
              Informação atualizada em{" "}
              {dayjs(data?.report?.participations?.lastUpdate).format(
                "DD/MM/YYYY"
              )}
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            {data.report?.participations?.companies?.map((item, index) => {
              return (
                <div key={index.document}>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 40,
                    }}
                  >
                    <div>CNPJ: {item.document}</div>
                    <div>
                      {item.restriction && (
                        <>
                          <Tooltip title="Empresa possui restrições">
                            <span><FiAlertOctagon color="#ff8c00" size={20}/></span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <div>Razão Social: {item?.companyName}</div>
                    <div>
                      Cidade/UF:{" "}
                      {item?.participants[0]?.city
                        ? item?.participants[0]?.city
                        : "Não Informado"}
                    </div>
                  </div>
                  <Table
                    style={{ width: "100%", marginBottom: 25, marginTop: 15 }}
                  >
                    <Tr>
                      <Th>CPF/CNPJ</Th>
                      <Th>Nome</Th>
                      <Th>Vínculo</Th>
                      <Th>Capital</Th>
                      <Th>Anotações</Th>
                    </Tr>

                    {item?.participants?.map((participant) => {
                      return (
                        <tr>
                          <Td>{participant.document}</Td>
                          <Td>{participant.name}</Td>
                          <Td>{participant.role}</Td>
                          <Td>
                            {participant.percentage
                              ? participant.percentage + "%"
                              : "0 %"}
                          </Td>
                          <Td>{participant.restriction ? "Sim" : "Não"}</Td>
                        </tr>
                      );
                    })}
                  </Table>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
