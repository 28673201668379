  
import axios from 'axios';

const baseUrl = () => {
  if (process.env.NODE_ENV !== 'development') {    
    return 'https://consulta.fynd.com.br'
    // return 'http://localhost:80'
  } else {
    return 'https://consulta.fynd.com.br'
    // return 'http://localhost:80'

  }
}


const http = axios.create({ baseURL: baseUrl(), timeout: 200000});

export default http;