import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function InformacoesComplementaresComplementares({ data }) {
  return (
    <>
      {data?.report?.syntheticRegistration?.personalInfo && (
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>
                {" "}
                <h3 style={{ margin: 0 }}>
                  Informações Cadastrais Complementares{" "}
                </h3>
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>Mais Recente </Th>
              <Th>
                {" "}
                Data da última alteração cadastral:{" "}
                {data?.report?.syntheticRegistration?.document.lastUpdate}{" "}
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>Indentificação </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>
                Nome: |{" "}
                {data?.report?.identification?.name
                  ? data?.report?.identification?.name
                  : "-"}{" "}
              </Th>
              <Th>
                CPF: |{" "}
                {data?.report?.identification?.document
                  ? data?.report?.identification?.document
                  : "-"}
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>
                RG:{" "}
                {data?.report?.syntheticRegistration?.document.document
                  ? data?.report?.syntheticRegistration?.document.document
                  : "-"}{" "}
              </Th>
              <Th>
                ORGAO:{" "}
                {data?.report?.syntheticRegistration?.document
                  .documentIssuerOrgan
                  ? data?.report?.syntheticRegistration?.document
                      .documentIssuerOrgan
                  : "-"}
              </Th>
              <Th>
                UF:{" "}
                {data?.report?.syntheticRegistration?.document
                  .documentIssuerState
                  ? data?.report?.syntheticRegistration?.document
                      .documentIssuerState
                  : "-"}
              </Th>
            </tr>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>
                Data de Emissão:{" "}
                {data?.report?.syntheticRegistration?.document
                  .documentIssuerDate &&
                data?.report?.syntheticRegistration?.document
                  .documentIssuerDate == "Invalid Date"
                  ? data?.report?.syntheticRegistration?.document
                      .documentIssuerDate
                  : "-"}{" "}
              </Th>
              <Th>
                Cidade Nasc:{" "}
                {data?.report?.syntheticRegistration?.personalInfo.birthCity
                  ? data?.report?.syntheticRegistration?.personalInfo.birthCity
                  : "-"}
              </Th>
              <Th>
                UF Nasc:{" "}
                {data?.report?.syntheticRegistration?.personalInfo.birthState
                  ? data?.report?.syntheticRegistration?.personalInfo.birthState
                  : "-"}
              </Th>
            </tr>
            <tr style={{ backgroundColor: "#ffffff", color: "#666263" }}>
              <Th>
                Estado Civil:{" "}
                {data?.report?.syntheticRegistration?.personalInfo
                  ?.maritialStatus
                  ? data?.report?.syntheticRegistration?.personalInfo
                      ?.maritialStatus
                  : "-"}{" "}
              </Th>
              <Th>
                Dependentes:{" "}
                {data?.report?.syntheticRegistration?.personalInfo?.dependentes
                  ? data?.report?.syntheticRegistration?.personalInfo
                      ?.dependentes
                  : "-"}
              </Th>
              <Th>
                Escolaridade:{" "}
                {data?.report?.syntheticRegistration?.personalInfo?.schooling
                  ? data?.report?.syntheticRegistration?.personalInfo?.schooling
                  : "-"}
              </Th>
            </tr>
          </Table>
          <Table>
            "As informações acima, de uso exclusivo do destinatário, são
            protegidas por sigilo contratual. Sua utilização por outra pessoa,
            ou para finalidade diversa da contratação, caracteriza ilicito
            civil.
          </Table>
          <br />
        </>
      )}
    </>
  );
}

export default InformacoesComplementaresComplementares;
