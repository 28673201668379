import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useStyles from "./styles";
import api from "../../../services/api";
import { Button, Divider, Grid, Tooltip } from "@material-ui/core";
import { Edit as EditIcon, Group as GroupIcon, SettingsOutlined, ArrowBack, Clear  } from "@material-ui/icons";
import {CPaginate, CDataTable } from "./../../../components/index";
import PerfectScrollBar from "react-perfect-scrollbar";
import CadastrarUsuario from "./components/cadastrar_usuario";
import { listar_usuario as listar, excluir_usuario} from "../../../store/actions/usuario";
import { useDispatch, useSelector } from 'react-redux';
import { listarConfiguracoes } from "../../../store/actions/clientes";

function ClientesVinculados() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch()
  const [user, setUser] = useState({});
  const [ openCadastro, setOpenCadastro] = useState(false)
  const { listar_usuario } = useSelector((state) => state.usuario)



  useEffect(() => {
    dispatch(listar({ page: 1,  per_page: 10}, id));
    dispatch(listarConfiguracoes());

  }, []);

  async function excluirUsuario(id) {
    try {
      await excluir_usuario(id);
      dispatch(listar({ page: 1,  per_page: 10}));
     
    } catch (error) {
      console.log('erro')
    }
  }


  return (
    <div className={classes.cvinculados}>
      <div className={classes.cvinculadosContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <h4>Cliente: {user.nome}</h4>
          </Grid>
          <Grid item xs={12} sm={4}>
              <div className={classes.voltarbtn}>
            <Link to="/app/clientes" style={{ textDecoration: "none" }}>
                <button style={{border: '1px solid #119e92', color: '#119e92'}}>
                  Clientes
                </button>
            </Link>
                <button onClick={() => setOpenCadastro(true)} style={{border: '1px solid #f44004', color: '#f44004',}}>                   
                  Adicionar Usuario
                  </button>
              </div>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <br/>
            <PerfectScrollBar>
              <CDataTable
                columns={[
                  { title: "Nome", field: "nome", cellStyle: { width: "30%", paddingLeft: "0.5em" }, },
                  { title: "Email", field: "email",  },
                  { title: "Situação",    cellStyle:{ textAlign: 'center'}, render: (row) => row.situacao == 'A' ? 'Ativo' : row.situacao == 'I' ? 'Inativo' : 'Cancelado' },          
                  { title: "Ações", cellStyle: { width: "10%", paddingLeft: "0.5em" }, render: (row) => 
                  <div style={{display:"flex"}} className={classes.actions}>
                    <Tooltip title="Excluir">
                      <Button onClick={() => excluirUsuario(row.id)}>
                        <Clear style={{color: '#590909', fontSize: '18px'}}/>
                      </Button>
                    </Tooltip>                  
                    
             
                  </div>}    
                ]}
                data={listar_usuario}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                }}
              />
            </PerfectScrollBar>            
          </Grid>
        </Grid>
        <CadastrarUsuario 
          open={openCadastro}
          close={() => setOpenCadastro()}
          id={id}
          update={() =>  dispatch(listar({ page: 1,  per_page: 10}))}
        />
      </div>
    </div>
  );
}

export default ClientesVinculados;
