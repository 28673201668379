import * as types from "../types/clientes";
import queryString from "query-string";
import http from "./../../services/api";
import store from "../index";

export const cadastrar_clientes = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.post(`/api/v1/clientes`, dados).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject();
      }
    );
  });
};

export const listar_clientes = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.CLIENTES_LOADING, payload: true });

    http.get(`/api/v1/clientes?${qry}`).then(
      (response) => {
        const { data } = response.data;
        const {
          total,
          currentPage: page,
          perPage: per_page,
        } = response.data.pagination;
        dispatch([
          { type: types.CLIENTES_LOAD, payload: data },
          { type: types.CLIENTES_PAGE, payload: page },
          { type: types.CLIENTES_PERPAGE, payload: per_page },
          { type: types.CLIENTES_TOTAL, payload: total },
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CLIENTES_LOADING, payload: false });
      }
    );
  };
};

export function listar_cliente(id) {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_LOADING, payload: true });

    http.get(`/api/v1/clientes/${id}`).then(
      (response) => {
        dispatch([{ type: types.CLIENTE_SET, payload: response.data }]);
      },
      (error) => {
        dispatch({ type: types.CLIENTES_LOADING, payload: false });
      }
    );
  };
}

export const editar_cliente = async (dados, id) => {
  dados.created_at = undefined
  dados.updated_at = undefined
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.put(`/api/v1/clientes/${id}`, dados).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject();
      }
    );
  });
};


export function listar_planos(id) {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_LOADING, payload: true });

    http.get(`/api/v1/clientes/planos/${id}`).then(
      (response) => {
        dispatch([{ type: types.CLIENTE_PLANOS, payload: response.data }]);
      },
      (error) => {
        dispatch({ type: types.CLIENTES_LOADING, payload: false });
      }
    );
  };
}



export const cadastrar_features = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.post(`/api/v1/clientes/features`, dados).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject();
      }
    );
  });
};

export const listar_features = (tipo_consulta_id , valor_consulta_id) => {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_LOADING, payload: true });

    http.get(`/api/v1/clientes/features/${tipo_consulta_id}/${valor_consulta_id}`).then(
      (response) => {
        const  data  = response.data;
        dispatch([
          { type: types.CLIENTES_FEATURES, payload: data },
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CLIENTES_LOADING, payload: false });
      }
    );
  };
};



export const editar_features = async (id , valor) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.put(`/api/v1/clientes/features/edit/${id}`, {valor: valor}).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject();
      }
    );
  });
};


export const editar_planos = async (id , valor) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.put(`/api/v1/clientes/planos/edit/${id}`, {valor: valor}).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject();
      }
    );
  });
};



export const listarConfiguracoes = ( ) => {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.get(`/api/v1/configuracoes`,).then(
      (response) => {       
        dispatch([
          { type: types.CLIENTES_CONFIGURACOES, payload: response.data },
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CLIENTES_LOADING, payload: false });
      }
    );
  };
};
