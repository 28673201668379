import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  clientes: {
    fontFamily: "Open Sans",
    widht: '100vw',
    height: '100vh',
    zIndex: 0,
    "& h1": {
      fontSize: "16px",
      marginTop: "2em",
    },
    "& h2": {
      textAlign: "left",
      fontSize: "17px",
      width: "24%",
      marginLeft: '2em',
      color: '#1d4f91 ',
      borderBottom: "1px solid #1d4f91 ",
    },
    
  },
  clientesContainer:{
    width: '70%',
    margin: 'auto'
  },
  modalCadastro:{
    padding: '2em',
  },
  cadastro:{
    padding: '2em',
    '& button':{
      backgroundColor: '#1d4f91',
      fontSize: '12.5px',
      color: '#fff',
      '&:hover':{
        backgroundColor: '#215faf',  
        transition: '0.3s'
      }
      
    }
  },
  actions:{
    width: '50%',
    '& button':{
      minWidth: '30px',
    }
  }

}));

export default useStyles;
