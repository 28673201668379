import React from "react";
import { TextField } from "@material-ui/core";

import CashMaskedInput from "./components/CashMaskedInput";
import GenericMaskedInput from "./components/GenericMaskedInput";
import NumberMaskedInput from "./components/NumberMaskedInput";

function InputMask({
  format,
  prefix,
  mask,
  placeholder = "",
  value,
  onChange = () => {},
  type = "generic",
  ...props
}) {
  const getTypeMask = () => {
    switch (type) {
      case "generic":
        return (
          <GenericMaskedInput
            format={format}
            prefix={prefix}
            mask={mask}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            customInput={TextField}
            {...props}
          />
        );
      case "number":
        return (
          <NumberMaskedInput
            customInput={TextField}
            onChange={onChange}
            value={value}
            {...props}
          />
        );
      case "cash":
        return (
          <CashMaskedInput
            format={format}
            mask={mask}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            customInput={TextField}
            prefix={prefix}
            {...props}
          />
        );
      default:
        break;
    }
  };

  return getTypeMask();
}
export default InputMask;