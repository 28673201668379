

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'


function ResumoConsulta({consultaJson}){
    return(
        <div id="resumoConsulta">
        <Table>
          <tr>
            <Th> Resumo da Consulta</Th>
          </tr>
        </Table>
        <Table>
          <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
            <Th>CNPJ</Th>
            <Th>RAZÃO SOCIAL</Th>
            <Th>DATA DE FUNDAÇÃO</Th>
          </tr>
          <tr>
            <Td> {consultaJson?.meta?.document} </Td>
            <Td> {consultaJson?.report?.confirmei.name} </Td>
            <Td> {consultaJson?.report?.confirmei.foundationDate} </Td>
          </tr>
        </Table>
      <br />
      </div>
    )
}

export default ResumoConsulta