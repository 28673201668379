import styled from "@emotion/styled";
import toCurrency from "../../../helpers/toCurrency";
import PieChartComponent from "../scoreComponents/pieChart";
import { Grid } from "@material-ui/core";

export function InformacoesSocietarias({ data }) {
  const Box = styled.div`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-size: 12px;
    margin-left: 15px;
    border-radius: 7px;
    text-align: center;
    width: 80%;
    @media print {
      font-size: 10px;
      width: 80%;
      margin-left: 25px;
      height: 10mm;
      padding: 3mm;
    }
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
    @media print {
      margin: 0;
      font-size: 8px;
      height: 10mm;
      width: 60mm;
      padding: 5mm;
    }
  `;

  const SizeLine = styled.div`
    width: 400px;
    font-size: 14px;
    @media print {
      font-size: 10px;
      width: 60mm;
    }
  `;

  const LineInfo = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
    width: 100%;
    @media print {
      font-size: 10px;
    }
  `;

  const H4 = styled.h4`
    margin: 0;
    background-color: #e9ecf3;
    color: #5b6475;
    border-bottom: 2px solid #b6bcc1;
    font-size: 16px;
    @media print {
      font-size: 10px;
    }
  `;

  const Descricao = styled.h2`
    margin: 0;
    color: #0d6eb7;
  `;

  function formatArray(inputArray) {
    const colors = [
      "#3498DB",
      "#1ABC9C",
      "#9B59B6",
      "#FF5733",
      "#27AE60",
      "#F1C40F",
      "#2980B9",
      "#E67E22",
      "#E74C3C",
      "#2C3E50",
      "#34495E",
      "#8E44AD",
      "#16A085",
      "#27AE60",
    ];

    return (
      inputArray &&
      inputArray.map((item, index) => {
        return {
          ...item,
          value: item.capitalPercentage,
          color: colors[index % colors.length],
        };
      })
    );
  }

  const sociosDadosFormatados = formatArray(
    data.report?.registerInformation?.corporateControl?.partnerDetails
  );

  function formatPercentage(value) {
    const stringValue = String(value).replace(".", ",");
    return `${stringValue}%`;
  }

  function analisarArray(array) {
    if (!Array.isArray(array)) {
      console.error("O argumento fornecido não é um array válido.");
      return array;
    }

    let soma = 0;

    // Calcular a soma atual
    for (let i = 0; i < array.length; i++) {
      if (array[i].value) {
        soma += array[i].value;
      }
    }

    if (soma < 100) {
      const valorRestante = 100 - soma;

      console.log(valorRestante > 1)

      if(valorRestante > 1){

        const valorArredondado = Math.round(valorRestante);
  
        const novoRegistro = {
          block: "",
          informationType: "",
          identification: "N",
          document: "",
          documentType: "DOCUMENTO OFICIAL",
          cpfDigit: "",
          name: "S/A",
          originCountry: "",
          capitalPercentage: valorArredondado,
          entryDate: "Invalid date",
          votingCapitalPercentage: valorArredondado,
          companySituation: "ATIVA",
          serasaCode: null,
          consistent: null,
          value: valorArredondado,
          color: "gray",
        };
  
        array.push(novoRegistro);
      }
      }

    return array;
  }

  const resultado = analisarArray(sociosDadosFormatados);

  return (
    <>
      {data?.report?.registerInformation?.corporateControl && (
        <div style={{ marginTop: 20 }}>
          <b>Informações sobre Sócios e Administradores </b>
          <div
            style={{ backgroundColor: "#e9ecf3", padding: 15, borderRadius: 8 }}
          >
            Informações Societárias
            <div
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#f6f7fa",
                padding: 15,
                borderRadius: 8,
              }}
            >
              Quadro social
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "100%" }}>
                  <Grid container spacing={1} style={{ textAlign: "center" }}>
                    <Grid item xs={4} sm={4}>
                      {resultado && <PieChartComponent value={resultado} />}
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Grid
                        container
                        spacing={1}
                        style={{ textAlign: "center" }}
                      >
                        <Grid item xs={6} sm={6}>
                          <Box>
                            <Descricao>
                              {toCurrency(
                                data?.report?.registerInformation?.corporateControl?.valueSocialCapital
                              )}
                            </Descricao>
                            <br />
                            Capital Social
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Box>
                            <Descricao>
                              {toCurrency(
                                data.report?.registerInformation?.corporateControl?.valueRealizedCapital
                              )}
                            </Descricao>
                            <br />
                            Realizado
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Box>
                            <Descricao>
                              {
                                data.report?.registerInformation?.corporateControl?.nationalityDescription
                              }
                            </Descricao>
                            <br />
                            Origem
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Box>
                            <Descricao>
                              {
                                data.report?.registerInformation?.corporateControl?.originDescription
                              }
                            </Descricao>
                            <br />
                            Controle
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Box>
                            <Descricao>
                              {
                                data.report?.registerInformation?.corporateControl?.natureDescription
                              }
                            </Descricao>
                            <br />
                            Natureza
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: 10,
                  }}
                >
                  <LineInfo>
                    <H4>CPF/CNPJ</H4>
                  </LineInfo>
                  <LineInfo>
                    <SizeLine>
                      <H4>Sócio / Acionista</H4>
                    </SizeLine>
                  </LineInfo>
                  <LineInfo>
                    <H4>Entrada</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Nacionalidade</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Votante</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Total</H4>
                  </LineInfo>
                </div>
                {sociosDadosFormatados &&
                  sociosDadosFormatados?.map((item) => {
                    return (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <LineInfo>{item.document + item.cpfDigit}</LineInfo>
                        <LineInfo>
                          <SizeLine>
                            <b style={{ fontSize: 18, color: item.color }}>•</b>{" "}
                            {item.name}
                          </SizeLine>
                        </LineInfo>
                        <LineInfo>{item.entryDate && item.entryDate}</LineInfo>
                        <LineInfo>{item.originCountry}</LineInfo>
                        <LineInfo>
                          {formatPercentage(item.votingCapitalPercentage)}
                        </LineInfo>
                        <LineInfo>
                          {formatPercentage(item.capitalPercentage)}
                        </LineInfo>
                      </div>
                    );
                  })}
              </div>
              <b style={{ color: "gray" }}>Administração:</b>
              <div style={{ marginTop: 20 }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: 10,
                  }}
                >
                  <LineInfo>
                    <H4>CPF/CNPJ</H4>
                  </LineInfo>
                  <LineInfo>
                    <SizeLine>
                      <H4>Administração</H4>
                    </SizeLine>
                  </LineInfo>
                  {/* <LineInfo>
                    <H4>Entrada</H4>
                  </LineInfo> */}
                  <LineInfo>
                    <H4>Cargo</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Nacionalidade</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Estado Civil</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Entrada</H4>
                  </LineInfo>
                  <LineInfo>
                    <H4>Anotações</H4>
                  </LineInfo>
                </div>
                {data.report?.registerInformation?.administrativeBoard?.details.map(
                  (item) => {
                    return (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <LineInfo>{item.document + item.cpfDigit}</LineInfo>
                        <LineInfo>
                          <SizeLine>{item.name}</SizeLine>
                        </LineInfo>
                        <LineInfo>{item.office}</LineInfo>
                        <LineInfo>{item.originCountry}</LineInfo>
                        <LineInfo>{item.civilStatus}</LineInfo>
                        <LineInfo>{item.entryDate}</LineInfo>
                        <LineInfo>{item.restriction ? "SIM" : "NÃO"}</LineInfo>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
