import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  consumo:{
    width: '95%',
    margin: 'auto',
    textAlign: "center",
    marginTop: '2em'
  },
  relatorio:{
    width: '80%',
    margin: 'auto',
    '& h1':{
      fontSize: '16px',
      textAlign: 'left',
      fontWeight: 'normal'
    }
  },

  descriptions: {
    textAlign: "center",
    marginTop: "1em",
    border: "1px solid #9b9b9b",
    borderRadius: "7px",
    padding: "20px",
    width: '80%',
    margin: 'auto',
    "& h3": {
      fontSize: "11px",
      backgroundColor: "#fff",
      marginTop: "-2.2em",
      width: '80%',
    },
    '& img':{
     
      cursor: 'pointer',
      marginBottom: '-0.5em'
    }
  },
  grafico:{
    height: '60vh'
  },
  consumo_financeiro:{
    width: '60%',
    margin: 'auto',
    marginTop: '2em'
  },
  btnBusca:{
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    '& button':{
      padding: '0.85em 1em',
      borderRadius: '4px',
      border: 'none',
      background: '#fff',
      cursor: 'pointer',
      fontSize: '13px',
      marginTop: '0.1em'

    }
  }
  
}));

export default useStyles;
