export const CLIENTES_LOADING = 'CLIENTES_LOADING';
export const CLIENTES_PERMISSAO = 'CLIENTES_PERMISSAO';
export const CLIENTES_LOAD = 'CLIENTES_LOAD';
export const CLIENTE_SET = 'CLIENTE_SET';
export const CLIENTES_PAGE = 'CLIENTES_PAGE';
export const CLIENTES_PERPAGE = 'CLIENTES_PERPAGE';
export const CLIENTES_TOTAL = 'CLIENTES_TOTAL';
export const CLIENTE_PLANOS = 'CLIENTE_PLANOS';
export const CLIENTES_FEATURES = 'CLIENTES_FEATURES';
export const CLIENTES_CONFIGURACOES = 'CLIENTES_CONFIGURACOES';



