import React, { useState } from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import { CModal, CTextField, CInputDate, CInputMask,} from "./../../../components/index";
import dayjs from "dayjs";
import InputMask from "react-input-mask";
import { CancelOutlined, CheckCircleOutline} from "@material-ui/icons";
import { cadastrar_clientes, editar_cliente, listar_clientes } from "./../../../store/actions/clientes";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../store/types/clientes'

function EditarCliente({ open, close }) {
  
  const { cliente } = useSelector((state) => state.clientes );  


  
  const dispatch = useDispatch()

  function closeModal() {   
    close();    
  }


  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const removedots = (value) =>
    value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");

 

  async function sendCadastro(event) {
    event.preventDefault();
    try {
      await editar_cliente({
        ...cliente,
        data_fundacao: dayjs(cliente?.data_fundacao).format("YYYY-MM-DD"),
        cnpj: removedots(cliente?.cnpj),
      }, cliente?.id);
      Toast.fire({
        icon: "success",
        title: "Cadastro efetuado com sucesso!",
      });
      dispatch(listar_clientes({ page: 1,  per_page: 10}));
      closeModal();
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos!",
      });
    }
  }

  return (
    <CModal modalWidth="64%" modalHeight="auto" open={open}>
      <div style={{padding: '2em'}}>
        Formulario de Cadastro
        <br />
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="Nome"
              name="nome"
              variant="outlined"
              value={cliente?.nome}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, nome: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              mask="99.999.999/9999-99"
              value={cliente?.cnpj}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, cnpj: e.target.value} })}
            >
              {() => <CTextField label="CNPJ" name="cnpj" variant="outlined" />}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={3}>
            <CInputDate
              label="Data de Fundação"
              value={cliente?.data_fundacao}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, data_fundacao: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="Endereço"
              name="endereco"
              variant="outlined"
              value={cliente?.endereco}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, endereco: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Numero"
              name="numero"
              variant="outlined"
              value={cliente?.numero}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, numero: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Complemento"
              name="complemento"
              variant="outlined"
              value={cliente?.complemento}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, complemento: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CTextField
              label="Bairro"
              name="bairro"
              variant="outlined"
              value={cliente?.bairro}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, bairro: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CTextField
              label="Cidade"
              name="cidade"
              variant="outlined"
              value={cliente?.cidade}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, cidade: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputMask
              mask="99999-999"
              value={cliente?.cep}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, cep: e.target.value} })}
            >
              {() => <CTextField label="CEP" name="cep" variant="outlined" />}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={2}>
            <CTextField
              label="UF"
              name="uf"
              variant="outlined"
              value={cliente?.uf}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, uf: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CTextField
              label="E-mail"
              name="email"
              variant="outlined"
              value={cliente?.email}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, email: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Telefone"
              name="telefone"
              variant="outlined"
              value={cliente?.telefone}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, telefone: e.target.value} })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CTextField
              label="Celular"
              name="celular"
              variant="outlined"
              value={cliente?.celular}
              onChange={(e) => dispatch({type: types.CLIENTE_SET, payload : {...cliente, celular: e.target.value} })}
            />
          </Grid>
        
      
        </Grid>
        <br />
        <Divider />
        <div style={{ float: "right", marginRight: "2em" }}>
          <Button onClick={sendCadastro} style={{ color: "#056d29" }}>
            <CheckCircleOutline style={{ width: "16px" }} />
            &nbsp; Editar
          </Button>
          <Button onClick={closeModal} style={{ color: "#890f0f" }}>
            <CancelOutlined style={{ width: "16px" }} />
            &nbsp; Fechar
          </Button>
        </div>
      </div>
    </CModal>
  );
}

export default EditarCliente;
