import BudgetPieChart from "../scoreComponents/budgetPieChart";
import styled from "@emotion/styled";

export function EstimatedIncome({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
    margin-left: 15px;
    border-radius: 7px;
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;


const dataValue = [
  { value: 1000, label: '0', color: '#d4e5fc' },
  { value: 1000, label: '1000', color: '#d4e5fc' },
  { value: 1000, label: '2000', color: '#bcd9ff' },
  { value: 1000, label: '3000', color: '#a4c9f9' },
  { value: 1000, label: '4000', color: '#86b8f9' },
  { value: 1000, label: '5000', color: '#70acf9' },
  { value: 1000, label: '6000', color: '#559efc' },
  { value: 1000, label: '7000', color: '#378cfc' },
  { value: 1000, label: '8000', color: '#207ffc' },
  { value: 1000, label: '9000', color: '#0771fc' },
  { value: 1000, label: '10000',  color: '#006eff' },
];
  return (
    <>
      {data.report?.scoring && (
        <div
          style={{
            width: "80%",
            margin: "auto",
            border: "1px solid #afacac",
          }}
        >
          <div style={{borderBottom: '1px solid #AFACAC'}}>
              <b style={{padding: 15}}>Renda Estimada</b> 

          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: 250, padding: 15, borderRadius: 15 }}>
              <div>
                <BudgetPieChart
                  value={
                    data.report.estimatedIncome?.income ? data.report.estimatedIncome?.income : 0
                  }
                  data={dataValue}
                />         
              </div>
            </div>
            <div
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  border: "1px solid gray",
                  width: 300,
                  height: 100,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 7,
                }}
              >
                 {data.report.estimatedIncome?.income && (
                  <>
                    {data.report.estimatedIncome?.income > 0 &&
                    data.report.estimatedIncome?.income < 1000
                      ? "R$ 0 a R$1.000"
                      : data.report.estimatedIncome?.income > 1001 &&
                        data.report.estimatedIncome?.income < 2000
                      ? "R$ 1.001 a R$2.000"
                      : data.report.estimatedIncome?.income > 2001 &&
                        data.report.estimatedIncome?.income < 3000
                      ? "R$ 2.001 a R$3.000"
                      : data.report.estimatedIncome?.income > 3001 &&
                        data.report.estimatedIncome?.income < 4000
                      ? "R$ 3.001 a R$4.000"
                      : data.report.estimatedIncome?.income > 4001 &&
                        data.report.estimatedIncome?.income < 5000
                      ? "R$ 4.001 a R$5.000"
                      : data.report.estimatedIncome?.income > 5001 &&
                        data.report.estimatedIncome?.income < 6000
                      ? "R$ 5.001 a R$6.000"
                      : data.report.estimatedIncome?.income > 6001 &&
                        data.report.estimatedIncome?.income < 7000
                      ? "R$ 6.001 a R$7.000"
                      : data.report.estimatedIncome?.income > 7001 &&
                        data.report.estimatedIncome?.income < 8000
                      ? "R$ 7.001 a R$8.000"
                      : data.report.estimatedIncome?.income > 8001 &&
                        data.report.estimatedIncome?.income < 9000
                      ? "R$ 8.001 a R$9.000"
                      : "R$10.000+"}
                  </>
                )}
              
              </div>
              <div
                style={{
                  border: "1px solid gray",
                  marginLeft: 15,
                  padding: "0px 10px",
                  height: 100,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 7,
                }}
              >
                O consumidor possúi uma renda mensal estimada entre &nbsp;
                {data.report.estimatedIncome?.income && (
                  <>
                    {data.report.estimatedIncome?.income > 0 &&
                    data.report.estimatedIncome?.income < 1000
                      ? "R$ 0 a R$1.000"
                      : data.report.estimatedIncome?.income > 1001 &&
                        data.report.estimatedIncome?.income < 2000
                      ? "R$ 1.001 a R$2.000"
                      : data.report.estimatedIncome?.income > 2001 &&
                        data.report.estimatedIncome?.income < 3000
                      ? "R$ 2.001 a R$3.000"
                      : data.report.estimatedIncome?.income > 3001 &&
                        data.report.estimatedIncome?.income < 4000
                      ? "R$ 3.001 a R$4.000"
                      : data.report.estimatedIncome?.income > 4001 &&
                        data.report.estimatedIncome?.income < 5000
                      ? "R$ 4.001 a R$5.000"
                      : data.report.estimatedIncome?.income > 5001 &&
                        data.report.estimatedIncome?.income < 6000
                      ? "R$ 5.001 a R$6.000"
                      : data.report.estimatedIncome?.income > 6001 &&
                        data.report.estimatedIncome?.income < 7000
                      ? "R$ 6.001 a R$7.000"
                      : data.report.estimatedIncome?.income > 7001 &&
                        data.report.estimatedIncome?.income < 8000
                      ? "R$ 7.001 a R$8.000"
                      : data.report.estimatedIncome?.income > 8001 &&
                        data.report.estimatedIncome?.income < 9000
                      ? "R$ 8.001 a R$9.000"
                      : "R$10.000+"}
                  </>
                )}
                , Esta estimativa é baseada nas melhores práticas e modelos estatísticos, mas não refletir a renda real da pessoa consultada
              </div>
            </div>
          </div>
          <div style={{ borderTop: '1px solid #AFACAC', fontSize: 13}}>
            A decisão da aprovação ou não do crédito é de exclusiva
            responsabilidade do concedente. As informações prestadas pela Serasa
            Experian têm como objetivo subsidiar essas decisões e, em hipótese
            alguma, devem ser utilizadas como justificativa, pelo concedente do
            crédito, para a tomada da referida decisão
          </div>
        </div>
      )}
      <br />
    </>
  );
}
