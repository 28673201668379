import * as types from '../types/clientes';

const hoje = new Date();

const INITIAL_STATE = {
    loading: false,
    permissao: {},
    clientes: [],
    cliente: {
        nome: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cep: "",
        cidade: "",
        uf: "",
        data_fundacao: hoje,
        cnpj: "",
        telefone: "",
        celular: "",
        email: "",
        valor_pf_basica: 0.0,
        valor_pf_avancada: 0.0,
        valor_pj_basica: 0.0,
        valor_pj_avancada: 0.0,
    },
    page: 1,
    per_page: 10,
    total: 0,
    planos: [],
    features: [],
    configuracoes: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CLIENTES_LOADING:
            return { ...state, loading: action.payload};
        
        case types.CLIENTES_PERMISSAO:
            return { ...state, permissao: action.payload};

        case types.CLIENTES_LOAD:
            return { ...state, clientes: action.payload};

        case types.CLIENTE_SET:
            return { ...state, cliente: action.payload};

        case types.CLIENTES_PAGE:
            return { ...state, page: action.payload};

        case types.CLIENTES_PERPAGE:
            return { ...state, per_page: action.payload}
            ;
        case types.CLIENTES_TOTAL:
            return { ...state, total: action.payload};    

        case types.CLIENTES_FEATURES:
            return { ...state, features: action.payload};    

        case types.CLIENTE_PLANOS:
            return { ...state, planos: action.payload};  
            
            
        case types.CLIENTES_CONFIGURACOES:
            return { ...state, configuracoes: action.payload};    
          
      default:
        return state;
    }
};