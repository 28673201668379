import React, { useState } from "react";
import { CInputSelect, CModal, CTextField } from "../../../../components";
import {
  CancelOutlined,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { cadastrar_usuario } from "./../../../../store/actions/usuario";
import { useDispatch, useSelector } from "react-redux";

function CadastrarUsuario({ open, close, id, update }) {
  const documento = {
    nome: "",
    email: "",
    configuracao: "",
    password: "",
    permissao: "",
  };
  const { configuracoes } = useSelector((state) => state.clientes);

  const selectConfigs = configuracoes.map((item) => ({
    value: item.id,
    label: item.email,
  }));

  const [dados, setDados] = useState({ ...documento });
  const [showPass, setShowPass] = useState(false);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  async function sendCadastro(event) {
    event.preventDefault();
    try {
      await cadastrar_usuario({
        ...dados,
        permissoes: {
          tipo: dados.permissao,
          cliente_id: id,
          configuracao_id: dados.configuracao,
        },
      });
      update();
      close();
    } catch (error) {
      console.log("erro");
    }
  }

  return (
    <>
      <CModal open={open} modalWidth="50%" modalHeight="auto">
        <div style={{ padding: "2em" }}>
          Cadastrar Usuario
          <Divider style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <CTextField
                label="Nome"
                name="nome"
                variant="outlined"
                value={dados.nome}
                onChange={(e) => setDados({ ...dados, nome: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CTextField
                label="Email"
                name="email"
                variant="outlined"
                value={dados.email}
                onChange={(e) => setDados({ ...dados, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CInputSelect
                name="configuracoes"
                values={[{ value: 0, label: "Selecione " }].concat(
                  selectConfigs
                )}
                value={dados.configuracao}
                label="Selecione a Configuração"
                onChange={(e) =>
                  setDados({ ...dados, configuracao: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CInputSelect
                name="permissao"
                values={[
                  { value: 0, label: "Selecione " },
                  { value: "ADMINISTRADOR", label: "Administrador " },
                  { value: "ADMINISTRADOR_FINANCEIRO", label: "Financeiro" },
                  { value: "CLIENTE_ADMINISTRADOR", label: "Cliente Administrador"},
                  { value: "CLIENTE_FUNCIONARIO", label: "Cliente Funcionário"},
                ]}
                value={dados.permissao}
                label="Selecione a Permissão"
                onChange={(e) =>
                  setDados({ ...dados, permissao: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CTextField
                label="Senha"
                name="password"
                type={showPass ? "text" : "password"}
                variant="outlined"
                value={dados.password}
                onChange={(e) =>
                  setDados({ ...dados, password: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: "Pointer", opacity: "0.7" }}
                      onClick={ShowPassword}
                    >
                      <IconButton onClick={ShowPassword}>
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <div
            style={{ float: "right", marginRight: "2em", marginBottom: "1em " }}
          >
            <Button onClick={sendCadastro} style={{ color: "#056d29" }}>
              <CheckCircleOutline style={{ width: "16px" }} />
              &nbsp; Cadastrar
            </Button>
            <Button onClick={close} style={{ color: "#890f0f" }}>
              <CancelOutlined style={{ width: "16px" }} />
              &nbsp; Fechar
            </Button>
          </div>
        </div>
      </CModal>
    </>
  );
}

export default CadastrarUsuario;
