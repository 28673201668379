import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function CommercialReferences({consultaJson}){
    return(
        <div id="commercialReferences">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Referências comerciais das consultas mais recentes
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.serasaQueries.commercialReferences.length >
        0 ? (
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Empresa </Th>
              <Th>Data da consulta </Th>
            </tr>
            {consultaJson?.report?.serasaQueries.commercialReferences.map(
              (item) => {
                <tr>
                  <Td> {item?.company} </Td>
                  <Td> {item?.date} </Td>
                </tr>;
              }
            )}
          </Table>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default CommercialReferences