import toCurrency from "../../../../helpers/toCurrency";
import { Box, Container, Table, Td, Th, Tr } from "./styles";

function Lawsuits({ data }) {
  return (
    <>
      {data?.lawsuits && (
        <Container>
          <Box>
            <h4>Ações Judiciais - Até 5 ocorrencias mais recentes</h4>
            <Table>
              <Tr>
                <Th>Data</Th>
                <Th>Natureza</Th>
                <Th>Valor</Th>
                <Th>Avalista</Th>
                <Th>Vara</Th>
                <Th>Cidade/UF</Th>
              </Tr>
              {data.lawsuits.map((item) => {
                return (
                  <tr>
                    <Td>{item.date}</Td>
                    <Td>{item.nature}</Td>
                    <Td>{toCurrency(item.value)}</Td>
                    <Td>{item.isGuarantor ? "Sim" : "Não"}</Td>
                    <Td>{item.civilCourt}</Td>
                    <Td>
                      {item.city}/{item.state}
                    </Td>
                  </tr>
                );
              })}
            </Table>
          </Box>
        </Container>
      )}
    </>
  );
}

export default Lawsuits;
