import React, { useEffect, useState } from "react";
import useStyles from "./styles";

import { Button, Link } from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PersonPinCircleOutlined from "@material-ui/icons/PersonPinCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import * as types from '../../../store/types/usuario'

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

export default function Conta() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [id, setAccessToken] = useState("");

  const { usuario, permissao } = useSelector((state) => state.usuario);

  const [user, setUser] = useState({})

  useEffect(() => {
    const _permissoes = localStorage.getItem('permissao')  
    const _usuario = localStorage.getItem('usuario')  
    setUser(JSON.parse(_usuario))    
    dispatch(     
      { type: types.USUARIO_PERMISSAO, payload: JSON.parse(_permissoes)}
      )

  },[])
  
 


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function logout(event) {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div className={classes.Btn}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <AccountCircleIcon /> <h1>Minha Conta</h1>
        <ExpandMoreIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} className={classes.grow}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <h1>{user.nome}</h1>
                  <h2>
                    {permissao.tipo} <br /> {user.email}
                  </h2>
                  {/* <MenuItem onClick={handleClose}>
                    <LockOpenIcon style={{ width: "18px", color: "#565656" }} />
                    <LinkRouter to="/app">&nbsp;Meus dados</LinkRouter>
                  </MenuItem> */}
                  {permissao?.tipo == "ADMINISTRADOR" ? (
                    <MenuItem onClick={handleClose}>
                      <PersonPinCircleOutlined
                        style={{ width: "18px", color: "#565656" }}
                      />
                      <LinkRouter to="/app/clientes">&nbsp;Clientes</LinkRouter>
                    </MenuItem>
                  ) : (
                    ""
                  )}
{/* 
                  {permissao?.tipo == "CLIENTE" ? (
                    <MenuItem onClick={handleClose}>
                      <PersonPinCircleOutlined
                        style={{ width: "18px", color: "#565656" }}
                      />
                      <LinkRouter to="/app/clientes">&nbsp;Usuários</LinkRouter>
                    </MenuItem>
                  ) : (
                    ""
                  )} */}

                  <LinkRouter to="/login" onClick={logout}>
                    <MenuItem onClick={handleClose}>
                      <ExitToAppIcon
                        style={{ width: "18px", color: "#565656" }}
                      />
                      &nbsp;Sair
                    </MenuItem>
                  </LinkRouter>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
