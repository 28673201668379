import html2pdf from "html2pdf.js";
import React, { useEffect, useRef } from "react";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionConsultaJson } from "../../store/actions/consultas";

import {
  AcoesJudiciais,
  ChequesSemFundos,
  DividasVencidas,
  ParticipacaoEmFalencia,
  ParticipacaoEmpresas,
  PendenciasComerciaisPefin,
  PendenciasComerciaisRefin,
  Protestos,
  ResumoConsulta,
  ResumoPendenciasFinanceiras,
} from "./components";
import { EstimatedIncome } from "./components/estimatedIncome";
import InformacoesComplementaresComplementares from "./components/informacoesCadastraisComplementares";
import ConsultasRealizadasCPFnoSerasa from "./components/serasaQueries";
import { SerasaScoreAvancadoPf } from "./components/serasaScore";
import { ButtonSave, Container } from "./style";

function AvancadoPessoaFisica() {
  const { id, cnpj } = useParams();
  const dispatch = useDispatch();
  const { consultaJson, consultaLoading } = useSelector(
    (state) => state.consultas
  );

  useEffect(() => {
    dispatch(ActionConsultaJson(id, cnpj));
  }, []);

  const printableRef = useRef(null);

  const imprimirPDF = () => {
    const printableComponent = printableRef.current;

    if (printableComponent) {
      const element = printableComponent.cloneNode(true);
      const options = {
        filename: `${consultaJson?.meta?.document}.pdf`,
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a2", orientation: "portrait" },
        autotable: { startY: false },
      };

      html2pdf().from(element).set(options).save();
    }
  };

  return (
    <>
      {!consultaLoading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "90%",
            }}
          >
            <ButtonSave onClick={imprimirPDF}>
              <BsFileEarmarkPdf />
              Salvar em PDF
            </ButtonSave>
          </div>
          <div ref={printableRef}>
            <Container>
              <h3 style={{ textAlign: "center", fontFamily: "Roboto" }}>
                Relatório Avançado | Pessoa Física{" "}
              </h3>

              <ResumoConsulta consultaJson={consultaJson} />

              {/* Pagamentos Pendentes */}
              <ResumoPendenciasFinanceiras consultaJson={consultaJson} />
              <PendenciasComerciaisPefin consultaJson={consultaJson} />
              <PendenciasComerciaisRefin consultaJson={consultaJson} />
              <ChequesSemFundos consultaJson={consultaJson} />
              <Protestos consultaJson={consultaJson} />
              <AcoesJudiciais consultaJson={consultaJson} />
              <ParticipacaoEmFalencia consultaJson={consultaJson} />
              <DividasVencidas consultaJson={consultaJson} />
              <ConsultasRealizadasCPFnoSerasa consultaJson={consultaJson} />
              <ParticipacaoEmpresas consultaJson={consultaJson} />
              <SerasaScoreAvancadoPf data={consultaJson} />
              <EstimatedIncome data={consultaJson} />
              <InformacoesComplementaresComplementares data={consultaJson} />
            </Container>
          </div>
        </>
      )}
    </>
  );
}

export default AvancadoPessoaFisica;
