import * as types from '../types/extrato';

const INITIAL_STATE = {
    loading: false,
    resumo_administrador: [],
    resumo_usuario: {},
    resumo_usuario_mes_anterior: {},
    faturaDetalhada: [],
    assinatura: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.EXTRATO_LOADING:
            return { ...state, loading: action.payload};

        case types.EXTRATO_RESUMO_ADMINISTRADOR:
            return { ...state, resumo_administrador: action.payload};   
                   
        case types.EXTRATO_RESUMO_USUARIO:
            return { ...state, resumo_usuario: action.payload};

        case types.EXTRATO_RESUMO_USUARIO_MES_ANTERIOR:
            return { ...state, resumo_usuario_mes_anterior: action.payload};

        case types.EXTRATO_FATURA_DETALHADA:
            return { ...state, faturaDetalhada: action.payload};
        case types.EXTRATO_FATURA_ASSINATURA:
            return { ...state, assinatura: action.payload};
          
      default:
        return state;
    }
};