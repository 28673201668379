import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function ChequesSemFundos({ consultaJson }) {
  return (
    <>
      {consultaJson?.report?.badChecks?.occurrences?.length > 0 && (
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>
                {" "}
                <h3>Cheques Sem Fundos </h3>
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Nº Cheque</Th>
              <Th>Nº Conta</Th>
              <Th>Banco</Th>
              <Th>Agencia</Th>
              <Th>Cidade</Th>
              <Th>UF</Th>
              <Th>Valor (R$)</Th>
            </tr>
            {consultaJson?.report?.badChecks?.occurrences?.map((item) => {
              return (
                <tr>
                 <Td>{item?.checkNumber}</Td>
                  <Td>{item?.account}</Td>
                  <Td>{item?.bankName}</Td>
                  <Td>{item?.bankAgency}</Td>
                  <Td>{item?.city}</Td>
                  <Td>{item?.state}</Td>
                  <Td>{item?.value && toCurrency(item.value)}</Td>
                </tr>
              );
            })}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.badChecks?.totalOccurrences}. Periodo{" "}
                {consultaJson?.report?.badChecks?.oldestOccurrenceDate} a{" "}
                {consultaJson?.report?.badChecks?.newestOccurrenceDate} valor
                total das ocorrencias{" "}
                {consultaJson?.report?.badChecks?.totalValue &&
                  toCurrency(consultaJson?.report?.badChecks?.totalValue)}{" "}
              </Th>
            </tr>
          </Table>
        </>
      )}
    </>
  );
}

export default ChequesSemFundos;
