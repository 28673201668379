import { Button, Divider } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { CModal } from "../../../components";

export default function ModalTipo({ tipo, open, close, classes }) {
  return (
    <>
      <CModal open={open} modalWidth={"30%"} modalHeight={"auto"}>
        <div className={classes.modalInformations}>
          Informações sobre os tipos de consultas:
          <Divider />
          {tipo == "FISICA" ? (
            <>
              <h3>CPF: Consulta Básica</h3>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
              fugit asperiores in obcaecati animi assumenda, ex ipsa minima
              voluptates quod cumque nostrum quasi aliquid possimus itaque et
              alias voluptatum porro?
              <br />
              <h3>CPF: Consulta Completa</h3>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
              fugit asperiores in obcaecati animi assumenda, ex ipsa minima
              voluptates quod cumque nostrum quasi aliquid possimus itaque et
              alias voluptatum porro? lore
            </>
          ) : (
            <>
              <h3>CNPJ: Consulta Básica</h3>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
              fugit asperiores in obcaecati animi assumenda, ex ipsa minima
              voluptates quod cumque nostrum quasi aliquid possimus itaque et
              alias voluptatum porro?
              <br />
              <h3>CNPJ: Consulta Completa</h3>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
              fugit asperiores in obcaecati animi assumenda, ex ipsa minima
              voluptates quod cumque nostrum quasi aliquid possimus itaque et
              alias voluptatum porro?
            </>
          )}
        </div>
        <br />
        <div className={classes.buttonModal}>
          <Button onClick={close}>Fechar</Button>
        </div>
        <br />
      </CModal>
    </>
  );
}
