import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

import de0a100 from "../../assets/0-100.jpg";
import de100a200 from "../../assets/100-200.jpg";
import de200a300 from "../../assets/200-300.jpg";
import de300a400 from "../../assets/300-400.jpg";
import de400a500 from "../../assets/400-500.jpg";
import de500a600 from "../../assets/500-600.jpg";
import de600a700 from "../../assets/600-700.jpg";
import de700a800 from "../../assets/700-800.jpg";
import de800a900 from "../../assets/800-900.jpg";
import de900a1000 from "../../assets/900-1000.jpg";

function SerasaPositiveScore({ consultaJson }) {
  return (
    <div id="serasaPositiveScore">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Serasa Score com Positivo
          </Th>
        </tr>
      </Table>
      <Table>
        <tr>
          <Th style={{ color: "#255391", textAlign: "center", width: '30%' }}>
            {consultaJson?.report?.serasaPositiveScore.score >= 0 &&
            consultaJson?.report?.serasaPositiveScore.score <= 100 ? (
              <img src={de0a100} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 100 &&
              consultaJson?.report?.serasaPositiveScore.score <= 200 ? (
              <img src={de100a200} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 200 &&
              consultaJson?.report?.serasaPositiveScore.score <= 300 ? (
              <img src={de200a300} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 300 &&
              consultaJson?.report?.serasaPositiveScore.score <= 400 ? (
              <img src={de300a400} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 400 &&
              consultaJson?.report?.serasaPositiveScore.score <= 500 ? (
              <img src={de400a500} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 500 &&
              consultaJson?.report?.serasaPositiveScore.score <= 600 ? (
              <img src={de500a600} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 600 &&
              consultaJson?.report?.serasaPositiveScore.score <= 700 ? (
              <img src={de600a700} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 700 &&
              consultaJson?.report?.serasaPositiveScore.score <= 800 ? (
              <img src={de700a800} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 800 &&
              consultaJson?.report?.serasaPositiveScore.score <= 900 ? (
              <img src={de800a900} />
            ) : consultaJson?.report?.serasaPositiveScore.score >= 900 &&
              consultaJson?.report?.serasaPositiveScore.score <= 1000 ? (
              <img src={de900a1000} />
            ) : (
              ""
            )}
            <h2 style={{ marginTop: -40, fontSize: 26 }}>
              {consultaJson?.report?.serasaPositiveScore.score}{" "}
            </h2>
            de 1000
          </Th>
          <Th style={{ color: "#255391", textAlign: "center", width: "20%" }}>
            <h2 style={{ fontSize: 26, marginBottom: 0 }}>
              {100 -
                +consultaJson?.report?.serasaPositiveScore
                  .delinquencyProbability}
              %
            </h2>
            <br />
            Chance de
            <br /> pagamento
          </Th>
          <Th style={{ color: "#255391", fontSize: 15 }}>
            A chance de um consumidor, com o score entre{" "}
            {consultaJson?.report?.serasaPositiveScore.score && (
              <>
                {consultaJson?.report?.serasaPositiveScore.score > 0 &&
                consultaJson?.report?.serasaPositiveScore.score < 100
                  ? "1 e 100"
                  : consultaJson?.report?.serasaPositiveScore.score > 101 &&
                    consultaJson?.report?.serasaPositiveScore.score < 200
                  ? "101 e 200"
                  : consultaJson?.report?.serasaPositiveScore.score > 201 &&
                    consultaJson?.report?.serasaPositiveScore.score < 300
                  ? "201 e 300"
                  : consultaJson?.report?.serasaPositiveScore.score > 301 &&
                    consultaJson?.report?.serasaPositiveScore.score < 400
                  ? "301 e 400"
                  : consultaJson?.report?.serasaPositiveScore.score > 401 &&
                    consultaJson?.report?.serasaPositiveScore.score < 500
                  ? "401 e 500"
                  : consultaJson?.report?.serasaPositiveScore.score > 501 &&
                    consultaJson?.report?.serasaPositiveScore.score < 600
                  ? "501 e 600"
                  : consultaJson?.report?.serasaPositiveScore.score > 601 &&
                    consultaJson?.report?.serasaPositiveScore.score < 700
                  ? "601 e 700"
                  : consultaJson?.report?.serasaPositiveScore.score > 701 &&
                    consultaJson?.report?.serasaPositiveScore.score < 800
                  ? "701 e 800"
                  : consultaJson?.report?.serasaPositiveScore.score > 801 &&
                    consultaJson?.report?.serasaPositiveScore.score < 900
                  ? "801 e 900"
                  : "901 e 1000"}
              </>
            )}
            , pagar seus compromissos financeiros nos próximos{" "}
            {consultaJson?.report?.serasaPositiveScore
              .delinquencyProbability ? (
              <>
                {100 -
                  consultaJson?.report?.serasaPositiveScore
                    .delinquencyProbability}
                %
              </>
            ) : (
              "Não calculado"
            )}
          </Th>
        </tr>
      </Table>
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            A decisão da aprovação ou não do crédito é de exclusiva
            responsabilidade do concedente. As informações prestadas pela Serasa
            Experian têm como objetivo subsidiar essas decisões e, em hipótese
            alguma, devem ser utilizadas como justificativa, pelo concedente do
            crédito, para a tomada da referida decisão.
          </Th>
        </tr>
      </Table>
      <br />
    </div>
  );
}

export default SerasaPositiveScore;
