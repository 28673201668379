import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import { CInputMask } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { editar_planos } from "../../../../store/actions/clientes";

function EditPlano( {data, close}) {
  const dispatch = useDispatch();

  const [editValor, setEditValor] = useState({...data });



  async function AddFeature() {
    await editar_planos(data.id, editValor.valor_consulta);
    try {     
        close()
    } catch (error) {
      return console.log(error);
    }
  }


  return (
    <div>
      <div>
        <Divider style={{ margin: "1em 0 1em 0" }} />
        <CInputMask
          type="cash"
          label="Edite o valor"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          value={editValor.valor_consulta}
          onValueChange={(v) =>
            setEditValor({ ...editValor, valor_consulta: v.floatValue || 0 })
          }
        />
        <button
          onClick={() => AddFeature()}
          style={{
            width: "100%",
            marginTop: "1em",
            padding: "1em",
            border: "1px solid #2b7ba0",
            color: "#2b7ba0",
            cursor: "pointer",
            backgroundColor: "transparent",
            borderRadius: "4px",
          }}
        >
          {" "}
          <b>Editar</b>
        </button>
      </div>
    </div>
  );
}

export default EditPlano;
