import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AttachMoney } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { resumo_extrato_usuario } from './../../../store/actions/extrato';
import {toCurrency} from './../../../helpers/index';

export default function Saldo() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { permissao } = useSelector((state) => state.usuario);
  const { resumo_usuario } = useSelector((state) => state.extrato);
  const [data, setData] = useState("P");


  const date = new Date();
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1; 



  useEffect(() => {
    dispatch(resumo_extrato_usuario({ mes: mes,  ano: ano}));
  }, []);

  return (
    <div className={classes.saldo}>
      <h1 style={{marginTop: 5}}>
        {permissao.tipo == "ADMINISTRADOR" ? (
          ""
        ) : (
          <>
           Consumo Atual {toCurrency(resumo_usuario.valor_total)}
          </>
        )}
      </h1>
    </div>
  );
}
