import { Divider, Grid } from "@material-ui/core";
import { toCurrency } from "../../../helpers";

export function CheckboxComponent({ dados, handleCheckboxChange }) {
  return (
    <div>
      <Divider />
           <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
             Descrição
            </Grid>
            <Grid item xs={12} sm={2}>
              Valor
            </Grid>
          </Grid>
      {dados.map((item) => (
        <div key={item.id}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
              <label>
                <input
                  type="checkbox"
                  value={item.id}
                  onChange={handleCheckboxChange}
                />
                {item.descricao} <br />
              </label>
            </Grid>
            <Grid item xs={12} sm={2}>
              {toCurrency(item.valor)}
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
}
