import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cvinculados: {
    fontFamily: "Open Sans",
    widht: '100vw',
    height: '100vh',
    zIndex: 0,
    "& h1": {
      fontSize: "16px",
      marginTop: "2em",
    },
    "& h2": {
      textAlign: "left",
      fontSize: "17px",
      width: "24%",
      marginLeft: '2em',
      color: '#1d4f91 ',
      borderBottom: "1px solid #1d4f91 ",
    },
    
  },
  cvinculadosContainer:{
    width: '70%',
    margin: 'auto'
  },
  voltarbtn:{
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    float: 'right',
    '& button':{
      padding: '0.85em 1em',
      borderRadius: '4px',
      border: 'none',
      background: '#fff',
      cursor: 'pointer',
      fontSize: '13px',
      marginTop: '1em',
      marginLeft: '1em',

    }
  }

}));

export default useStyles;
