import { Table, Td, Th } from "../style";
import styled from "@emotion/styled";
import toCurrency from "../../../helpers/toCurrency";
import iconpjavancada from "../../../assets/iconpjavancada.png";
import { Grid } from "@material-ui/core";

export function CreditLimit({ data }) {
  const Box = styled.div`
    border: 1px solid #898989;
    height: 100px;
    display: flex;
    border-radius: 8px;
    align-items: center;  
    @media print {
    }
  `;

  const BoxLimit = styled.th`
    width: 220px;
    font-size: 12px;
    height: 60px;

    @media print {
      width: auto; /* Largura da página A4 em milímetros */
      margin: 0;
      height: 10mm;

      box-sizing: border-box;
      font-size: 10px;
    }
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

  function primeiraMaiuscula(texto) {
    return (
      texto && texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase()
    );
  }

  return (
    <>
      {data?.report?.creditLimit && (
        <div
          style={{
            backgroundColor: "#e9ecf3",
            padding: 15,
            borderRadius: 8,
            marginTop: 15,
            border: "1px solid #e9ecf3",
          }}
        >
          <div
            style={{
              width: "90%",
              margin: "auto",
              backgroundColor: "#f6f7fa",
              padding: 15,
              borderRadius: 8,
            }}
          >
            <div>
              <div style={{ width: "50%" }}>Limite de Crédito PJ</div>
              <div>
                Informação Atualizada em{" "}
                {data?.report?.creditLimit?.calculationDateTime}
              </div>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4} sm={4} >
                <Box style={{ width: "100%", justifyContent: 'center', alignContent: 'center', alignItems: "center", display: "flex" }}>
                  <img src={iconpjavancada} style={{ width: 50 }} />
                  {toCurrency(data?.report?.creditLimit?.value)}
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} style={{}}>
                <div style={{ width: "100%" ,}}>
                  <Box >
                    {data?.report?.creditLimit?.message ? (
                      primeiraMaiuscula(data?.report?.creditLimit?.message)
                    ) : (
                      <>
                        Metodologia Utilizada <br />O limite de crédtio
                        PJ foi calculado com base em referências de negócios e
                        técnicas de modelagem estatísticas, alinhado ao atual
                        faturamento estimado e Score Empresas. utilizando-se
                        informações do Cadastro Positivo.
                      </>
                    )}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
