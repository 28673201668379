import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function DividasVencidas({ consultaJson }) {
  return (
    <>
      {consultaJson?.report?.overdueDebt?.occurrences?.length > 0 && (
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>
                {" "}
                <h3> Dívidas Vencidas</h3>
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Data</Th>
              <Th>Nº Título</Th>
              <Th>Compania</Th>
              <Th>Valor</Th>
              <Th>Código da Cidade</Th>
            </tr>
            {consultaJson?.report?.overdueDebt?.occurrences?.map((item) => {
              return (
                <tr>
                  <Td>{item?.date}</Td>
                  <Td>{item?.titleNumber}</Td>
                  <Td>{item?.company}</Td>
                  <Td>{item?.value && toCurrency(item?.value)}</Td>
                  <Td>{item?.cityCode}</Td>
                </tr>
              );
            })}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.overdueDebt?.totalOccurrences}. Periodo{" "}
                {consultaJson?.report?.overdueDebt?.oldestOccurrenceDate} a{" "}
                {consultaJson?.report?.overdueDebt?.newestOccurrenceDate} valor
                total das ocorrencias{" "}
                {consultaJson?.report?.overdueDebt?.totalValue &&
                  toCurrency(consultaJson?.report?.overdueDebt?.totalValue)}{" "}
              </Th>
            </tr>
          </Table>
          <br/>
        </>
      )}
    </>
  );
}

export default DividasVencidas;
