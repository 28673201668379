import { Table, Td, Th } from "../style";
import styled from "@emotion/styled";
import BudgetPieChart from "../scoreComponents/budgetPieChart";
import { Grid } from "@material-ui/core";

export function SerasaScoreWithPositive({ data }) {
  const Box = styled.div`
    border: 1px solid #afacac;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
    padding: 15px 0px;
    height: 30px;
    width: 100%;
    margin-left: 15px;
    border-radius: 7px;
    @media print {
      font-size: 10px;
    height: 5mm;

    }
  `;

  const BoxMaior = styled.div`
      border: 1px solid #afacac;
    text-align: left;
    font-size: 12px;
    padding: 15px 0px;
    width: 100%;
    margin-left: 15px;
    border-radius: 7px;
    @media print {
      font-size: 8px;
    }
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    font-size: 12px;
    margin-right: 5px; 
    @media print {
      font-size: 10px;
    }
  `;

  return (
    <>
      {data.report?.serasaScoreWithPositive && (
        <div
          style={{ backgroundColor: "#e9ecf3", padding: 15, borderRadius: 8 }}
        >
          <div
            style={{
              width: "90%",
              margin: "auto",
              backgroundColor: "#f6f7fa",
              padding: 15,
              borderRadius: 8,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 250, padding: 15, borderRadius: 15 }}>
                Serasa Score 2.0
                <div style={{ marginTop: 35 }}>
                  <BudgetPieChart
                    value={
                      data.report.serasaScoreWithPositive.riskScoringFactor
                        ? data.report.serasaScoreWithPositive.riskScoringFactor
                        : 0
                    }
                  />
                  <div style={{ textAlign: "center", fontSize: 18 }}>
                    <b style={{ fontSize: 14 }}>Score</b> <br />{" "}
                    {data.report.serasaScoreWithPositive.riskScoringFactor
                      ? data.report.serasaScoreWithPositive.riskScoringFactor
                      : "0"}
                    <h6 style={{ margin: 0, fontWeight: "normal" }}>
                      {" "}
                      de 1000
                    </h6>
                  </div>
                </div>
              </div>
              <div style={{ width: "70%" }}>
                <h4 style={{ textAlign: "right", fontSize: 13 }}>
                  Informação Atualizada em{" "}
                  {data.report.serasaScoreWithPositive.dateTime}
                </h4>

                <div style={{ width: "100%" }}>
                  <h4
                    style={{
                      margin: 0,
                      textAlign: "right",
                      fontSize: 13,
                      padding: 15,
                      color: "#333333",
                    }}
                  >
                    ______ Práticas de mercado ______
                  </h4>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  <Grid container spacing={1} style={{ textAlign: "center" }}>
                    <Grid item xs={6} sm={6}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        <b style={{ color: "#426fb3" }}>
                          Probabilidade de 
                          Inadimplência
                        </b>
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        {data.report?.serasaScoreWithPositive?.prinadFactor ? (
                          <>
                            {data.report?.serasaScoreWithPositive?.prinadFactor} %
                          </>
                        ) : (
                          "Não Cal"
                        )}
                      </div>
                    </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <b style={{ color: "#426fb3" }}>
                          Risco de
                          <br /> Crédito
                        </b>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        {data.report.serasaScoreWithPositive
                          .riskScoringFactor && (
                          <>
                            {data.report.serasaScoreWithPositive
                              .riskScoringFactor < 250
                              ? "Alto"
                              : data.report.serasaScoreWithPositive
                                  .riskScoringFactor > 251 &&
                                data.report.serasaScoreWithPositive
                                  .riskScoringFactor < 500
                              ? "Médio"
                              : data.report.serasaScoreWithPositive
                                  .riskScoringFactor > 501 &&
                                data.report.serasaScoreWithPositive
                                  .riskScoringFactor < 750
                              ? "Baixo"
                              : "Mínimo"}
                          </>
                        )}
                      </div>
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <BoxMaior style={{ display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center"}}>
                    A pontuação enquadra-se na faixa de
                    {data.report.serasaScoreWithPositive?.riskScoringFactor < 50
                      ? "de 01 a 50"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          51 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          100
                      ? "de 51 a 100"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          101 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          150
                      ? "de 101 a 150"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          151 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          200
                      ? "de 150 a 201"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          201 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          250
                      ? "de 201 a 250"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          250 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          301
                      ? "de 250 a 301"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          301 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          350
                      ? "de 301 a 350"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          351 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          400
                      ? "de 351 a 400"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          401 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          450
                      ? "de 401 a 450"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          451 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          500
                      ? "de 451 a 500"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          501 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          550
                      ? "de 501 a 550"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          551 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          600
                      ? "de 551 a 600"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          601 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          650
                      ? "de 601 a 650"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          651 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          700
                      ? "de 651 a 700"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          701 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          750
                      ? "de 701 a 750"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          751 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          800
                      ? "de 751 a 800"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          801 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          850
                      ? "de 801 a 850"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          851 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          900
                      ? "de 851 a 900"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          901 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          950
                      ? "de 901 a 950"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          951 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          1000
                      ? "de 951 a 1000"
                      : "Não calculado"}{" "}
                    e representa risco{" "}
                    {data.report.serasaScoreWithPositive?.riskScoringFactor < 250
                      ? "Alto"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          251 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          500
                      ? "médio"
                      : data.report.serasaScoreWithPositive?.riskScoringFactor >
                          501 &&
                        data.report.serasaScoreWithPositive?.riskScoringFactor <
                          750
                      ? "baixo"
                      : "mínimo"}{" "}
                    de crédito. Para empresas com este perfil de risco, é
                    prática de mercado conceder crédito para compras de
                    mercadorias ou serviços sem necessidades de garantias
                    adicionais. Em média, empresas com esta categoria de risco
                    costumam honrar os compromissos assumidos em {data.report?.serasaScoreWithPositive?.prinadFactor ? 100 - data.report?.serasaScoreWithPositive?.prinadFactor : 0} %
                  </BoxMaior>
                    </Grid>
                    </Grid>
                  
                   
                  </div>
                  
                </div>
              </div>
            </div>
            <div>
              <b style={{ color: "gray", fontSize: 12, marginLeft: 20 }}>
                Legenda
              </b>
              <div id="legend" style={{ marginTop: 15, marginLeft: 20 }}>
                <div >
                  <span style={{color: '#0d6eb7'}}>◉</span> &nbsp;Risco mínimo a
                  baixo de crédito
                </div>
                <div>
                 <span style={{color: '#00a045'}}>◉</span> &nbsp;Risco baixo a
                  médio de crédito
                </div>
                <div >
                  <span style={{color: '#ffda00'}}>◉</span> &nbsp;Risco médio a
                  relevane de crédito
                </div>
                <div >
                  <span style={{color: '#ff8e21'}}>◉</span> &nbsp;Iminente risco de
                  crédito - Default
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
