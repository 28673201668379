import { toCurrency } from "../../../../helpers";
import { Box, Container, Table, Td, Th, Tr } from "./styles";

function Refin({ data }) {
  return (
    <>
      {data?.refin && (
        <Container>
          <Box>
          <h4>Refin - Até 5 ocorrencias mais recentes</h4>
          <Table>
            <Tr>
              <Th>Data</Th>
              <Th>Modalidade</Th>
              <Th>Avalista</Th>
              <Th>Valor</Th>
              <Th>Contrato</Th>
              <Th>Origem</Th>
              <Th>Local</Th>
            </Tr>
            {data?.refin?.occurrences.map((item) => {
              return (
                <tr>
                  <Td>{item.date}</Td>
                  <Td>{item.title}</Td>
                  <Td>{item.isGuarantor ? "Sim" : "Não"}</Td>
                  <Td>{toCurrency(item.value)}</Td>
                  <Td>{item.contract}</Td>
                  <Td>{item.origin}</Td>
                  <Td>{item.subJudiceDistrict}</Td>
                </tr>
              );
            })}
          </Table>
      </Box>
    </Container>
      )}
      </>
  );
}

export default Refin;
