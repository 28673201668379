import PaymentHistoryTitles from "./PaymentHistoryTitles";
import BusinessReferences from "./businessReferences";
import MainSuppliers from "./mainSuppliers";
import MainSuppliersRelationshipsByPeriod from "./mainSuppliersRelationshipsByPeriod";
import { SuppliersCommitmentEvolution } from "./suppliersCommitmentEvolution";

function PositiveCommercialHistory({ data }) {

  return (
    <div>
      {/* <Spelling data={data?.positiveCommercialHistory} /> */}
      {data?.positiveCommercialHistory && 
        <div>
          <PaymentHistoryTitles data={data?.positiveCommercialHistory} />
          <SuppliersCommitmentEvolution data={data?.positiveCommercialHistory} />
          <BusinessReferences data={data?.positiveCommercialHistory} />
          <MainSuppliers data={data?.positiveCommercialHistory} />
          <MainSuppliersRelationshipsByPeriod data={data?.positiveCommercialHistory} />

        </div>
        
        }
    </div>
  );
}

export default PositiveCommercialHistory;
