import { Grid } from "@material-ui/core";
import { Box, Container } from "./styles";

function PaymentHistoryTitles({ data }) {

  function formatarTexto(texto) {
    let palavras = texto.toLowerCase().split(" ");
    for (let i = 0; i < palavras.length; i++) {
      palavras[i] = palavras[i].charAt(0).toUpperCase() + palavras[i].substring(1);
    }
    
    let textoFormatado = palavras.join(" ");
    
    return textoFormatado;
  }
 

  return (
    <>
      {data?.paymentHistoryTitles && (
        <Container>
          <h4>Histórico de Pagamentos</h4>
          <Box>
            Quantidade de títulos
            <Grid container spacing={1} style={{ textAlign: "center" }}>
              {data?.paymentHistoryTitles.map((item) => {
                return (
                  <Grid item xs={2} sm={2}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: 'center',
                        justifyContent: 'center',
                        border: "1px solid gray",
                        borderRadius: 15,
                        width: "100%",
                        height: 80,
                        background: item.periodDescription === 'PONTUAL' ? '#7c94f8' : ''
                      }}
                    >
                      <div
                        style={{
                          background: "gray",
                          color: "#ffffff",
                          height: '100%',
                          borderRadius: 10,
                          width: '20%'
                        }}
                      >
                        <h4 style={{ transform: "rotate(-90deg)", marginTop: 30, }}>
                          {item.rangeCode}
                        </h4>
                      </div>
                      <div
                        style={{
                          width: '80%',
                          color:  item.periodDescription === 'PONTUAL' ? '#FFF' :  "" 
                        }}
                      >
                        {formatarTexto(item.periodDescription)} <br />{" "}
                        <b style={{ color:  item.periodDescription === 'PONTUAL' ? '#FFF' :  "#226a84" }}>
                          {item.rangeDescription}
                        </b>
                        <br/>
                        <span style={{ color:  item.periodDescription === 'PONTUAL' ? '#FFF' :  "#226a84" , fontSize: 12}}>
                          {item?.initialPercentage ? <>entre {item.initialPercentage}% até {item.finalPercentage}% </> : ''}
                        </span>
                      </div>
                     
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}

export default PaymentHistoryTitles;
