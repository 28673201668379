import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  PictureAsPdfOutlined,
  Search,
  Send as SendIcon,
} from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CDataTable,
  CDialog,
  CModal,
  CPaginate,
} from "../../../components/index";
import http from "../../../services/api";
import * as types from "../../../store/types/consultas";
import { formatCNPJ, formatCPF, toCurrency } from "./../../../helpers/index";
import {
  ActionConsultaJson,
  CancelarConsultaEfetuadaAction,
  DownloadArquivo,
  atualizarVendaEfetuada,
  listar_consultas,
} from "./../../../store/actions/consultas";
import { GiCancel } from "react-icons/gi";

function TabelaConsultas({ status_filtro }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { consultas, page, rows, total, filtro, listar_precos_cliente } =
    useSelector((state) => state.consultas);
  const { permissao } = useSelector((state) => state.usuario);

  console.log(permissao.tipo);

  const [dialogConfirmarVenda, setDialogConfirmarVenda] = useState(false);
  const [idConfirmacaoVenda, setIdConfirmacaoVenda] = useState(0);
  const [_uuidArquivo, setUuidArquivo] = useState();
  const status = status_filtro == "P" ? "P" : undefined;
  const [openModalConsulta, setOpenModalConsulta] = useState(false);
  const [featuresDetails, setFeaturesDetails] = useState([]);
  const [informacoesDaFeature, setInformacoesDafeature] = useState(0);
  const [dialogCancel, setDialogCancel] = useState(false);
  const [cancelId, setCancelId] = useState("");

  const [nomeCliente, setNomeCliente] = useState("");
  const [buscaClienteDebounce, setBuscaClienteDebounce] = useState("");

  const handleInputChange = (e) => {
    const valor = e.target.value;
    setNomeCliente(valor);
  };

  useEffect(() => {
    const salvarNomeFuncionario = () => {
      setBuscaClienteDebounce(nomeCliente);
    };

    // useEffect(() => {
    //   console.log(buscaClienteDebounce)
    // }, [buscaClienteDebounce]);

    const debounceTimeout = 800;
    let timeoutId;

    const handleChange = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        salvarNomeFuncionario();
      }, debounceTimeout);
    };

    handleChange();

    return () => clearTimeout(timeoutId);
  }, [nomeCliente]);

  useEffect(() => {
    dispatch(
      listar_consultas({
        page: 1,
        per_page: rows,
        status,
        filter: buscaClienteDebounce,
      })
    );
  }, [buscaClienteDebounce]);

  const handleChangePage = (event, newPage) => {
    dispatch([
      { type: types.CONSULTAS_LOADING, payload: true },
      { type: types.CONSULTAS_PAGE, payload: newPage + 1 },
      listar_consultas({
        ...filtro,
        page: newPage + 1,
        per_page: rows,
        paginate: true,
        status,
        filter: buscaClienteDebounce,
      }),
    ]);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch([
      { type: types.CONSULTAS_LOADING, payload: true },
      { type: types.CONSULTAS_PERPAGE, payload: true },
      listar_consultas({
        ...filtro,
        page: 1,
        per_page: parseInt(event.target.value),
        paginate: true,
        status,
        filter: buscaClienteDebounce,
      }),
    ]);
  };

  async function BaixarArquivoDiretorio(tipo_consulta, uuid) {
    await DownloadArquivo(tipo_consulta, uuid);
    try {
      setUuidArquivo();
    } catch (error) {
      console.log("erro ao baixar");
    }
  }
  async function downloadConsulta(consulta, id, cnpj) {
    try {
      await dispatch(ActionConsultaJson(id, cnpj));
      if (consulta === "1") {
        navigate(`/app/consulta/basicopf/${cnpj}/${id}`);
      } else if (consulta === "2") {
        navigate(`/app/consulta/avancadopf/${cnpj}/${id}`);
      } else if (consulta === "3") {
        navigate(`/app/consulta/basicopj/${cnpj}/${id}`);
      } else if (consulta === "4") {
        navigate(`/app/consulta/avancadopj/${cnpj}/${id}`);
      } else {
        BaixarArquivoDiretorio(consulta, id);
        setUuidArquivo(id);
      }
    } catch (e) {
      console.log("Não foi possível concluir a consulta ");
    }
  }

  function openDialogConfirmacaoVenda(data) {
    setIdConfirmacaoVenda(data);
    setDialogConfirmarVenda(true);
  }

  // JSON.parse(row?.features_adicionais) tipo_consulta_id

  async function confirmarVenda() {
    await atualizarVendaEfetuada(idConfirmacaoVenda);
    dispatch(listar_consultas({ page: 1, per_page: 10, status }));
    setIdConfirmacaoVenda(0);
    setDialogConfirmarVenda(false);
  }

  async function OpenModalconsulta(data) {
    setInformacoesDafeature(data);
    const featuresObjeto =
      (await data.features_adicionais) && JSON.parse(data.features_adicionais);
    http
      .post("/api/v1/consultas/consulta-features", {
        numeros: featuresObjeto.features_selecionadas,
      })
      .then((resp) => {
        setFeaturesDetails(resp.data.descricoes);
      });
    setOpenModalConsulta(true);
  }

  function onSelectConsultToCancel(data) {
    setDialogCancel(true);
    setCancelId(data);
    console.log(data);
  }

  async function CancelarConsultaEfetuada(id) {
    try {
      await CancelarConsultaEfetuadaAction(id);
      setDialogCancel(false);
      setCancelId("");
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div style={{ width: "100%", marginLeft: 25 }}>
        <h4>Resumo das consultas </h4>
        <div style={{ width: "50%" }}>
          <TextField
            label="Digite o Nome, CPF ou CNPJ(Sem pontos) para pesquisar"
            value={nomeCliente}
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleInputChange}
          />
        </div>
        {buscaClienteDebounce && (
          <h5>Você pesquisou por: {buscaClienteDebounce}</h5>
        )}
      </div>

      <Grid item sm={12}>
        <PerfectScrollBar>
          <CDataTable
            columns={[
              {
                title: "CPF/CNPJ",
                cellStyle: { paddingLeft: "1em", width: "15%" },
                render: (row) =>
                  row.cnpj &&
                  (row.cnpj.length === 11 ? (
                    <span
                      style={{ color: row.status !== "C" ? "#000" : "red" }}
                    >
                      {formatCPF(row.cnpj)}
                    </span>
                  ) : (
                    <span
                      style={{ color: row.status !== "C" ? "#000" : "red" }}
                    >
                      {formatCNPJ(row.cnpj)}
                    </span>
                  )),
              },
              {
                title: "Nome Cliente",
                cellStyle: { width: "20%" },
                render: (row) => (
                  <Tooltip
                    title={`Empresa: ${row.nome} |${
                      row.tipo_pessoa == 1
                        ? "Física Básica"
                        : row.tipo_pessoa == 2
                        ? "Física Avançada"
                        : row.tipo_pessoa == 3
                        ? "Juridíca Básica"
                        : "Juridíca Avançada"
                    }`}
                  >
                    <p
                      style={{
                        maxWidth: "25ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 14,
                        color: row.status !== "C" ? "#000" : "red",
                      }}
                    >
                      {row.nome}
                    </p>
                  </Tooltip>
                ),
              },
              {
                title: "Usuario",
                cellStyle: { width: "10%" },
                render: (row) => (
                  <Tooltip title={row.nome_usuario}>
                    <p
                      style={{
                        maxWidth: "15ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 14,
                        color: row.status !== "C" ? "#000" : "red",
                      }}
                    >
                      {row.nome_usuario}
                    </p>
                  </Tooltip>
                ),
              },
              {
                title: "Data",
                field: "created_at",
                cellStyle: { textAlign: "center" },
                render: (row) => {
                  return (
                    <Tooltip
                      title={`Hora ${dayjs(row?.created_at)
                        .add(3, "hour")
                        .format("HH:mm")}`}
                    >
                      <span
                        style={{ color: row.status !== "C" ? "#000" : "red" }}
                      >
                        {" "}
                        {dayjs(row?.created_at).format("DD/MM/YYYY")}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                title: "Valor Relatório",
                cellStyle: { textAlign: "center" },
                render: (row) => toCurrency(row.valor),
              },
              {
                title: "Valor da Venda",
                cellStyle: { textAlign: "center" },
                render: (row) =>
                  row.valor_venda ? toCurrency(row.valor_venda) : toCurrency(0),
              },
              {
                title: "Venda Efetuada",
                cellStyle: { textAlign: "center" },
                render: (row) =>
                  row.venda_efetuada ? (
                    <Tooltip title="Venda Confirmada">
                      {/* <button
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: "none",
                        }}
                        onClick={() => VerInformacoesAdicionais(row)}
                      > */}
                      <FiThumbsUp
                        style={{ fontSize: "18px", color: "#2ba54f" }}
                      />
                      {/* </button> */}
                    </Tooltip>
                  ) : (
                    <Tooltip title="Confirmar Venda!">
                      <button
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: "none",
                        }}
                        onClick={() => openDialogConfirmacaoVenda(row.id)}
                      >
                        <FiThumbsDown
                          style={{ fontSize: "18px", color: "#a53e03" }}
                        />
                      </button>
                    </Tooltip>
                  ),
              },
              {
                title: "Arquivo",
                cellStyle: { textAlign: "center" },
                render: (row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        <h4 style={{ margin: 0, fontSize: 16 }}>
                          {row.tipo_pessoa == 1
                            ? "Física Básica"
                            : row.tipo_pessoa == 2
                            ? "Física Avançada"
                            : row.tipo_pessoa == 3
                            ? "Juridíca Básica"
                            : "Juridíca Avançada"}
                        </h4>
                      }
                    >
                      <button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => OpenModalconsulta(row)}
                      >
                        <HiOutlineInformationCircle
                          style={{ fontSize: "18px", color: "#090909" }}
                        />
                      </button>
                    </Tooltip>
                    {_uuidArquivo == row.uuid_file ? (
                      <CircularProgress size={20} />
                    ) : _uuidArquivo ? (
                      <Tooltip title="Aguarde o outro arquivo finalizar o download">
                        <PictureAsPdfOutlined
                          style={{ fontSize: "24px", color: "#727272" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Baixar Arquivo">
                        <Button
                          onClick={() =>
                            downloadConsulta(
                              row.tipo_consulta,
                              row.uuid_file,
                              row.cnpj
                            )
                          }
                        >
                          <PictureAsPdfOutlined
                            style={{ fontSize: "24px", color: "#8e1c1c" }}
                          />
                        </Button>
                      </Tooltip>
                    )}

                    {permissao && permissao.tipo === "ADMINISTRADOR" && (
                      <>
                        {row.status !== "C" ? (
                          <Tooltip title="Cancelar consulta">
                            <button
                              onClick={() => onSelectConsultToCancel(row)}
                              style={{
                                border: "none",
                                background: "transparent",
                                cursor: "pointer",
                              }}
                            >
                              <GiCancel size={18} />
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Consulta Cancelada">
                            <span>
                              <GiCancel size={18} color="red" />
                            </span>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                ),
              },
            ]}
            data={consultas}
            options={{
              headerStyle: {
                backgroundColor: "#F2F2F3",
                fontSize: 12,
                textAlign: "center",
                borderLeft: "solid 0.5px white",
              },
            }}
          />
        </PerfectScrollBar>
        <CPaginate
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          rowsPerPage={+rows}
          count={parseInt(total)}
          page={parseInt(page) - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <CDialog
          open={dialogConfirmarVenda}
          title="Confirmar venda?"
          contenttext={"Atenção, essa ação não podera ser revertida"}
          buttons={[
            {
              title: "Cancelar",
              icon: <ClearIcon />,
              style: { color: "gray" },
              onClick: () => {
                setDialogConfirmarVenda(false);
                setIdConfirmacaoVenda(0);
              },
            },
            {
              title: "Confirmar",
              icon: <SendIcon />,
              style: { color: "green" },
              onClick: () => confirmarVenda(),
            },
          ]}
        />

        <CModal open={openModalConsulta} modalWidth="30%" modalHeight="auto">
          <div style={{ padding: 5 }}>
            <h4>Abaixo informações da consulta</h4>
            <Divider />
            <div
              style={{ fontSize: 18, flexDirection: "row", display: "flex" }}
            >
              {" "}
              Tipo da consulta:
              <b style={{ marginLeft: 5 }}>
                {informacoesDaFeature.tipo_pessoa == 1
                  ? "Física Básica"
                  : informacoesDaFeature.tipo_pessoa == 2
                  ? "Física Avançada"
                  : informacoesDaFeature.tipo_pessoa == 3
                  ? "Juridíca Básica"
                  : "Juridíca Avançada"}
              </b>
            </div>
            <br />
            {featuresDetails.length > 0 && (
              <>
                Features selecionadas:{" "}
                <b style={{ marginLeft: 5 }}>{featuresDetails.join(", ")}</b>
              </>
            )}
            <br />
            <button
              style={{
                width: "100%",
                marginTop: 20,
                padding: "10px 15px",
                background: "transparent",
                border: "1px solid #702020",
                borderRadius: 7,
                color: "#702020",
                cursor: "pointer",
                marginBottom: 5,
              }}
              onClick={() => setOpenModalConsulta(false)}
            >
              Fechar
            </button>
          </div>
        </CModal>
      </Grid>

      <CDialog
        open={dialogCancel}
        title="Deseja realmente cancelar a consulta?"
        contenttext={
          <>
            Empresa: {cancelId.nome} <br />
            Data: {dayjs(cancelId?.created_at).format("DD/MM/YYYY")} <br />
            Consulta:{" "}
            {cancelId.tipo_pessoa == 1
              ? "Física Básica"
              : cancelId.tipo_pessoa == 2
              ? "Física Avançada"
              : cancelId.tipo_pessoa == 3
              ? "Juridíca Básica"
              : "Juridíca Avançada"}
            <br />
            <br />
            <b>Atenção: </b>Esta ação não poderá ser revertida
          </>
        }
        buttons={[
          {
            title: "Cancelar",
            icon: <ClearIcon />,
            style: { color: "gray" },
            onClick: () => {
              setDialogCancel(false);
            },
          },
          {
            title: "Confirmar",
            icon: <SendIcon />,
            style: { color: "green" },
            onClick: () => CancelarConsultaEfetuada(cancelId.id),
          },
        ]}
      />
    </>
  );
}

export default TabelaConsultas;
