import * as types from '../types/usuario';

const INITIAL_STATE = {
    loading: false,
    usuario: { },
    permissao: {},
    listar_usuario: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.USUARIO_LOADING:
            return { ...state, loading: action.payload};

        case types.USUARIO_SET:
            return { ...state, usuario: action.payload};   

        case types.LISTAR_USUARIO:
            return { ...state, listar_usuario: action.payload};   
                   
        case types.USUARIO_PERMISSAO:
            return { ...state, permissao: action.payload};
          
      default:
        return state;
    }
};