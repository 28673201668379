import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Tooltip, XAxis, YAxis } from 'recharts';



function BarChartComponent({data}) {

  const largura = window. screen. width;

  console.log(largura)

  const [eventPress, setEventPress] = useState(false)

  window.addEventListener('beforeprint', function() {
    setEventPress(true)
    console.log('Impressão acionada');
  });

  window.addEventListener('afterprint', function() {
    setEventPress(false)
    console.log('Impressão acionada');
  });

  return (
      
      <BarChart      
        width={eventPress ? 400 : 800}
        height={200}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
        barSize={eventPress ? 25 : 40}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 25, right: 20 }} fontSize={12}/>
        <YAxis fontSize={eventPress ? 10 : 14} />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="Quantidade" fill="#26478d" >
        <LabelList
          dataKey="Quantidade"
          position="centerTop"
          offset={5}
          fill='#ffffff'
        />
        </Bar>
      </BarChart>
  );
}

export default BarChartComponent;