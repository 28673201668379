

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function StateProtests({consultaJson}){
    return(
        <div id="stateProtests">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Protesto Nacional
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.stateProtests.occurrences.length > 0 ? (
          <>
            <Table>
              <tr
                style={{ backgroundColor: "#e6e6e6", color: "#666263" }}
              >
                <Th>Data </Th>
                <Th>Valor </Th>
                <Th>Cartório </Th>
                <Th>Contrato </Th>
                <Th>Cidade </Th>
                <Th>UF </Th>
              </tr>
              {consultaJson?.report?.stateProtests.occurrences.map(
                (item) => {
                  return (
                    <tr>
                      <Td> {item?.date} </Td>
                      <Td> {item?.value && toCurrency(item?.value)} </Td>
                      <Td> {item?.registryOffice} </Td>
                      <Td> {item?.annotationType} </Td>
                      <Td> {item?.city} </Td>
                      <Td> {item?.state} </Td>
                    </tr>
                  );
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th style={{ color: "#255391", background: "#dee5eb" }}>
                  Total de ocorrências{" "}
                  {consultaJson?.report?.stateProtests?.total}. Periodo{" "}
                  {
                    consultaJson?.report?.stateProtests
                      ?.oldestOccurrenceDate
                  }{" "}
                  a{" "}
                  {
                    consultaJson?.report?.stateProtests
                      ?.newestOccurrenceDate
                  }{" "}
                  valor total das ocorrencias{" "}
                  {consultaJson?.report?.stateProtests?.totalValue &&
                    toCurrency(
                      consultaJson?.report?.stateProtests?.totalValue
                    )}
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default StateProtests