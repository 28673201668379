import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import experimentalStyled from "styled-components";
import Navbar from "./DashboardNavbar/index";
import { useSelector, useDispatch} from 'react-redux'
import * as types from './../store/types/usuario'

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  // backgroundColor: "#9b9b9b",
  display: "flex",
  flexDirection: 'column',
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch()
  const { usuario, permissao} = useSelector((state) => state.usuario)


  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: types.USUARIO_SET, payload: usuario });
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <DashboardLayoutRoot>
      <Navbar />
      <Outlet />
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
