import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Swal from "sweetalert2";
import api from "./../../../../services/api";
import { useParams } from "react-router-dom";
import logoConsultas from "./../../../../assets/images/logo_colorida.png";
import { TextField, Button } from "@material-ui/core";

const NewPass = () => {
  const { id, key } = useParams();

  const [value, setValue] = useState("");

  function handleChange(event) {
    setValue(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let email = value;
    try {
      let res = await api
        .post(`/api/v1/users/receive-new-password/${id}/${key}`, {
          password: value,
        })
        .then(
          Swal.fire({
            icon: "success",
            title: "Sucesso...",
            timer: 3000,
            text: `Senha alterada com sucesso! `,
            footer: "Faça o login com a nova senha",
          }).then(function (result) {
            if (true) {
              window.location = "/login";
            }
          })
        );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Esse link já foi utilizado",
        footer: "Solicite nova senha!",
        
      }).then(function (result) {
        if (true) {
          window.location = "/login";
        }
      })
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
      <img src={logoConsultas} />
        <h2>Crie uma nova senha:</h2>
        <div>
          <TextField
            label="Crie uma nova senha"
            variant="outlined"
            type="password"
            value={value}
            onChange={handleChange}
          />
        </div>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          // startIcon={<SaveIcon />}
        >
          Salvar
        </Button>
      </form>
    </>
  );
};

const ChangePassword = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.forget}>
        <div className={classes.container}>
          <div className={classes.forgetContainer}>
            <NewPass />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
