import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function Bankruptcies({ consultaJson }) {
  return (
    <div id="falenciasConcordatas">
      <Table>
        <tr>
          <Th style={{ color: "#255391", background: "#dee5eb" }}>
            Falencias/Concordatas
          </Th>
        </tr>
      </Table>
      {consultaJson?.report?.completeAnnotations.bankruptcies.occurrences
        .length > 0 ? (
        <>
          <Table>
            <tr
              style={{
                backgroundColor: "#e6e6e6",
                color: "#666263",
              }}
            >
              <Th>Data </Th>
              <Th>Natureza da ação </Th>
              <Th>Avalista </Th>
              <Th>Valor </Th>
              <Th>Distrito </Th>
              <Th>Vara </Th>
              <Th>Cidade </Th>
            </tr>
            {consultaJson?.report?.completeAnnotations.bankruptcies.occurrences.map(
              (item) => {
                return (
                  <tr>
                    <Td> {item?.occurrenceDate} </Td>
                    <Td> {item?.natureDescription} </Td>
                    <Td> {item?.principal} </Td>
                    <Td> {item?.value && toCurrency(item?.value)} </Td>
                    <Td> {item?.judgementInfo.distributor} </Td>
                    <Td> {item?.judgementInfo.distributor} </Td>
                    <Td> {item?.judgementInfo.constituency} </Td>
                    <Td> {item?.city} </Td>
                  </tr>
                );
              }
            )}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.completeAnnotations.bankruptcies.total} .
                Periodo{" "}
                {
                  consultaJson?.report?.completeAnnotations.bankruptcies
                    .initialDate
                }{" "}
                a{" "}
                {
                  consultaJson?.report?.completeAnnotations.bankruptcies
                    .finalDate
                }{" "}
                valor total das ocorrencias{" "}
                {toCurrency(
                  consultaJson?.report?.completeAnnotations.bankruptcies
                    .totalValue
                )}
              </Th>
            </tr>
          </Table>
        </>
      ) : (
        <Table>
          <tr>
            <Th>NÃO CONSTAM OCORRENCIAS</Th>
          </tr>
        </Table>
      )}
      <br />
    </div>
  );
}

export default Bankruptcies;
