
import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function StolenDocumentsAlerts({consultaJson}){
    return(
        <div id="stolenDocumentsAlerts">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Documentos Roubados, Furtados ou Extraviados
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.stolenDocumentsAlerts.occurrences.length >
        0 ? (
          <>
            <Table>
              <tr
                style={{ backgroundColor: "#e6e6e6", color: "#666263" }}
              >
                <Th>Data </Th>
                <Th>COD. </Th>
                <Th>Tipo de Documento </Th>
                <Th>Número Documento </Th>
              </tr>
              {consultaJson?.report?.stolenDocumentsAlerts.occurrences.map(
                (item) => {
                  return (
                    <tr>
                      <Td> {item?.date} </Td>
                      <Td> {item?.messageNumber} </Td>
                      <Td> {item?.documentType} </Td>
                      <Td> {item?.documentNumber} </Td>
                    </tr>
                  );
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th style={{ color: "#255391", background: "#dee5eb" }}>
                  Total de ocorrências &nbsp;
                  {
                    consultaJson?.report?.stolenDocumentsAlerts
                      .totalOccurrences
                  }
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>
                {consultaJson?.report?.stolenDocumentsAlerts.message}
              </Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default StolenDocumentsAlerts