import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Tooltip} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CDataTable, CModal } from "../../../components";
import { listar_planos, listar_features } from "../../../store/actions/clientes";
import useStyles from "./styles";
import PerfectScrollBar from "react-perfect-scrollbar";
import SelectFeatures from "./components/selectFeatures";
import { CancelOutlined } from "@material-ui/icons";
import EditFeature  from './components/editFeature'
import EditPlano  from './components/editPlanos'
import { FiEdit } from 'react-icons/fi'
import {AiOutlineAppstoreAdd} from 'react-icons/ai'
import toCurrency from "../../../helpers/toCurrency";

function PlanosCliente() {
  const { id } = useParams();
  const classes = useStyles();
  const [openAddFeature, setOpenModal] = useState(false);
  const [openEditFeature, setOpenEdit] = useState(false);
  const [openEditPlano, setOpenEditPlano] = useState(false);
  const dispatch = useDispatch();
  const { planos, features } = useSelector((state) => state.clientes);
  const [consultaId, setConsultaId] = useState()
  const [featureID, setFeatureID ] = useState()
  const [planoID, setPlanoID ] = useState()

  useEffect(() => {
    dispatch(listar_planos(id));

  }, []);



  function CloseModal(){
    setOpenModal(false)
    dispatch(listar_planos(id));
  }

  function CloseEditModal(){
    setOpenEdit(false)
    dispatch(listar_planos(id));
  }


  function AddFeature(data){
    setOpenModal(true)
    setConsultaId(data)
  }


  function EditarFeature(data){
    setOpenEdit(true)
    setFeatureID(data)
  }


  function EditarPlano(data){
    setOpenEditPlano(true)
    setPlanoID(data)
  }

  
  function CloseEditPlanoModal(){
    setOpenEditPlano(false)
    dispatch(listar_planos(id));
  }
  
  return (
    <div className={classes.clientes}>
      <div className={classes.clientesContainer}>
        <h4>Planos</h4>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <PerfectScrollBar>
              <CDataTable
                columns={[ 
                  { title: "Nome da Consulta", field: "nome", cellStyle: { width: "60%", paddingLeft: "0.5em" }},
                  { title: "Valor da consulta",  cellStyle: { width: "30%",}, render: (row) => toCurrency(row.valor_consulta)},
                 
                  { title: "Ações", cellStyle: { textAlign:"center"}, render: (row) => 
                  <>
                    <Tooltip title="Editar Consulta">
                      <button style={{backgroundColor: "transparent", border: 'none', cursor: "pointer"}} onClick={() => EditarPlano(row)}> 
                        <FiEdit color="#173f74" size={15}/>
                      </button>
                    </Tooltip>
                    <Tooltip title="Adicionar Feature">
                      <button style={{backgroundColor: "transparent", border: 'none', cursor: "pointer"}}  onClick={() => AddFeature(row)}> 
                        <AiOutlineAppstoreAdd color="#173f74" size={16}/>  
                      </button>
                    </Tooltip>
                  </>  
                  }
                ]}
                data={planos}
                detailPanel={[ 
                  (row) => ({
                    tooltip: "Listar Features",
                    render: (row) => {
                      if (row) {
                        return (
                          <div style={{ padding: "1em" }}>
                                 <table style={{width: "100%", textAlign :'left'}}>
                                  {row.features.length > 0 ? 
                                     <tr>
                                     <th> NOME FEATURE</th>
                                     <th> DESCRICAO</th>
                                     <th> VALOR</th>
                                     <th> EDITAR VALOR</th>
                                   </tr>
                                      : 
                                  'A consulta ainda não possui features cadastradas'
                                  
                                  }
                                     
                            {row.features.map((item, index) => {
                              return(
                                <>
                                  <tr key={index.id}>
                                    <td>{item.nome}</td>
                                    <td>{item.descricao}</td>
                                    <td>{toCurrency(item.valor)}</td>
                                    <td>
                                      <Tooltip title="Editar Consulta">
                                      <button style={{backgroundColor: "transparent", border: 'none', cursor: "pointer"}} onClick={() => EditarFeature(item)}> 
                                        <FiEdit color="#173f74" size={15}/>
                                      </button>
                                     </Tooltip>
                                      {/* <button onClick={() => EditarFeature(item)}> 
                                        Editar 
                                      </button> */}
                                    </td>
                            </tr>
                                </>
                              )
                            })}
                            </table>
                          </div>
                        );
                      }
                    },
                  }),
                ]}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                }}
              />
            </PerfectScrollBar>
          </Grid>
        </Grid>
        <CModal open={openAddFeature} style={{padding: "3em"}} modalWidth="40%" modalHeight="30%">
         
          <SelectFeatures planoId={consultaId}/>
          <br/>
          <div style={{ position: 'absolute', bottom: 0, width: "85%" }}>
          <Divider />
          <div style={{ textAlign: "right"}}>
          <Button onClick={() => CloseModal()} style={{ color: "#890f0f" }}>
            <CancelOutlined style={{ width: "16px" }} />
            &nbsp; Fechar
          </Button>
          </div>
          <br/>
          </div>
          

        </CModal>
      <CModal open={openEditFeature} style={{padding: "3em"}} modalWidth="20%" modalHeight="30%">
            Feature: {featureID ? featureID.descricao : ''}
          <EditFeature data={featureID} close={() => CloseEditModal()} />
          <div style={{ position: 'absolute', bottom: 0, width: "85%" }}>
          <Divider />
          <div style={{ textAlign: "right"}}>
          <Button onClick={() => CloseEditModal()} style={{ color: "#890f0f" }}>
            <CancelOutlined style={{ width: "16px" }} />
            &nbsp; Fechar
          </Button>
          </div>
          <br/>
          </div>
      </CModal>


      <CModal open={openEditPlano} style={{padding: "3em"}} modalWidth="20%" modalHeight="30%">
           Plano: {planoID ? planoID.nome : ''}
          <EditPlano data={planoID} close={() => CloseEditPlanoModal()} />
          <div style={{ position: 'absolute', bottom: 0, width: "75%" }}>
          <Divider />
          <div style={{ textAlign: "right"}}>
          <Button onClick={() => CloseEditPlanoModal()} style={{ color: "#890f0f" }}>
            <CancelOutlined style={{ width: "16px" }} />
            &nbsp; Fechar
          </Button>
          </div>
          <br/>
          </div>
      </CModal>

      </div>
    </div>
  );
}

export default PlanosCliente;
