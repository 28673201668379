import queryString from "query-string";
import store from "../index";
import * as types from "../types/consultas";
import http from "./../../services/api";

export const listar_consultas = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });

  return (dispatch) => {
    dispatch({ type: types.CONSULTAS_LOADING, payload: true });

    http.get(`/api/v1/consultas?${qry}`).then(
      (response) => {
        const { data } = response.data;
        const {
          total,
          currentPage: page,
          perPage: per_page,
        } = response.data.pagination;

        dispatch([
          { type: types.CONSULTAS_LOAD, payload: data },
          { type: types.CONSULTAS_PAGE, payload: page },
          { type: types.CONSULTAS_PERPAGE, payload: per_page },
          { type: types.CONSULTAS_TOTAL, payload: total },
          { type: types.CONSULTAS_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CONSULTAS_LOADING, payload: false });
      }
    );
  };
};


export const ActionConsultaJson = (uuid, cnpj) => {
  

  return (dispatch) => {
    dispatch({ type: types.CONSULTAS_JSON_LOADING, payload: true });

    http.get(`/api/v1/consultas/query/${uuid}/${cnpj}`).then(
      (response) => {
        const data = response.data;  

        dispatch([
          { type: types.SET_CONSULTAS_JSON, payload: data },
          { type: types.CONSULTAS_JSON_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CONSULTAS_JSON_LOADING, payload: false });
      }
    );
  };
};

export const efetuar_consulta = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: true });
    http.post(`/api/v1/consultas/query`, dados).then(
      ({ data }) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        reject();
      }
    );
  });
};

export const efetuar_upload = async (file, id) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONSULTAS_LOADING, payload: true });
    http.put(`/api/v1/consultas/${id}`, file).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CONSULTAS_LOADING, payload: false });
        reject();
      }
    );
  });
};

export const cancelar_consulta = async (id) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONSULTAS_LOADING, payload: true });
    http.delete(`/api/v1/consultas/${id}`).then(
      ({ data }) => {
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CONSULTAS_LOADING, payload: false });
        reject();
      }
    );
  });
};

export function DownloadArquivo(tipo_consulta, uudi) {
  return new Promise((resolve, reject) => {
    http
      .get(`/api/v1/consultas/anexo/${tipo_consulta}/${uudi}`, { responseType: "blob" })
      .then((response) => {
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(pdfBlob);
        }

        const data = window.URL.createObjectURL(pdfBlob);
        window.open(data, "_blank");
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const listar_tipos_planos = (callback = (data) => {}) => {
  return (dispatch) => {
    dispatch({ type: types.CONSULTAS_LOADING, payload: true });

    http.get(`/api/v1/consultas/listar`).then(
      (response) => {
        const  data  = response.data;

        dispatch([
          { type: types.CONSULTAS_PLANOS, payload: data },
          { type: types.CONSULTAS_LOADING, payload: false },
        ]);
        callback(data)
      },
      (error) => {
        dispatch({ type: types.CONSULTAS_LOADING, payload: false });
      }
    );
  };
};



export const listarPrecosClientesActions = (cliente_id) => {
  return (dispatch) => {
    http.get(`/api/v1/clientes/planos/${cliente_id}`).then(
      (response) => {
        const  data  = response.data;
        dispatch([
          { type: types.CONSULTAS_LISTAR_PRECOS_CLIENTE, payload: data },
          { type: types.CONSULTAS_LOADING, payload: false },
        ]);
      },
      (error) => {
        dispatch({ type: types.CONSULTAS_LOADING, payload: false });
      }
    );
    
  }

}


export const atualizarVendaEfetuada = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: true });
    http.put(`/api/v1/consultas/venda/${dados}`).then(
      ({ data }) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        reject();
      }
    );
  });
};



export const CancelarConsultaEfetuadaAction = async (id) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: true });
    http.delete(`/api/v1/consultas/${id}`).then(
      ({ data }) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.CONSULTA_EFETUADA_LOADING, payload: false });
        reject();
      }
    );
  });
};