import * as types from "../types/validadoc";
import http from "./../../services/api";

export function getTokenValida() {
    return (dispatch) => {
      dispatch({ type: types.VALIDA_DOC_LOAD, payload: true });
  
      http.get(`/api/v1/validadoc/auth`).then(
        (response) => {
          dispatch([{ type: types.VALIDA_DOC_TOKEN, payload: response.data.token }]);
        },
        (error) => {
          dispatch({ type: types.VALIDA_DOC_LOAD, payload: false });
        }
      );
    };
  }
  


  export function retornoConsultaEfetuada(token, documento) {
    return new Promise((resolve, reject)  => {  
      http.post(`/api/v1/validadoc/consulta`, { document: documento, token: token}).then(
        (response) => {        
          resolve(response.data)
        },
        (error) => {
          reject(error.response.data)
        }
      );
    });
  }
  