import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function ParticipacaoEmFalencia({ consultaJson }) {
  return (
    <>
      {consultaJson?.report?.bankruptyParticipations?.occurrences?.length >
        0 && (
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>
                {" "}
                <h3> Participação em Falências</h3>
              </Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Data</Th>
              <Th>Documento</Th>
              <Th>Razão Social</Th>
              <Th>Qualificação Social</Th>
              <Th>Vara Cívil</Th>
              <Th>Declaração de Falência</Th>
            </tr>
            {consultaJson?.report?.bankruptyParticipations?.occurrences?.map(
              (item) => {
                return (
                  <tr>
                    <Td>{item?.date}</Td>
                    <Td>{item?.document}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.qualification}</Td>
                    <Td>{item?.civilConstituency}</Td>
                    <Td>{item?.declaredBankruptcy}</Td>
                  </tr>
                );
              }
            )}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.bankruptyParticipations?.totalOccurrences}
                . Periodo{" "}
                {
                  consultaJson?.report?.bankruptyParticipations?.oldestOccurrenceDate
                }{" "}
                a{" "}
                {
                  consultaJson?.report?.bankruptyParticipations?.newestOccurrenceDate
                }{" "}
                valor total das ocorrencias{" "}
                {consultaJson?.report?.bankruptyParticipations?.totalValue &&
                  toCurrency(
                    consultaJson?.report?.bankruptyParticipations?.totalValue
                  )}{" "}
              </Th>
            </tr>
          </Table>
          <br/>
        </>
      )}
    </>
  );
}

export default ParticipacaoEmFalencia;
