import { Grid } from '@material-ui/core';
import React from 'react';
import { BsGraphUp, BsSpeedometer2 } from 'react-icons/bs';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { CardContainer, H3 } from '../style';
import { useState } from 'react';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div style={{padding: 10, background: '#ffffff'}} >
                <p className="label">{`Data: ${data.mes}`}</p>
                <p className="value">{`Score: ${data.score == 0 ? 'Não calculado' : data.score}`}</p>
            </div>
        );
    }

    return null;
};


function BarChartComponent({ data }) {

    const [eventPress, setEventPress] = useState(false)

window.addEventListener('beforeprint', function() {
  setEventPress(true)
  console.log('Impressão acionada');
});

window.addEventListener('afterprint', function() {
  setEventPress(false)
  console.log('Impressão acionada');
});



    return (
        <BarChart
            width={eventPress ? 600 : 900}
            height={150}
            data={data}
            margin={{
                top: 5,
                bottom: 5,
            }}
            barSize={30}
        >
            <XAxis
                dataKey="mes"
                scale="point"
                padding={{ left: 20, right: 20 }}
                fontSize={12}
            />
            <YAxis fontSize={14} />
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="score" fill="#30876b" />
        </BarChart>
    );
}

function HistoricoScoreConexo({ data }) {

    function adicionarMeses(array) {
        const dataAtual = new Date(); 
        const meses = []; 
      
   
        for (let i = 0; i < 12; i++) {
          const mes = dataAtual.getMonth(); // Obtém o mês atual (0-11)
          const ano = dataAtual.getFullYear(); // Obtém o ano atual
          const mesTexto = (mes < 9 ? "0" : "") + (mes + 1); // Formata o mês para 'MM'
          const mesAno = `${mesTexto}/${ano.toString().substr(-2)}`;
      
          if (array && !array.some(item => item.mes === mesAno)) {
            meses.unshift({
              mes: mesAno,
              score: "0"
            });
          }
      
          dataAtual.setMonth(mes - 1); // Define o mês para o anterior
        }
      
      
        const resultado = meses.concat(array);
      
        
        resultado.sort((a, b) => {
          const [mesA, anoA] = a.mes.split('/');
          const [mesB, anoB] = b.mes.split('/');
          return new Date(anoB, mesB - 1) - new Date(anoA, mesA - 1);
        });
      
        return resultado;
      }      
  
      
      const resultado = adicionarMeses(data?.historico_score?.media_score_por_mes);
   

    return (
        <>
        {data?.historico_score && 
        <div style={{marginTop: 15}}>
            <div
                style={{
                    background: '#F6F7FA',
                    borderRadius: 7,
                    padding: 15
                }}
            >
                <h4>Histórico de Score <BsSpeedometer2 color='#30876b'/></h4>
                <h4>Evolução nos últimos 12 Meses <BsGraphUp /></h4>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                        <CardContainer >
                            Média Score    <h5 style={{ margin: 0 }}>Ult. 12 meses</h5>
                            <H3> {data?.historico_score?.media_geral_score}
                            </H3>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <CardContainer>
                            Maior Score
                            <h5 style={{ margin: 0 }}>Ult. 12 meses</h5>
                            <H3>{data?.historico_score?.maior_score?.score} </H3>
                            <h4 style={{ margin: 0 }}>{data?.historico_score?.maior_score?.mes && <> Data: {data?.historico_score?.maior_score?.mes} </>}</h4>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <CardContainer>
                            Menor Score <h5 style={{ margin: 0 }}>Ult. 12 meses </h5>
                            <H3>{data?.historico_score?.menor_score?.score}  </H3>
                            <h4 style={{ margin: 0 }}>{data?.historico_score?.menor_score?.mes && <>Data: {data?.historico_score?.menor_score?.mes}</>}</h4>
                        </CardContainer>
                    </Grid>

                </Grid>
                <div style={{ marginTop: 15 }}>
                    <BarChartComponent
                        data={resultado}
                    />
                </div>
            </div>
        </div>
         }
         </>
    );
}

export default HistoricoScoreConexo;