import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  relatorioFatura,
  resumo_extrato_usuario,
  resumo_extrato_usuario_mes_anterior,
} from "../../store/actions/extrato";
import toCurrency from "./../../helpers/toCurrency";
import useStyles from "./styles";
import { useParams } from "react-router";

function ConsumoFaturaAdministrador() {

  const { id, mes_filtro, ano_filtro } = useParams()

  const classes = useStyles();
  const dispatch = useDispatch();
  const { permissao } = useSelector((state) => state.usuario);
  const { resumo_usuario, resumo_usuario_mes_anterior, faturaDetalhada, assinatura } =
    useSelector((state) => state.extrato);
  const [data, setData] = useState("P");

  const date = new Date();
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1;
  const mes_anterior = date.getMonth();

  useEffect(() => {
    dispatch(resumo_extrato_usuario({ mes: mes_filtro, ano: ano_filtro }));
    dispatch(
      resumo_extrato_usuario_mes_anterior({ mes: mes_filtro, ano: ano_filtro })
    );
    dispatch(relatorioFatura({ mes: mes_filtro, ano: ano_filtro, clienteId: id }));
  }, []);


  function formatarTexto(texto) {
    if (texto.length === 0) {
      return texto;
    }
    return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }

  return (
    <div>    

      <div
        style={{
          fontSize: 14,
          width: "80%",
          padding: 15,
          border: "1px solid gray",
          borderRadius: 7,
          margin: "auto",
          marginBottom: 25,
          marginTop: 15,  fontFamily: "Poppins"
        }}
      >
        <h4>
          {" "}
          Relatório de Consultas {mes_filtro}/{ano_filtro}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} style={{ textAlign: "left" }}></Grid>
          <Grid item xs={3} sm={3}>
            QUANTIDADE
          </Grid>
          <Grid item xs={3} sm={3}>
            Valor Unitário
          </Grid>
          <Grid item xs={3} sm={3}>
            Valor Total
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} sm={4} style={{ textAlign: "left" }}>
            <span style={{ color: "#1d4f91" }}>◉</span>{" "}
            <b> Assinatura Plataforma</b>
          </Grid>
          <Grid item xs={2} sm={2}>
            1
          </Grid>
          <Grid item xs={3} sm={3}>-</Grid>
          <Grid item xs={3} sm={3}>
            {toCurrency(assinatura)}
          </Grid>
        </Grid>

        {faturaDetalhada.map((item, index) => {
          return (
            <div key={index.usuario_id}>
              <Divider />
              <div
                style={{
                  textAlign: "left",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginTop: 15,
                  marginBottom: 10,
                }}
              >
                {item.nome_usuario}
              </div>
              <div style={{ fontSize: 12, textAlign: "left" }}>Consultas :</div>
              {item?.tipos_consultas.map((consultas, consultasIndex) => {
                return (
                  <div key={consultasIndex.id_tipo_consulta}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={4} style={{ textAlign: "left" }}>
                      <span style={{ color: '#1d4f91'}}>◉</span> <b> {consultas.nome}</b>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        {consultas.quantidade}
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        {toCurrency(consultas.valor)}
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        {toCurrency(consultas.quantidade * +consultas.valor)}
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {consultas?.features_adicionais.length > 0 && (
                        <div style={{ fontSize: 12, marginLeft: 15 }}>
                          Consultas adicionais:
                        </div>
                      )}
                    </div>
                    {consultas?.features_adicionais.map(
                      (features, featuresIdx) => {
                        return (
                          <div key={featuresIdx.idFeature}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                style={{ textAlign: "left" }}
                              >
                                <div style={{ margin: "5px 25px" }}>
                                ○ {features.nome}
                                </div>
                              </Grid>
                              <Grid item xs={2} sm={2}>
                                {features.quantidade}
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                {toCurrency(features.valor)}
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                {toCurrency(
                                  features.quantidade * +features.valor
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}


export default ConsumoFaturaAdministrador