import React from "react";
import NumberFormat from "react-number-format";

function StringMaskedInput({
  mask,
  onChange,
  value,
  label,
  required,
  customInput,
  ...props
}) {
  return (
    <NumberFormat
      mask={mask}
      label={label}
      required={required}
      value={value || ""}
      onChange={onChange}
      customInput={customInput}
      {...props}
    />
  );
}

export default StringMaskedInput;