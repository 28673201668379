


import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function JudicialActions({consultaJson}){
    return(
        <div id="acoesJudiciais">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Ações Judiciais
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.completeAnnotations.judicialActions
          .occurrences.length > 0 ? (
          <>
            <Table>
              <tr
                style={{
                  backgroundColor: "#e6e6e6",
                  color: "#666263",
                }}
              >
                <Th>Data </Th>
                <Th>Natureza da ação </Th>
                <Th>Avalista </Th>
                <Th>Valor </Th>
                <Th>Distrito </Th>
                <Th>Vara </Th>
                <Th>Cidade </Th>
              </tr>
              {consultaJson?.report?.completeAnnotations.judicialActions.occurrences.map(
                (item) => {
                  return(
                  <tr>
                    <Td> {item?.occurrenceDate} </Td>
                    <Td> {item?.natureDescription} </Td>
                    <Td> {item?.principal} </Td>
                    <Td>
                      {" "}
                      {item?.value && toCurrency(item?.value)}{" "}
                    </Td>
                    <Td> {item?.judgementInfo.distributor} </Td>
                    <Td> {item?.judgementInfo.constituency} </Td>
                    <Td> {item?.city} </Td>
                  </tr>
                  )
                }
              )}
            </Table>
            <Table>
              <tr>
                <Th
                  style={{ color: "#255391", background: "#dee5eb" }}
                >
                  Total de ocorrências{" "}
                  {
                    consultaJson?.report?.completeAnnotations
                      .judicialActions.total
                  }{" "}
                  . Periodo{" "}
                  {
                    consultaJson?.report?.completeAnnotations
                      .judicialActions.initialDate
                  }{" "}
                  a{" "}
                  {
                    consultaJson?.report?.completeAnnotations
                      .judicialActions.finalDate
                  }{" "}
                  valor total das ocorrencias{" "}
                  {consultaJson?.report?.completeAnnotations
                    .judicialActions.totalValue &&
                    toCurrency(
                      consultaJson?.report?.completeAnnotations
                        .judicialActions.totalValue
                    )}
                </Th>
              </tr>
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONSTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
      <br />
      </div>
    )
}

export default JudicialActions