

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'


function ResumoConsulta({consultaJson}){
    return(
        <div id="resumoConsulta">
        <Table>
          <tr>
            <Th> Resumo da Consulta</Th>
          </tr>
        </Table>
        <Table>
          <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
            <Th>CPF</Th>
            <Th>NOME</Th>
            <Th>NOME DA MAE</Th>
            <Th>DATA NASCIMENTO</Th>
          </tr>
          <tr>
            <Td> {consultaJson?.meta?.document} </Td>
            <Td> {consultaJson?.report?.confirmei.name} </Td>
            <Td> {consultaJson?.report?.confirmei.mothersName} </Td>
            <Td> {consultaJson?.report?.confirmei.birthDate} </Td>
          </tr>
        </Table>
      <br />
      </div>
    )
}

export default ResumoConsulta