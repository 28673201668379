import { Box, BoxConsultas, Container } from "./styles";

function MainSuppliersRelationshipsByPeriod({ data }) {

  function formatarTexto(texto) {
    if (texto.length === 0) {
      return texto;
    }
    return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }
  

  return (
    <>
      {data && (
        <Container>
          <Box>
            <h4>Relacionamento com Fornecedores</h4>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {data?.mainSuppliersRelationshipsByPeriod ? (
                <>
                  {data?.mainSuppliersRelationshipsByPeriod?.map(
                    (item, index) => {
                      return (
                        <BoxConsultas style={{ margin: 3 }}>
                          <b style={{ color: "#245782" }}> {item.suppliers}</b>
                          <br />
                          {item.periodDescription}
                        </BoxConsultas>
                      );
                    }
                  )}
                </>
              ) : (
                <>{formatarTexto(data?.message)}</>
              )}
            </div>
          </Box>
        </Container>
      )}
    </>
  );
}

export default MainSuppliersRelationshipsByPeriod;
