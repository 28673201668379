

import { toCurrency } from '../../../helpers'
import { Td, Table, Th } from '../style'

function PartnerParticipations({consultaJson}){
    return(
        <div id="partnerParticipations">
        <Table>
          <tr>
            <Th style={{ color: "#255391", background: "#dee5eb" }}>
              Participação Societaria
            </Th>
          </tr>
        </Table>
        {consultaJson?.report?.partnerParticipations?.occurrences.length > 0 ? (
          <>
            <Table>
              <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
                <Th>Empresa </Th>
                <Th>CNPJ </Th>
                <Th>Data de Abertura </Th>
                <Th>Participacao </Th>
                <Th>UF </Th>
                <Th>Possui Restrição?</Th>
              </tr>
              {consultaJson?.report?.partnerParticipations?.occurrences.map((item) => {
                return (
                  <tr>
                    <Td> {item?.name} </Td>
                    <Td> {item?.document} </Td>
                    <Td> {item?.dateInitiated} </Td>
                    <Td> {item?.percentage} </Td>
                    <Td> {item?.state} </Td>
                    <Td> {item?.restriction ? 'SIM' : 'NÃO'} </Td>
                  </tr>
                );
              })}
            </Table>
          </>
        ) : (
          <Table>
            <tr>
              <Th>NÃO CONTAM OCORRENCIAS</Th>
            </tr>
          </Table>
        )}
        <br />
      </div>
    )
}

export default PartnerParticipations