import styled from "@emotion/styled";

export const Th = styled.th`
  text-align: left;
  padding: 8px;
  font-weight: bold;
  font-size: 14px;
  @media print {
    margin: 0;
    font-size: 10px;
  }
`;
export const Td = styled.td`
  text-align: left;
  padding: 8px;
  font-size: 14px;
  @media print {
    margin: 0;
    font-size: 10px;
  }
`;
export const Table = styled.table`
  font-family: "Roboto", sans-serif;
  border-collapse: collapse;
  width: 95%;
  margin: auto;
  color: #1c1c1c;
  @media print {
    margin: 0;
    font-size: 10px;
  }
`;

export const B = styled.b`
  font-weight: bold;
`;
export const Tr = styled.tr`
  font-weight: bold;
  background: #e9ecf3;
  border-bottom: 2px solid #B6BCC1
`;

export const Container = styled.div`
  color: #000;
  font-family: Arial;
  font-size: 16px;
  width: 95%;
  margin: auto;
  padding: 20px;
  background: #e9ecf3;
  border-radius: 15px;
  margin-bottom: 10px;
  @media print {
    margin: 0;
    font-size: 10px;
    width: 210mm;
  margin-bottom: 5px;
  }
`;

export const Box = styled.div`
  color: #000;
  font-family: Arial;
  font-size: 16px;
  width: 100%;
  margin: auto;
  background: #f6f7fa;
`;


export const BoxConsultas = styled.div`
  color: gray;
  font-family: Arial;
  font-size: 16px;
  width: 90%;
  margin: auto;
  background: #ffffff;
  border: 1px solid gray;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
`;
