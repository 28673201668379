


import { toCurrency } from "../../../../helpers";
import { Box, Container, Table, Td, Th, Tr } from "./styles";

function Resumo({ data }) {
  return (
    <>
      {data?.summary && (
        <Container>
          <Box>
          <h4>Resumo</h4>
          <Table>
            <Tr>
            <Th>Quantidade</Th>
              <Th>Discriminação</Th>
              <Th>Período</Th>
              <Th>Valor</Th>
              <Th>Origem</Th>
              <Th>Praça</Th>
            </Tr>
            {data?.summary.map((item) => {
              return (
                <tr>
                  <Td>{item.quantity}</Td>
                  <Td>{item.group}</Td>
                  <Td>{item.initialMonthDescription}/{item.initialYear} - {item.finalMonthDescription}/{item.finalYear}</Td>
                  <Td>{toCurrency(item.value)}</Td>                 
                  <Td>{item.origin}</Td>
                  <Td>{item.agency}</Td>
                </tr>
              );
            })}
          </Table>
      </Box>
    </Container>
      )}
      </>
  );
}

export default Resumo;
