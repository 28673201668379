import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import {
  responseInterceptor,
  requestInterceptor,
} from "./services/interceptors";

import store from "./store";
import ScreenSize from "./screenSize";
import MaintenancePage from "./pages/manutencao";

responseInterceptor(store);
requestInterceptor(store);

const largura = window.screen.width;

const isUnderMaintenance = true; // Altere para false quando o site não estiver em manutenção



const App = () => {
  const routing = useRoutes(routes);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
      {isUnderMaintenance ? <MaintenancePage /> : <> {largura < 1058 ? <ScreenSize /> : routing} </>}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
