import { toCurrency } from "../../../helpers";
import { Td, Table, Th } from "../style";

function PendenciasComerciaisPefin({ consultaJson }) {
  return (
    <>
      <Table>
        <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
          <Th> Detalhe</Th>
        </tr>
      </Table>

      {consultaJson?.report?.paymentPendencies?.market?.occurrences?.length > 0 &&
        <>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th> <h3>Pendências Comerciais (PEFIN) </h3></Th>
            </tr>
          </Table>
          <Table>
            <tr style={{ backgroundColor: "#e6e6e6", color: "#666263" }}>
              <Th>Contrato </Th>
              <Th>Modalidade </Th>
              <Th>Empresa </Th>
              <Th>Data. </Th>
              <Th>Valor </Th>
              <Th>Avalista </Th>
              <Th>Local </Th>
            </tr>
            {consultaJson?.report?.paymentPendencies?.market?.occurrences?.map(
              (item) => {
                return (
                  <tr>
                    <Td> {item?.contractNumber} </Td>
                    <Td> {item?.modality} </Td>
                    <Td> {item?.company} </Td>
                    <Td> {item?.date} </Td>
                    <Td> {item?.value && toCurrency(item?.value)} </Td>
                    <Td> {item?.isGuarantor ? "SIM" : "NÃO"} </Td>
                    <Td> - </Td>
                  </tr>
                );
              }
            )}
          </Table>
          <Table>
            <tr>
              <Th style={{ color: "#255391", background: "#dee5eb" }}>
                Total de ocorrências{" "}
                {consultaJson?.report?.paymentPendencies?.market?.totalOccurrences}. Periodo{" "}
                {
                  consultaJson?.report?.paymentPendencies?.market?.oldestOccurrenceDate
                }{" "}
                a{" "}
                {
                  consultaJson?.report?.paymentPendencies?.market?.newestOccurrenceDate
                }{" "}
                valor total das ocorrencias{" "}
                {consultaJson?.report?.paymentPendencies?.market?.totalValue &&
                  toCurrency(
                    consultaJson?.report?.paymentPendencies?.market?.totalValue
                  )}{" "}
              </Th>
            </tr>
          </Table>
          <br/>
        </>
      }
    </>
  );
}

export default PendenciasComerciaisPefin;
