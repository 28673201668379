import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Nav: {
    backgroundColor: "#1d4f91",

    position: "relative",
    ["@media (max-width:230mm)"]: {
      display: 'none'
    },
   
  },
  logo: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: '0.5em',
    "& img": {
       marginTop: '0.5em',
    },
  },
  NavBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& Button": {
      color: "#fff",
      right: "2em",
      marginTop: "center",
    },
  },
  Grid: {
    width: "80%",
    margin: "auto",
    ["@media (max-width:1580px)"]: {
      width: "100%",
    },
  },
  grow: {
    width: "250px",
    height: "120px",
    "& h1": {
      color: "#AF1685",
      fontSize: "15px",
      padding: "5px 10px",
      marginTop: "-0.1em",
      borderBottom: "1px solid #f1f1f1",
    },
    "& a": {
      textDecoration: "none",
      color: "#010100",
      "&:hover": {
        color: "#1d4f91",
        textDecoration: "none",
      },
    },
  },
}));

export default useStyles;
