import styled from "@emotion/styled";

export const Th = styled.th`
  border: 1px solid #afacac;
  text-align: left;
  padding: 8px;
  font-weight: bold;
  font-size: 12px;
  @media print {
    width: 210mm; /* Largura da página A4 em milímetros */
    margin: 0;
    font-size: 10px;
  }
`;
export const Td = styled.td`
  border: 1px solid #afacac;
  text-align: left;
  padding: 8px;
  font-size: 12px;
  @media print {
    width: 210mm; /* Largura da página A4 em milímetros */
    margin: 0;
    font-size: 10px;
  }
`;
export const Table = styled.table`
font-family: 'Roboto', sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin: auto;
  color: #1c1c1c;
  @media print {
    width: 210mm; /* Largura da página A4 em milímetros */
    margin: 0;
    height: auto;
    font-size: 12px;
  }
`;

export const B = styled.b`
  font-weight: bold;
`;

export const Container = styled.div`
  color: #000;
  font-family: Arial;
  font-size: 16px;
  width: 80%;
  margin: auto;
  @media print {
    width: 210mm; /* Largura da página A4 em milímetros */
    margin: 0;
    padding: 20mm; /* Margem de 20mm para dentro do papel */
    box-sizing: border-box;
    font-size: 12px;
  }
`;

export const CardContainer = styled.div`
  color: #000;
  font-family: Arial;
  height: 80px;
  font-size: 16px;
  width: 90%;
  margin: auto;
  padding: 5px;
  box-shadow: 0px 0px 14px -6px rgba(48,135,107,0.69);
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  @media print {
    width: 40mm; /* Largura da página A4 em milímetros */
    margin: 0;
    height: auto;
    height: 20mm;
    padding: 0mm; /* Margem de 20mm para dentro do papel */
    box-sizing: border-box;
    font-size: 12px;
  }
  
`;


export const H3 = styled.h3`
  margin: 0; 
  color: #30876b; 
  font-size: 16px; 
  @media print { 
    font-size: 12px;
  }
`;


export const ButtonSave = styled.button`
  padding: 15px;
  background: transparent;
  border: 1px solid #1d4f91;
  border-radius: 8px;
  margin-right: 25px;
  margin-top: 25px;
  cursor: pointer;
  &:hover {
    background: #1d4f91;
    color: #ffffff;
    transition: 0.3s ease-in-out;
  }
`;
