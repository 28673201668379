import styled from "@emotion/styled";
import dayjs from "dayjs";

export function SyntheticRegistration({ data }) {
  const Box = styled.th`
    border: 1px solid #afacac;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
    margin-left: 15px;
    border-radius: 7px;
  `;

  const Barra = styled.div`
    width: 70px;
    border-radius: 2px;
    height: 12px;
    margin-right: 5px;
  `;

  const LineInfo = styled.div`
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    @media print {
      font-size: 9px;
    }
  `;

  const H4 = styled.h4`
    margin: 0;
    background-color: #e9ecf3;
    color: #5b6475;
    border-bottom: 2px solid #b6bcc1;
    font-size: 16px;
    @media print {
      font-size: 11px;
    }
  `;

  return (
    <>
      {data.report?.registerInformation && (
        <div style={{ marginTop: 20 }}>
          <b>Informações Cadastrais da Empresa </b>
          <div
            style={{ backgroundColor: "#e9ecf3", padding: 15, borderRadius: 8 }}
          >
            Dados Cadastrais
            <div
              style={{
                width: "90%",
                margin: "auto",
                backgroundColor: "#f6f7fa",
                padding: 15,
                borderRadius: 8,
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                }}
              >
                <LineInfo>
                  <H4>CNPJ</H4>
                  {data.report?.registerInformation?.accounting?.editedDocument}
                </LineInfo>
                <LineInfo>
                  <H4>Situação CNPJ</H4>
                  {
                    data.report?.registerInformation?.companyData?.registrationStatusDescription
                  }
                </LineInfo>
              </div>
              <LineInfo>
                <H4>Razão Social</H4>
                {data.report.registerInformation.identification?.companyName}
              </LineInfo>
              <LineInfo>
                <H4>Nome Fantasia</H4>
                {data.report.registerInformation.identification?.fantasyName}
              </LineInfo>
              <LineInfo>
                <H4>Endereço Completo</H4>
                {
                  data.report.registerInformation?.address?.fullCompanyAddress
                } | {data.report?.registerInformation?.localization?.city} -
                {data.report?.registerInformation?.localization?.uf} / CEP:{" "}
                {data.report?.registerInformation?.localization?.zipcode}
              </LineInfo>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                }}
              >
                <LineInfo>
                  <H4>Telefone</H4>
                  {data?.report?.registerInformation?.localization?.phone}
                </LineInfo>
                <LineInfo>
                  <H4>Email</H4>
                  {data.report?.registerInformation?.localization?.email}
                </LineInfo>
              </div>
              <LineInfo>
                <H4>Tipo de Sociedade</H4>
                {
                  data.report?.registerInformation?.identification?.partnershipTypeDescription
                }
              </LineInfo>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                }}
              >
                <LineInfo>
                  <H4>Registro</H4>
                  {
                    data?.report?.registerInformation?.accounting?.lastRegistrationNumber
                  }
                </LineInfo>

                <LineInfo>
                  <H4>Registro realizado em </H4>
                  {
                    data.report?.registerInformation?.accounting?.lastRegistrationDate
                  }
                </LineInfo>

                <LineInfo>
                  <H4>NIRE</H4>
                  {
                    data?.report?.registerInformation?.identification?.registrationNumber
                  }
                </LineInfo>
              </div>
              <LineInfo>
                <H4>Antecessoras </H4>
                {data.report?.registerInformation?.predecessors
                  ? data.report?.registerInformation?.predecessors?.map((item) => {
                      return (
                        <div>
                          {item?.companyName} - até{" "}
                          {item.reasonDate &&
                            dayjs(item.reasonDate).format("DD/MM/YYYY")}
                        </div>
                      );
                    })
                  : ""}
              </LineInfo>
              <LineInfo>
                <H4>Fundação </H4>
                {data.report?.registerInformation?.activity?.foundationDate}
              </LineInfo>
              <LineInfo>
                <H4>Número de Funcionários </H4>
                {data.report?.registerInformation?.activity?.employeesQuantity}
              </LineInfo>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                }}
              >
                <LineInfo>
                  <H4>Opção Tributária</H4>
                  {data.report?.registerInformation?.identification?.taxOption}
                </LineInfo>

                <LineInfo>
                  <H4>Inscrição Estadual </H4>
                  {data.report?.stateRegistrations?.registrations?.map(
                    (item) => item.registrationNumber
                  )}
                </LineInfo>
              </div>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                }}
              >
                <LineInfo>
                  <H4>Código de Atividade Serasa </H4>
                  {data.report.registerInformation?.activity?.fullSerasaCode}
                </LineInfo>

                <LineInfo>
                  <H4>Importação sobre Compras</H4>
                  {data.report.registerInformation?.activity?.purchasePercentage}
                </LineInfo>

                <LineInfo>
                  <H4>Exportação sobre Vendas </H4>
                  {data.report.registerInformation?.activity?.salesPercentage}
                </LineInfo>
              </div>

              <LineInfo>
                <H4>CNAE </H4>
                {data.report?.registerInformation?.activity?.cnae}
              </LineInfo>
              <LineInfo>
                <H4>Consultar Filiais </H4>
                {data.report?.registerInformation?.activity?.branchesQuantity}
              </LineInfo>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
