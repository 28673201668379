
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, LabelList } from 'recharts';


export default function BudgetPieChart({value, data}){ 

  const RADIAN = Math.PI / 180;  

  
  const cx = 120;
  const cy = 170;
  const iR = 40;
  const oR = 65;
  // const value = 700;
  
  
  const needle = (value, data, cx, cy, iR, oR, color) => {
    const maxTotal = data.map((item) => item.value).reduce((p, v) => p + v)
    const ang = 180.0 * (1 - value / maxTotal);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;   
  
  
    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />
    ];
  };



    return (
      <div style={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: 'center', marginTop: -90}}>
      <PieChart width={250} height={190}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          nameKey="name"
          paddingAngle={2}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color}  />
          ))}

          <LabelList dataKey="label" position="outside" offset={15} fontSize={10} />
        </Pie>
        {value}
        {needle(value, data, cx, cy, iR, oR, '#525659')}

      </PieChart>
      </div>
    );
  }
