import React from 'react';
import { Dialog as DialogMaterial, DialogTitle, DialogContent, Backdrop,
DialogContentText, DialogActions, Button, Divider } from '@material-ui/core';

const CDialog = (props) => {
  const { open = false, renderAsHtml = false, contenttext, children, title, buttons } = props;

  return (
    <DialogMaterial
      open={open}
    >
      <div style={{ padding: "5px 20px"}}>
      <div style={{fontWeight: 'bold', margin: "15px 15px", fontSize: "14px"}}>{title}</div>
      <Divider />
   
      <DialogContent>
        {renderAsHtml && (<div dangerouslySetInnerHTML={{__html: contenttext}}></div>)}
        {contenttext && (<DialogContentText>{contenttext}</DialogContentText>)}
        {children}
      </DialogContent>
      <Divider />
      <DialogActions>
        {buttons.map((button, index) => (
          <Button
            style={{...button.style}}
            disabled={button.disabled}
            color={button.color}
            onClick={() => button.onClick()}
            key={index}
          >
            {button.icon}&nbsp;{button.title}
          </Button>
        ))}
      </DialogActions>
      </div>
    </DialogMaterial>
  );
};

export default CDialog;